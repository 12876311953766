/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */

a.text-action {
    color: #a3afb7
}

a.text-action, a.text-action:focus, a.text-action:hover {
    text-decoration: none
}

a.text-action:focus, a.text-action:hover {
    color: #ccd5db
}

a.text-action .icon + span {
    margin-left: 3px
}

a.text-like {
    color: #a3afb7 !important
}

a.text-like, a.text-like:focus, a.text-like:hover {
    text-decoration: none
}

a.text-like.active, a.text-like:focus, a.text-like:hover {
    color: #f96868 !important
}

.text-action + .text-action {
    margin-left: 6px
}

.img-bordered {
    padding: 3px;
    border: 1px solid #e4eaec
}

.img-bordered-primary {
    border-color: #62a8ea !important
}

.img-bordered-purple {
    border-color: #7c51d1 !important
}

.img-bordered-red {
    border-color: #e9595b !important
}

.img-bordered-green {
    border-color: #7dd3ae !important
}

.img-bordered-orange {
    border-color: #ec9940 !important
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    text-shadow: rgba(0, 0, 0, .15) 0 0 1px
}

.h1 .icon:first-child, .h2 .icon:first-child, .h3 .icon:first-child, .h4 .icon:first-child, .h5 .icon:first-child, .h6 .icon:first-child, h1 .icon:first-child, h2 .icon:first-child, h3 .icon:first-child, h4 .icon:first-child, h5 .icon:first-child, h6 .icon:first-child {
    margin-right: .5em
}

.mark, mark {
    color: #fff;
    border-radius: 2px
}

.drop-cap {
    float: left;
    padding: 5px;
    margin-right: 5px;
    font-family: Georgia;
    font-size: 60px;
    line-height: 50px;
    color: #263238
}

.drop-cap-reversed {
    color: #fff;
    background-color: #263238
}

.list-icons {
    padding-left: 10px;
    margin-left: 0;
    list-style: none
}

.list-icons > li {
    margin-top: 6px
}

.list-icons > li:first-child {
    margin-top: 0
}

.list-icons > li i {
    float: left;
    width: 1em;
    margin: 0 6px 0 0
}

.text-primary {
    color: #62a8ea
}

a.text-primary:focus, a.text-primary:hover {
    color: #358fe4
}

.text-success {
    color: #46be8a
}

a.text-success:focus, a.text-success:hover {
    color: #369b6f
}

.text-info {
    color: #57c7d4
}

a.text-info:focus, a.text-info:hover {
    color: #33b6c5
}

.text-warning {
    color: #f2a654
}

a.text-warning:focus, a.text-warning:hover {
    color: #ee8d25
}

.text-danger {
    color: #f96868
}

a.text-danger:focus, a.text-danger:hover {
    color: #f73737
}

blockquote {
    color: #526069;
    font-size: 20px;
    border-left-width: 2px
}

blockquote .small, blockquote footer, blockquote small {
    font-size: 14px
}

.blockquote-reverse {
    border-right-width: 2px
}

.blockquote {
    padding: 15px 20px;
    border-left-width: 4px;
    border-radius: 3px
}

.blockquote.blockquote-reverse {
    border-right-width: 4px
}

.blockquote-success {
    background-color: rgba(70, 190, 138, .1);
    border-color: #46be8a
}

.blockquote-info {
    background-color: rgba(87, 199, 212, .1);
    border-color: #57c7d4
}

.blockquote-warning {
    background-color: rgba(242, 166, 84, .1);
    border-color: #f2a654
}

.blockquote-danger {
    background-color: rgba(249, 104, 104, .1);
    border-color: #f96868
}

code {
    border: 1px solid #bcd8f1
}

.container {
    max-width: 100%
}

@media (min-width: 1600px) {
    .container {
        width: 1310px
    }
}

@media (min-width: 1600px) {
    .col-xlg-1, .col-xlg-10, .col-xlg-11, .col-xlg-12, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9 {
        float: left
    }

    .col-xlg-12 {
        width: 100%
    }

    .col-xlg-11 {
        width: 91.66666667%
    }

    .col-xlg-10 {
        width: 83.33333333%
    }

    .col-xlg-9 {
        width: 75%
    }

    .col-xlg-8 {
        width: 66.66666667%
    }

    .col-xlg-7 {
        width: 58.33333333%
    }

    .col-xlg-6 {
        width: 50%
    }

    .col-xlg-5 {
        width: 41.66666667%
    }

    .col-xlg-4 {
        width: 33.33333333%
    }

    .col-xlg-3 {
        width: 25%
    }

    .col-xlg-2 {
        width: 16.66666667%
    }

    .col-xlg-1 {
        width: 8.33333333%
    }

    .col-xlg-pull-12 {
        right: 100%
    }

    .col-xlg-pull-11 {
        right: 91.66666667%
    }

    .col-xlg-pull-10 {
        right: 83.33333333%
    }

    .col-xlg-pull-9 {
        right: 75%
    }

    .col-xlg-pull-8 {
        right: 66.66666667%
    }

    .col-xlg-pull-7 {
        right: 58.33333333%
    }

    .col-xlg-pull-6 {
        right: 50%
    }

    .col-xlg-pull-5 {
        right: 41.66666667%
    }

    .col-xlg-pull-4 {
        right: 33.33333333%
    }

    .col-xlg-pull-3 {
        right: 25%
    }

    .col-xlg-pull-2 {
        right: 16.66666667%
    }

    .col-xlg-pull-1 {
        right: 8.33333333%
    }

    .col-xlg-pull-0 {
        right: auto
    }

    .col-xlg-push-12 {
        left: 100%
    }

    .col-xlg-push-11 {
        left: 91.66666667%
    }

    .col-xlg-push-10 {
        left: 83.33333333%
    }

    .col-xlg-push-9 {
        left: 75%
    }

    .col-xlg-push-8 {
        left: 66.66666667%
    }

    .col-xlg-push-7 {
        left: 58.33333333%
    }

    .col-xlg-push-6 {
        left: 50%
    }

    .col-xlg-push-5 {
        left: 41.66666667%
    }

    .col-xlg-push-4 {
        left: 33.33333333%
    }

    .col-xlg-push-3 {
        left: 25%
    }

    .col-xlg-push-2 {
        left: 16.66666667%
    }

    .col-xlg-push-1 {
        left: 8.33333333%
    }

    .col-xlg-push-0 {
        left: auto
    }

    .col-xlg-offset-12 {
        margin-left: 100%
    }

    .col-xlg-offset-11 {
        margin-left: 91.66666667%
    }

    .col-xlg-offset-10 {
        margin-left: 83.33333333%
    }

    .col-xlg-offset-9 {
        margin-left: 75%
    }

    .col-xlg-offset-8 {
        margin-left: 66.66666667%
    }

    .col-xlg-offset-7 {
        margin-left: 58.33333333%
    }

    .col-xlg-offset-6 {
        margin-left: 50%
    }

    .col-xlg-offset-5 {
        margin-left: 41.66666667%
    }

    .col-xlg-offset-4 {
        margin-left: 33.33333333%
    }

    .col-xlg-offset-3 {
        margin-left: 25%
    }

    .col-xlg-offset-2 {
        margin-left: 16.66666667%
    }

    .col-xlg-offset-1 {
        margin-left: 8.33333333%
    }

    .col-xlg-offset-0 {
        margin-left: 0
    }
}

.col-xlg-1, .col-xlg-10, .col-xlg-11, .col-xlg-12, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px
}

.row.no-space {
    margin-right: 0;
    margin-left: 0
}

.row.no-space > [class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.row-lg {
    margin-left: -25px;
    margin-right: -25px
}

.row-lg > .col-lg-1, .row-lg > .col-lg-10, .row-lg > .col-lg-11, .row-lg > .col-lg-12, .row-lg > .col-lg-2, .row-lg > .col-lg-3, .row-lg > .col-lg-4, .row-lg > .col-lg-5, .row-lg > .col-lg-6, .row-lg > .col-lg-7, .row-lg > .col-lg-8, .row-lg > .col-lg-9, .row-lg > .col-md-1, .row-lg > .col-md-10, .row-lg > .col-md-11, .row-lg > .col-md-12, .row-lg > .col-md-2, .row-lg > .col-md-3, .row-lg > .col-md-4, .row-lg > .col-md-5, .row-lg > .col-md-6, .row-lg > .col-md-7, .row-lg > .col-md-8, .row-lg > .col-md-9, .row-lg > .col-sm-1, .row-lg > .col-sm-10, .row-lg > .col-sm-11, .row-lg > .col-sm-12, .row-lg > .col-sm-2, .row-lg > .col-sm-3, .row-lg > .col-sm-4, .row-lg > .col-sm-5, .row-lg > .col-sm-6, .row-lg > .col-sm-7, .row-lg > .col-sm-8, .row-lg > .col-sm-9, .row-lg > .col-xlg-1, .row-lg > .col-xlg-10, .row-lg > .col-xlg-11, .row-lg > .col-xlg-12, .row-lg > .col-xlg-2, .row-lg > .col-xlg-3, .row-lg > .col-xlg-4, .row-lg > .col-xlg-5, .row-lg > .col-xlg-6, .row-lg > .col-xlg-7, .row-lg > .col-xlg-8, .row-lg > .col-xlg-9, .row-lg > .col-xs-1, .row-lg > .col-xs-10, .row-lg > .col-xs-11, .row-lg > .col-xs-12, .row-lg > .col-xs-2, .row-lg > .col-xs-3, .row-lg > .col-xs-4, .row-lg > .col-xs-5, .row-lg > .col-xs-6, .row-lg > .col-xs-7, .row-lg > .col-xs-8, .row-lg > .col-xs-9 {
    padding-left: 25px;
    padding-right: 25px
}

.table {
    color: #76838f
}

.table > tfoot > tr > th, .table > thead > tr > th {
    font-weight: 400;
    color: #526069
}

.table > thead > tr > th {
    border-bottom: 1px solid #e4eaec
}

.table > tbody + tbody {
    border-top: 1px solid #e4eaec
}

.table a {
    text-decoration: underline
}

.table td > .checkbox-custom:only-child, .table th > .checkbox-custom:only-child {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center
}

.table .danger, .table .info, .table .success, .table .warning {
    color: #fff
}

.table .danger a, .table .info a, .table .success a, .table .warning a {
    color: #fff
}

.table .cell-30 {
    width: 30px
}

.table .cell-40 {
    width: 40px
}

.table .cell-50 {
    width: 50px
}

.table .cell-60 {
    width: 60px
}

.table .cell-80 {
    width: 80px
}

.table .cell-100 {
    width: 100px
}

.table .cell-120 {
    width: 120px
}

.table .cell-130 {
    width: 130px
}

.table .cell-150 {
    width: 150px
}

.table .cell-180 {
    width: 180px
}

.table .cell-200 {
    width: 200px
}

.table .cell-250 {
    width: 250px
}

.table .cell-300 {
    width: 300px
}

.table-danger thead tr, .table-dark thead tr, .table-info thead tr, .table-primary thead tr, .table-success thead tr, .table-warning thead tr {
    color: #fff
}

.table-default thead tr {
    background: #f3f7f9
}

.table-primary thead tr {
    background: #62a8ea
}

.table-success thead tr {
    background: #46be8a
}

.table-info thead tr {
    background: #57c7d4
}

.table-warning thead tr {
    background: #f2a654
}

.table-danger thead tr {
    background: #f96868
}

.table-dark thead tr {
    background: #526069
}

.table-gray thead tr {
    color: #526069;
    background: #ccd5db
}

.table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border-bottom-width: 1px
}

.table-bordered > thead:first-child > tr:first-child > th {
    border: 1px solid #e4eaec
}

.table-section + tbody {
    display: none
}

.table-section-arrow {
    text-align: center;
    font-family: 'Web Icons';
    -webkit-transition: -webkit-transform .15s;
    -o-transition: -o-transform .15s;
    transition: transform .15s;
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.table-section-arrow:before {
    content: '\f181'
}

.table-section.active tr {
    background-color: #f3f7f9
}

.table-section.active + tbody {
    display: table-row-group
}

.table-section.active .table-section-arrow {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.form-control {
    -webkit-transition: -webkit-box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
    -o-transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
    transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control:not(select) {
    -webkit-appearance: none
}

.has-success .checkbox, .has-success .checkbox-inline, .has-success .control-label, .has-success .help-block, .has-success .radio, .has-success .radio-inline, .has-success.checkbox label, .has-success.checkbox-inline label, .has-success.radio label, .has-success.radio-inline label {
    color: #46be8a
}

.has-success .form-control {
    border-color: #46be8a;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-success .form-control:focus {
    border-color: #369b6f;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #91d9ba;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #91d9ba
}

.has-success .input-group-addon {
    color: #46be8a;
    border-color: #46be8a;
    background-color: #fff
}

.has-success .form-control-feedback {
    color: #46be8a
}

.has-success .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-success .form-control:focus {
    border-color: #46be8a;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(70, 190, 138, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(70, 190, 138, .6)
}

.has-success .form-control.focus, .has-success .form-control:focus {
    border-color: #46be8a;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-warning .checkbox, .has-warning .checkbox-inline, .has-warning .control-label, .has-warning .help-block, .has-warning .radio, .has-warning .radio-inline, .has-warning.checkbox label, .has-warning.checkbox-inline label, .has-warning.radio label, .has-warning.radio-inline label {
    color: #f2a654
}

.has-warning .form-control {
    border-color: #f2a654;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-warning .form-control:focus {
    border-color: #ee8d25;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f9d7b3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f9d7b3
}

.has-warning .input-group-addon {
    color: #f2a654;
    border-color: #f2a654;
    background-color: #fff
}

.has-warning .form-control-feedback {
    color: #f2a654
}

.has-warning .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-warning .form-control:focus {
    border-color: #f2a654;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(242, 166, 84, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(242, 166, 84, .6)
}

.has-warning .form-control.focus, .has-warning .form-control:focus {
    border-color: #f2a654;
    -webkit-box-shadow: none;
    box-shadow: none
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
    color: #f96868
}

.has-error .form-control {
    border-color: #f96868;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

.has-error .form-control:focus {
    border-color: #f73737;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fdcaca;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #fdcaca
}

.has-error .input-group-addon {
    color: #f96868;
    border-color: #f96868;
    background-color: #fff
}

.has-error .form-control-feedback {
    color: #f96868
}

.has-error .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.has-error .form-control:focus {
    border-color: #f96868;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(249, 104, 104, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(249, 104, 104, .6)
}

.has-error .form-control.focus, .has-error .form-control:focus {
    border-color: #f96868;
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-group.has-feedback.no-label .form-control-feedback {
    top: 0
}

.form-group.has-feedback.left-feedback .form-control-feedback {
    right: auto;
    left: 0
}

.form-group.has-feedback.left-feedback .form-control {
    padding-right: 13px;
    padding-left: 50px
}

.form-control.square {
    border-radius: 0
}

.form-control.round {
    border-radius: 200px
}

textarea.form-control.no-resize {
    resize: none
}

.input-group-file input[type=text] {
    background-color: #fff
}

.input-group-file .btn-file {
    position: relative;
    overflow: hidden
}

.input-group-file .btn-file.btn-outline {
    border: 1px solid #e4eaec;
    border-left: none
}

.input-group-file .btn-file.btn-outline:hover {
    border-left: none
}

.input-group-file .btn-file > .icon {
    margin: 0 3px
}

.input-group-file .btn-file input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    text-align: 0;
    cursor: pointer;
    opacity: 0
}

.help-block {
    margin-top: 7px;
    margin-bottom: 8px
}

.help-block > .icon {
    margin: 0 5px
}

.input-search-close {
    color: #000;
    text-shadow: none;
    opacity: .2;
    filter: alpha(opacity=20)
}

.input-search-close.icon {
    line-height: inherit;
    font-size: inherit
}

.input-search-close:focus, .input-search-close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .5;
    filter: alpha(opacity=50)
}

button.input-search-close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none
}

.input-search {
    position: relative
}

.input-search .form-control {
    border-radius: 200px
}

.input-search .input-search-close, .input-search .input-search-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
    width: 36px
}

.input-search .input-search-close {
    right: 8px
}

.input-search .input-search-icon + .form-control {
    padding-left: 43.5px
}

.input-search .input-search-icon {
    left: 8px;
    font-size: 16px;
    color: #a3afb7;
    text-align: center;
    pointer-events: none
}

.input-search-btn + .form-control {
    padding-right: 50px
}

.input-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 10px;
    background: 0 0;
    border: none;
    border-radius: 0 200px 200px 0
}

.input-search-btn > .icon {
    margin: 0 3px
}

.input-search-dark .input-search-icon {
    color: #76838f
}

.input-search-dark .form-control {
    background: #f3f7f9;
    -webkit-box-shadow: none;
    box-shadow: none
}

.input-search-dark .form-control:focus {
    background-color: transparent
}

.form-inline .form-group {
    margin-right: 20px
}

.form-inline .form-group:last-child {
    margin-right: 0
}

.form-inline .control-label {
    margin-right: 5px
}

@media (max-width: 767px) {
    .form-inline .form-group {
        margin-right: 0
    }
}

.btn {
    padding: 6px 15px;
    font-size: 14px;
    line-height: 1.57142857;
    border-radius: 3px;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear;
    -o-transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear;
    transition: border .2s linear, color .2s linear, width .2s linear, background-color .2s linear
}

.btn.active:focus, .btn:active:focus, .btn:focus {
    outline: 0
}

.btn.active, .btn:active {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn .icon {
    width: 1em;
    text-align: center;
    line-height: inherit;
    margin: -1px 3px 0
}

.btn-block {
    white-space: normal
}

.btn-outline.btn-default {
    color: #76838f;
    background-color: transparent
}

.btn-outline.btn-default.active, .btn-outline.btn-default:active, .btn-outline.btn-default:focus, .btn-outline.btn-default:hover, .open > .dropdown-toggle.btn-outline.btn-default {
    border-color: #e4eaec;
    background-color: rgba(118, 131, 143, .1);
    color: #76838f
}

.btn-outline.btn-default.active .badge, .btn-outline.btn-default:active .badge, .btn-outline.btn-default:focus .badge, .btn-outline.btn-default:hover .badge, .open > .dropdown-toggle.btn-outline.btn-default .badge {
    color: #76838f;
    background-color: #76838f
}

.btn-outline.btn-primary {
    color: #62a8ea;
    background-color: transparent
}

.btn-outline.btn-primary.active, .btn-outline.btn-primary:active, .btn-outline.btn-primary:focus, .btn-outline.btn-primary:hover, .open > .dropdown-toggle.btn-outline.btn-primary {
    border-color: #62a8ea;
    background-color: #62a8ea;
    color: #fff
}

.btn-outline.btn-primary.active .badge, .btn-outline.btn-primary:active .badge, .btn-outline.btn-primary:focus .badge, .btn-outline.btn-primary:hover .badge, .open > .dropdown-toggle.btn-outline.btn-primary .badge {
    color: #62a8ea;
    background-color: #fff
}

.btn-outline.btn-success {
    color: #46be8a;
    background-color: transparent
}

.btn-outline.btn-success.active, .btn-outline.btn-success:active, .btn-outline.btn-success:focus, .btn-outline.btn-success:hover, .open > .dropdown-toggle.btn-outline.btn-success {
    border-color: #46be8a;
    background-color: #46be8a;
    color: #fff
}

.btn-outline.btn-success.active .badge, .btn-outline.btn-success:active .badge, .btn-outline.btn-success:focus .badge, .btn-outline.btn-success:hover .badge, .open > .dropdown-toggle.btn-outline.btn-success .badge {
    color: #46be8a;
    background-color: #fff
}

.btn-outline.btn-info {
    color: #57c7d4;
    background-color: transparent
}

.btn-outline.btn-info.active, .btn-outline.btn-info:active, .btn-outline.btn-info:focus, .btn-outline.btn-info:hover, .open > .dropdown-toggle.btn-outline.btn-info {
    border-color: #57c7d4;
    background-color: #57c7d4;
    color: #fff
}

.btn-outline.btn-info.active .badge, .btn-outline.btn-info:active .badge, .btn-outline.btn-info:focus .badge, .btn-outline.btn-info:hover .badge, .open > .dropdown-toggle.btn-outline.btn-info .badge {
    color: #57c7d4;
    background-color: #fff
}

.btn-outline.btn-warning {
    color: #f2a654;
    background-color: transparent
}

.btn-outline.btn-warning.active, .btn-outline.btn-warning:active, .btn-outline.btn-warning:focus, .btn-outline.btn-warning:hover, .open > .dropdown-toggle.btn-outline.btn-warning {
    border-color: #f2a654;
    background-color: #f2a654;
    color: #fff
}

.btn-outline.btn-warning.active .badge, .btn-outline.btn-warning:active .badge, .btn-outline.btn-warning:focus .badge, .btn-outline.btn-warning:hover .badge, .open > .dropdown-toggle.btn-outline.btn-warning .badge {
    color: #f2a654;
    background-color: #fff
}

.btn-outline.btn-danger {
    color: #f96868;
    background-color: transparent
}

.btn-outline.btn-danger.active, .btn-outline.btn-danger:active, .btn-outline.btn-danger:focus, .btn-outline.btn-danger:hover, .open > .dropdown-toggle.btn-outline.btn-danger {
    border-color: #f96868;
    background-color: #f96868;
    color: #fff
}

.btn-outline.btn-danger.active .badge, .btn-outline.btn-danger:active .badge, .btn-outline.btn-danger:focus .badge, .btn-outline.btn-danger:hover .badge, .open > .dropdown-toggle.btn-outline.btn-danger .badge {
    color: #f96868;
    background-color: #fff
}

.btn-outline.btn-dark {
    color: #526069;
    background-color: transparent
}

.btn-outline.btn-dark.active, .btn-outline.btn-dark:active, .btn-outline.btn-dark:focus, .btn-outline.btn-dark:hover, .open > .dropdown-toggle.btn-outline.btn-dark {
    border-color: #526069;
    background-color: #526069;
    color: #fff
}

.btn-outline.btn-dark.active .badge, .btn-outline.btn-dark:active .badge, .btn-outline.btn-dark:focus .badge, .btn-outline.btn-dark:hover .badge, .open > .dropdown-toggle.btn-outline.btn-dark .badge {
    color: #526069;
    background-color: #fff
}

.btn-outline.btn-inverse {
    color: #fff;
    background-color: transparent
}

.btn-outline.btn-inverse.active, .btn-outline.btn-inverse:active, .btn-outline.btn-inverse:focus, .btn-outline.btn-inverse:hover, .open > .dropdown-toggle.btn-outline.btn-inverse {
    border-color: #fff;
    background-color: #fff;
    color: #76838f
}

.btn-outline.btn-inverse.active .badge, .btn-outline.btn-inverse:active .badge, .btn-outline.btn-inverse:focus .badge, .btn-outline.btn-inverse:hover .badge, .open > .dropdown-toggle.btn-outline.btn-inverse .badge {
    color: #fff;
    background-color: #76838f
}

.btn-lg {
    padding: 10px 18px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 4px
}

.btn-sm {
    padding: 6px 13px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px
}

.btn-xs {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px
}

.btn-squared {
    border-radius: 0
}

.btn-round {
    border-radius: 1000px
}

.btn-default.focus, .btn-default:focus, .btn-default:hover {
    background-color: #f3f7f9;
    border-color: #f3f7f9
}

.btn-default.active, .btn-default:active, .open > .dropdown-toggle.btn-default {
    background-color: #ccd5db;
    border-color: #ccd5db
}

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
    background-color: #ccd5db;
    border-color: #ccd5db
}

.btn-default.disabled, .btn-default.disabled.active, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled], .btn-default[disabled].active, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default.active, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
    color: #76838f;
    background-color: #f3f7f9;
    border-color: #f3f7f9
}

.btn-default.btn-up:before {
    border-bottom-color: #e4eaec
}

.btn-default.btn-up:focus:before, .btn-default.btn-up:hover:before {
    border-bottom-color: #f3f7f9
}

.btn-default.btn-up.active:before, .btn-default.btn-up:active:before, .open > .dropdown-toggle.btn-default.btn-up:before {
    border-bottom-color: #ccd5db
}

.btn-default.btn-right:before {
    border-left-color: #e4eaec
}

.btn-default.btn-right:focus:before, .btn-default.btn-right:hover:before {
    border-left-color: #f3f7f9
}

.btn-default.btn-right.active:before, .btn-default.btn-right:active:before, .open > .dropdown-toggle.btn-default.btn-right:before {
    border-left-color: #ccd5db
}

.btn-default.btn-bottom:before {
    border-top-color: #e4eaec
}

.btn-default.btn-bottom:focus:before, .btn-default.btn-bottom:hover:before {
    border-top-color: #f3f7f9
}

.btn-default.btn-bottom.active:before, .btn-default.btn-bottom:active:before, .open > .dropdown-toggle.btn-default.btn-bottom:before {
    border-top-color: #ccd5db
}

.btn-default.btn-left:before {
    border-right-color: #e4eaec
}

.btn-default.btn-left:focus:before, .btn-default.btn-left:hover:before {
    border-right-color: #f3f7f9
}

.btn-default.btn-left.active:before, .btn-default.btn-left:active:before, .open > .dropdown-toggle.btn-default.btn-left:before {
    border-right-color: #ccd5db
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    background-color: #89bceb;
    border-color: #89bceb
}

.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
    background-color: #4e97d9;
    border-color: #4e97d9
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
    background-color: #4e97d9;
    border-color: #4e97d9
}

.btn-primary.disabled, .btn-primary.disabled.active, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled], .btn-primary[disabled].active, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
    color: #fff;
    background-color: #a2caee;
    border-color: #a2caee
}

.btn-primary.btn-up:before {
    border-bottom-color: #62a8ea
}

.btn-primary.btn-up:focus:before, .btn-primary.btn-up:hover:before {
    border-bottom-color: #89bceb
}

.btn-primary.btn-up.active:before, .btn-primary.btn-up:active:before, .open > .dropdown-toggle.btn-primary.btn-up:before {
    border-bottom-color: #4e97d9
}

.btn-primary.btn-right:before {
    border-left-color: #62a8ea
}

.btn-primary.btn-right:focus:before, .btn-primary.btn-right:hover:before {
    border-left-color: #89bceb
}

.btn-primary.btn-right.active:before, .btn-primary.btn-right:active:before, .open > .dropdown-toggle.btn-primary.btn-right:before {
    border-left-color: #4e97d9
}

.btn-primary.btn-bottom:before {
    border-top-color: #62a8ea
}

.btn-primary.btn-bottom:focus:before, .btn-primary.btn-bottom:hover:before {
    border-top-color: #89bceb
}

.btn-primary.btn-bottom.active:before, .btn-primary.btn-bottom:active:before, .open > .dropdown-toggle.btn-primary.btn-bottom:before {
    border-top-color: #4e97d9
}

.btn-primary.btn-left:before {
    border-right-color: #62a8ea
}

.btn-primary.btn-left:focus:before, .btn-primary.btn-left:hover:before {
    border-right-color: #89bceb
}

.btn-primary.btn-left.active:before, .btn-primary.btn-left:active:before, .open > .dropdown-toggle.btn-primary.btn-left:before {
    border-right-color: #4e97d9
}

.btn-success.focus, .btn-success:focus, .btn-success:hover {
    background-color: #5cd29d;
    border-color: #5cd29d
}

.btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
    background-color: #36ab7a;
    border-color: #36ab7a
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success:active.focus, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
    background-color: #36ab7a;
    border-color: #36ab7a
}

.btn-success.disabled, .btn-success.disabled.active, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled:focus, .btn-success.disabled:hover, .btn-success[disabled], .btn-success[disabled].active, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled]:focus, .btn-success[disabled]:hover, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success.active, fieldset[disabled] .btn-success.focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:hover {
    color: #fff;
    background-color: #7dd3ae;
    border-color: #7dd3ae
}

.btn-success.btn-up:before {
    border-bottom-color: #46be8a
}

.btn-success.btn-up:focus:before, .btn-success.btn-up:hover:before {
    border-bottom-color: #5cd29d
}

.btn-success.btn-up.active:before, .btn-success.btn-up:active:before, .open > .dropdown-toggle.btn-success.btn-up:before {
    border-bottom-color: #36ab7a
}

.btn-success.btn-right:before {
    border-left-color: #46be8a
}

.btn-success.btn-right:focus:before, .btn-success.btn-right:hover:before {
    border-left-color: #5cd29d
}

.btn-success.btn-right.active:before, .btn-success.btn-right:active:before, .open > .dropdown-toggle.btn-success.btn-right:before {
    border-left-color: #36ab7a
}

.btn-success.btn-bottom:before {
    border-top-color: #46be8a
}

.btn-success.btn-bottom:focus:before, .btn-success.btn-bottom:hover:before {
    border-top-color: #5cd29d
}

.btn-success.btn-bottom.active:before, .btn-success.btn-bottom:active:before, .open > .dropdown-toggle.btn-success.btn-bottom:before {
    border-top-color: #36ab7a
}

.btn-success.btn-left:before {
    border-right-color: #46be8a
}

.btn-success.btn-left:focus:before, .btn-success.btn-left:hover:before {
    border-right-color: #5cd29d
}

.btn-success.btn-left.active:before, .btn-success.btn-left:active:before, .open > .dropdown-toggle.btn-success.btn-left:before {
    border-right-color: #36ab7a
}

.btn-info.focus, .btn-info:focus, .btn-info:hover {
    background-color: #77d6e1;
    border-color: #77d6e1
}

.btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
    background-color: #47b8c6;
    border-color: #47b8c6
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
    background-color: #47b8c6;
    border-color: #47b8c6
}

.btn-info.disabled, .btn-info.disabled.active, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled:focus, .btn-info.disabled:hover, .btn-info[disabled], .btn-info[disabled].active, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled]:focus, .btn-info[disabled]:hover, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info.active, fieldset[disabled] .btn-info.focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:hover {
    color: #fff;
    background-color: #9ae1e9;
    border-color: #9ae1e9
}

.btn-info.btn-up:before {
    border-bottom-color: #57c7d4
}

.btn-info.btn-up:focus:before, .btn-info.btn-up:hover:before {
    border-bottom-color: #77d6e1
}

.btn-info.btn-up.active:before, .btn-info.btn-up:active:before, .open > .dropdown-toggle.btn-info.btn-up:before {
    border-bottom-color: #47b8c6
}

.btn-info.btn-right:before {
    border-left-color: #57c7d4
}

.btn-info.btn-right:focus:before, .btn-info.btn-right:hover:before {
    border-left-color: #77d6e1
}

.btn-info.btn-right.active:before, .btn-info.btn-right:active:before, .open > .dropdown-toggle.btn-info.btn-right:before {
    border-left-color: #47b8c6
}

.btn-info.btn-bottom:before {
    border-top-color: #57c7d4
}

.btn-info.btn-bottom:focus:before, .btn-info.btn-bottom:hover:before {
    border-top-color: #77d6e1
}

.btn-info.btn-bottom.active:before, .btn-info.btn-bottom:active:before, .open > .dropdown-toggle.btn-info.btn-bottom:before {
    border-top-color: #47b8c6
}

.btn-info.btn-left:before {
    border-right-color: #57c7d4
}

.btn-info.btn-left:focus:before, .btn-info.btn-left:hover:before {
    border-right-color: #77d6e1
}

.btn-info.btn-left.active:before, .btn-info.btn-left:active:before, .open > .dropdown-toggle.btn-info.btn-left:before {
    border-right-color: #47b8c6
}

.btn-warning.focus, .btn-warning:focus, .btn-warning:hover {
    background-color: #f4b066;
    border-color: #f4b066
}

.btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
    background-color: #ec9940;
    border-color: #ec9940
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning:active.focus, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
    background-color: #ec9940;
    border-color: #ec9940
}

.btn-warning.disabled, .btn-warning.disabled.active, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled:focus, .btn-warning.disabled:hover, .btn-warning[disabled], .btn-warning[disabled].active, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled]:focus, .btn-warning[disabled]:hover, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning.active, fieldset[disabled] .btn-warning.focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:hover {
    color: #fff;
    background-color: #f6be80;
    border-color: #f6be80
}

.btn-warning.btn-up:before {
    border-bottom-color: #f2a654
}

.btn-warning.btn-up:focus:before, .btn-warning.btn-up:hover:before {
    border-bottom-color: #f4b066
}

.btn-warning.btn-up.active:before, .btn-warning.btn-up:active:before, .open > .dropdown-toggle.btn-warning.btn-up:before {
    border-bottom-color: #ec9940
}

.btn-warning.btn-right:before {
    border-left-color: #f2a654
}

.btn-warning.btn-right:focus:before, .btn-warning.btn-right:hover:before {
    border-left-color: #f4b066
}

.btn-warning.btn-right.active:before, .btn-warning.btn-right:active:before, .open > .dropdown-toggle.btn-warning.btn-right:before {
    border-left-color: #ec9940
}

.btn-warning.btn-bottom:before {
    border-top-color: #f2a654
}

.btn-warning.btn-bottom:focus:before, .btn-warning.btn-bottom:hover:before {
    border-top-color: #f4b066
}

.btn-warning.btn-bottom.active:before, .btn-warning.btn-bottom:active:before, .open > .dropdown-toggle.btn-warning.btn-bottom:before {
    border-top-color: #ec9940
}

.btn-warning.btn-left:before {
    border-right-color: #f2a654
}

.btn-warning.btn-left:focus:before, .btn-warning.btn-left:hover:before {
    border-right-color: #f4b066
}

.btn-warning.btn-left.active:before, .btn-warning.btn-left:active:before, .open > .dropdown-toggle.btn-warning.btn-left:before {
    border-right-color: #ec9940
}

.btn-danger.focus, .btn-danger:focus, .btn-danger:hover {
    background-color: #fa7a7a;
    border-color: #fa7a7a
}

.btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
    background-color: #e9595b;
    border-color: #e9595b
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger:active.focus, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
    background-color: #e9595b;
    border-color: #e9595b
}

.btn-danger.disabled, .btn-danger.disabled.active, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled:focus, .btn-danger.disabled:hover, .btn-danger[disabled], .btn-danger[disabled].active, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled]:focus, .btn-danger[disabled]:hover, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger.active, fieldset[disabled] .btn-danger.focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:hover {
    color: #fff;
    background-color: #fa9898;
    border-color: #fa9898
}

.btn-danger.btn-up:before {
    border-bottom-color: #f96868
}

.btn-danger.btn-up:focus:before, .btn-danger.btn-up:hover:before {
    border-bottom-color: #fa7a7a
}

.btn-danger.btn-up.active:before, .btn-danger.btn-up:active:before, .open > .dropdown-toggle.btn-danger.btn-up:before {
    border-bottom-color: #e9595b
}

.btn-danger.btn-right:before {
    border-left-color: #f96868
}

.btn-danger.btn-right:focus:before, .btn-danger.btn-right:hover:before {
    border-left-color: #fa7a7a
}

.btn-danger.btn-right.active:before, .btn-danger.btn-right:active:before, .open > .dropdown-toggle.btn-danger.btn-right:before {
    border-left-color: #e9595b
}

.btn-danger.btn-bottom:before {
    border-top-color: #f96868
}

.btn-danger.btn-bottom:focus:before, .btn-danger.btn-bottom:hover:before {
    border-top-color: #fa7a7a
}

.btn-danger.btn-bottom.active:before, .btn-danger.btn-bottom:active:before, .open > .dropdown-toggle.btn-danger.btn-bottom:before {
    border-top-color: #e9595b
}

.btn-danger.btn-left:before {
    border-right-color: #f96868
}

.btn-danger.btn-left:focus:before, .btn-danger.btn-left:hover:before {
    border-right-color: #fa7a7a
}

.btn-danger.btn-left.active:before, .btn-danger.btn-left:active:before, .open > .dropdown-toggle.btn-danger.btn-left:before {
    border-right-color: #e9595b
}

.btn-inverse {
    color: #76838f;
    background-color: #fff;
    border-color: #e4eaec
}

.btn-inverse.focus, .btn-inverse:focus {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #99b0b7
}

.btn-inverse:hover {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #c0ced3
}

.btn-inverse.active, .btn-inverse:active, .open > .dropdown-toggle.btn-inverse {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #c0ced3
}

.btn-inverse.active.focus, .btn-inverse.active:focus, .btn-inverse.active:hover, .btn-inverse:active.focus, .btn-inverse:active:focus, .btn-inverse:active:hover, .open > .dropdown-toggle.btn-inverse.focus, .open > .dropdown-toggle.btn-inverse:focus, .open > .dropdown-toggle.btn-inverse:hover {
    color: #76838f;
    background-color: #d4d4d4;
    border-color: #99b0b7
}

.btn-inverse.active, .btn-inverse:active, .open > .dropdown-toggle.btn-inverse {
    background-image: none
}

.btn-inverse.disabled.focus, .btn-inverse.disabled:focus, .btn-inverse.disabled:hover, .btn-inverse[disabled].focus, .btn-inverse[disabled]:focus, .btn-inverse[disabled]:hover, fieldset[disabled] .btn-inverse.focus, fieldset[disabled] .btn-inverse:focus, fieldset[disabled] .btn-inverse:hover {
    background-color: #fff;
    border-color: #e4eaec
}

.btn-inverse .badge {
    color: #fff;
    background-color: #76838f
}

.btn-inverse.focus, .btn-inverse:focus, .btn-inverse:hover {
    background-color: #fff;
    border-color: #f3f7f9
}

.btn-inverse.active, .btn-inverse:active, .open > .dropdown-toggle.btn-inverse {
    background-color: #fff;
    border-color: #ccd5db
}

.btn-inverse.active.focus, .btn-inverse.active:focus, .btn-inverse.active:hover, .btn-inverse:active.focus, .btn-inverse:active:focus, .btn-inverse:active:hover, .open > .dropdown-toggle.btn-inverse.focus, .open > .dropdown-toggle.btn-inverse:focus, .open > .dropdown-toggle.btn-inverse:hover {
    background-color: #fff;
    border-color: #ccd5db
}

.btn-inverse.disabled, .btn-inverse.disabled.active, .btn-inverse.disabled.focus, .btn-inverse.disabled:active, .btn-inverse.disabled:focus, .btn-inverse.disabled:hover, .btn-inverse[disabled], .btn-inverse[disabled].active, .btn-inverse[disabled].focus, .btn-inverse[disabled]:active, .btn-inverse[disabled]:focus, .btn-inverse[disabled]:hover, fieldset[disabled] .btn-inverse, fieldset[disabled] .btn-inverse.active, fieldset[disabled] .btn-inverse.focus, fieldset[disabled] .btn-inverse:active, fieldset[disabled] .btn-inverse:focus, fieldset[disabled] .btn-inverse:hover {
    color: #ccd5db;
    background-color: #fff;
    border-color: #a3afb7
}

.btn-inverse.btn-up:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-up:focus:before, .btn-inverse.btn-up:hover:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-up.active:before, .btn-inverse.btn-up:active:before, .open > .dropdown-toggle.btn-inverse.btn-up:before {
    border-bottom-color: #fff
}

.btn-inverse.btn-right:before {
    border-left-color: #fff
}

.btn-inverse.btn-right:focus:before, .btn-inverse.btn-right:hover:before {
    border-left-color: #fff
}

.btn-inverse.btn-right.active:before, .btn-inverse.btn-right:active:before, .open > .dropdown-toggle.btn-inverse.btn-right:before {
    border-left-color: #fff
}

.btn-inverse.btn-bottom:before {
    border-top-color: #fff
}

.btn-inverse.btn-bottom:focus:before, .btn-inverse.btn-bottom:hover:before {
    border-top-color: #fff
}

.btn-inverse.btn-bottom.active:before, .btn-inverse.btn-bottom:active:before, .open > .dropdown-toggle.btn-inverse.btn-bottom:before {
    border-top-color: #fff
}

.btn-inverse.btn-left:before {
    border-right-color: #fff
}

.btn-inverse.btn-left:focus:before, .btn-inverse.btn-left:hover:before {
    border-right-color: #fff
}

.btn-inverse.btn-left.active:before, .btn-inverse.btn-left:active:before, .open > .dropdown-toggle.btn-inverse.btn-left:before {
    border-right-color: #fff
}

.btn-dark {
    color: #fff;
    background-color: #526069;
    border-color: #526069
}

.btn-dark.focus, .btn-dark:focus {
    color: #fff;
    background-color: #3c464c;
    border-color: #1a1f21
}

.btn-dark:hover {
    color: #fff;
    background-color: #3c464c;
    border-color: #374147
}

.btn-dark.active, .btn-dark:active, .open > .dropdown-toggle.btn-dark {
    color: #fff;
    background-color: #3c464c;
    border-color: #374147
}

.btn-dark.active.focus, .btn-dark.active:focus, .btn-dark.active:hover, .btn-dark:active.focus, .btn-dark:active:focus, .btn-dark:active:hover, .open > .dropdown-toggle.btn-dark.focus, .open > .dropdown-toggle.btn-dark:focus, .open > .dropdown-toggle.btn-dark:hover {
    color: #fff;
    background-color: #2c3338;
    border-color: #1a1f21
}

.btn-dark.active, .btn-dark:active, .open > .dropdown-toggle.btn-dark {
    background-image: none
}

.btn-dark.disabled.focus, .btn-dark.disabled:focus, .btn-dark.disabled:hover, .btn-dark[disabled].focus, .btn-dark[disabled]:focus, .btn-dark[disabled]:hover, fieldset[disabled] .btn-dark.focus, fieldset[disabled] .btn-dark:focus, fieldset[disabled] .btn-dark:hover {
    background-color: #526069;
    border-color: #526069
}

.btn-dark .badge {
    color: #526069;
    background-color: #fff
}

.btn-dark.focus, .btn-dark:focus, .btn-dark:hover {
    background-color: #76838f;
    border-color: #76838f
}

.btn-dark.active, .btn-dark:active, .open > .dropdown-toggle.btn-dark {
    background-color: #37474f;
    border-color: #37474f
}

.btn-dark.active.focus, .btn-dark.active:focus, .btn-dark.active:hover, .btn-dark:active.focus, .btn-dark:active:focus, .btn-dark:active:hover, .open > .dropdown-toggle.btn-dark.focus, .open > .dropdown-toggle.btn-dark:focus, .open > .dropdown-toggle.btn-dark:hover {
    background-color: #37474f;
    border-color: #37474f
}

.btn-dark.disabled, .btn-dark.disabled.active, .btn-dark.disabled.focus, .btn-dark.disabled:active, .btn-dark.disabled:focus, .btn-dark.disabled:hover, .btn-dark[disabled], .btn-dark[disabled].active, .btn-dark[disabled].focus, .btn-dark[disabled]:active, .btn-dark[disabled]:focus, .btn-dark[disabled]:hover, fieldset[disabled] .btn-dark, fieldset[disabled] .btn-dark.active, fieldset[disabled] .btn-dark.focus, fieldset[disabled] .btn-dark:active, fieldset[disabled] .btn-dark:focus, fieldset[disabled] .btn-dark:hover {
    color: #fff;
    background-color: #a3afb7;
    border-color: #a3afb7
}

.btn-dark.btn-up:before {
    border-bottom-color: #526069
}

.btn-dark.btn-up:focus:before, .btn-dark.btn-up:hover:before {
    border-bottom-color: #76838f
}

.btn-dark.btn-up.active:before, .btn-dark.btn-up:active:before, .open > .dropdown-toggle.btn-dark.btn-up:before {
    border-bottom-color: #37474f
}

.btn-dark.btn-right:before {
    border-left-color: #526069
}

.btn-dark.btn-right:focus:before, .btn-dark.btn-right:hover:before {
    border-left-color: #76838f
}

.btn-dark.btn-right.active:before, .btn-dark.btn-right:active:before, .open > .dropdown-toggle.btn-dark.btn-right:before {
    border-left-color: #37474f
}

.btn-dark.btn-bottom:before {
    border-top-color: #526069
}

.btn-dark.btn-bottom:focus:before, .btn-dark.btn-bottom:hover:before {
    border-top-color: #76838f
}

.btn-dark.btn-bottom.active:before, .btn-dark.btn-bottom:active:before, .open > .dropdown-toggle.btn-dark.btn-bottom:before {
    border-top-color: #37474f
}

.btn-dark.btn-left:before {
    border-right-color: #526069
}

.btn-dark.btn-left:focus:before, .btn-dark.btn-left:hover:before {
    border-right-color: #76838f
}

.btn-dark.btn-left.active:before, .btn-dark.btn-left:active:before, .open > .dropdown-toggle.btn-dark.btn-left:before {
    border-right-color: #37474f
}

.btn-dark:focus, .btn-dark:hover {
    color: #fff
}

.btn-dark.active, .btn-dark:active, .open > .dropdown-toggle.btn-dark {
    color: #fff
}

.btn-dark.btn-flat {
    color: #526069
}

.btn-flat {
    background: 0 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-flat.disabled {
    color: #a3afb7
}

.btn-icon, .btn.icon {
    padding: 10px;
    line-height: 1em
}

.btn-icon.btn-xs, .btn.icon.btn-xs {
    padding: 4px;
    font-size: 12px
}

.btn-icon.btn-sm, .btn.icon.btn-sm {
    padding: 8px;
    font-size: 14px
}

.btn-icon.btn-lg, .btn.icon.btn-lg {
    padding: 12px;
    font-size: 20px
}

.btn-icon.disabled, .btn.icon.disabled {
    color: #a3afb7
}

.btn-icon .icon {
    margin: -1px 0 0
}

.btn-raised {
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, .18), 0 2px 4px rgba(0, 0, 0, .21);
    box-shadow: 0 0 2px rgba(0, 0, 0, .18), 0 2px 4px rgba(0, 0, 0, .21);
    -webkit-transition: -webkit-box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    -o-transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1);
    transition: box-shadow .25s cubic-bezier(.4, 0, .2, 1)
}

.btn-raised.active, .btn-raised:active, .btn-raised:hover, .open > .dropdown-toggle.btn-raised {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .2);
    box-shadow: 0 0 3px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .2)
}

.btn-raised.disabled, .btn-raised[disabled], fieldset[disabled] .btn-raised {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-floating {
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    font-size: 24px;
    text-align: center;
    border-radius: 100%;
    -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .15);
    box-shadow: 0 6px 10px rgba(0, 0, 0, .15)
}

.btn-floating.btn-xs {
    width: 30px;
    height: 30px;
    padding: 0;
    font-size: 13px
}

.btn-floating.btn-sm {
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 15px
}

.btn-floating.btn-lg {
    width: 70px;
    height: 70px;
    padding: 0;
    font-size: 30px
}

.btn-floating i {
    position: relative;
    top: 0
}

.btn-animate {
    position: relative;
    overflow: hidden
}

.btn-animate span {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.btn-animate-side {
    padding: 8px 28px
}

.btn-animate-side span {
    -webkit-transition: -webkit-transform .2s ease-out 0s;
    -o-transition: -o-transform .2s ease-out 0s;
    transition: transform .2s ease-out 0s
}

.btn-animate-side span > .icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    opacity: 0;
    -webkit-transform: translate(-20px, -50%);
    -ms-transform: translate(-20px, -50%);
    -o-transform: translate(-20px, -50%);
    transform: translate(-20px, -50%);
    -webkit-transition: opacity .2s ease-out 0s;
    -o-transition: opacity .2s ease-out 0s;
    transition: opacity .2s ease-out 0s
}

.btn-animate-side:hover span {
    -webkit-transform: translate(10px, 0);
    -ms-transform: translate(10px, 0);
    -o-transform: translate(10px, 0);
    transform: translate(10px, 0)
}

.btn-animate-side:hover span > .icon {
    opacity: 1
}

.btn-animate-side.btn-xs {
    padding: 3px 14px
}

.btn-animate-side.btn-xs span > .icon {
    left: 5px
}

.btn-animate-side.btn-xs:hover span {
    -webkit-transform: translate(8px, 0);
    -ms-transform: translate(8px, 0);
    -o-transform: translate(8px, 0);
    transform: translate(8px, 0)
}

.btn-animate-side.btn-sm {
    padding: 6px 22px
}

.btn-animate-side.btn-sm span > .icon {
    left: 3px
}

.btn-animate-side.btn-sm:hover span {
    -webkit-transform: translate(8px, 0);
    -ms-transform: translate(8px, 0);
    -o-transform: translate(8px, 0);
    transform: translate(8px, 0)
}

.btn-animate-side.btn-lg {
    padding: 10px 33px
}

.btn-animate-side.btn-lg span > .icon {
    left: -6px
}

.btn-animate-side.btn-lg:hover span {
    -webkit-transform: translate(14px, 0);
    -ms-transform: translate(14px, 0);
    -o-transform: translate(14px, 0);
    transform: translate(14px, 0)
}

.btn-animate-vertical span {
    -webkit-transition: all .2s ease-out 0s;
    -o-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s
}

.btn-animate-vertical span > .icon {
    position: absolute;
    top: -2px;
    left: 50%;
    display: block;
    font-size: 24px;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%)
}

.btn-animate-vertical:hover span {
    -webkit-transform: translate(0, 150%);
    -ms-transform: translate(0, 150%);
    -o-transform: translate(0, 150%);
    transform: translate(0, 150%)
}

.btn-animate-vertical.btn-xs span > .icon {
    top: -5px;
    font-size: 18px
}

.btn-animate-vertical.btn-sm span > .icon {
    top: -3px;
    font-size: 21px
}

.btn-animate-vertical.btn-lg span > .icon {
    font-size: 37px
}

.btn-labeled {
    padding: 0;
    padding-right: 8px
}

.btn-labeled .btn-label {
    padding: 6px 8px;
    margin-right: 5px
}

.btn-labeled.btn-xs {
    padding-right: 5px
}

.btn-labeled.btn-xs .btn-label {
    padding: 1px 4px;
    margin-right: 2px
}

.btn-labeled.btn-sm {
    padding-right: 13px
}

.btn-labeled.btn-sm .btn-label {
    padding: 6px 6px;
    margin-right: 10px
}

.btn-labeled.btn-lg {
    padding-right: 14px
}

.btn-labeled.btn-lg .btn-label {
    padding: 10px 14px;
    margin-right: 11px
}

.btn-labeled.btn-block {
    text-align: left
}

.btn-label {
    display: inline-block;
    background-color: rgba(0, 0, 0, .15);
    border-radius: 3px 0 0 3px
}

.btn-pill-left {
    border-radius: 500px 0 0 500px
}

.btn-pill-right {
    border-radius: 0 500px 500px 0
}

.btn-direction {
    position: relative
}

.btn-direction:before {
    position: absolute;
    line-height: 0;
    content: '';
    border: 8px solid transparent
}

.btn-up:before {
    top: -16px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #e4eaec
}

.btn-right:before {
    top: 50%;
    right: -16px;
    margin-top: -8px;
    border-left-color: #e4eaec
}

.btn-bottom:before {
    bottom: -16px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #e4eaec
}

.btn-left:before {
    top: 50%;
    left: -16px;
    margin-top: -8px;
    border-right-color: #e4eaec
}

.btn-pure, .btn-pure.active, .btn-pure:active, .btn-pure:focus, .btn-pure:hover, .btn-pure[disabled], .open > .dropdown-toggle.btn-pure, fieldset[disabled] .btn-pure {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-pure.active.focus, .btn-pure.active:focus, .btn-pure.active:hover, .btn-pure.focus, .btn-pure:active.focus, .btn-pure:active:focus, .btn-pure:active:hover, .btn-pure:focus, .btn-pure:focus.focus, .btn-pure:focus:focus, .btn-pure:focus:hover, .btn-pure:hover, .btn-pure:hover.focus, .btn-pure:hover:focus, .btn-pure:hover:hover, .btn-pure[disabled].focus, .btn-pure[disabled]:focus, .btn-pure[disabled]:hover, .open > .dropdown-toggle.btn-pure.focus, .open > .dropdown-toggle.btn-pure:focus, .open > .dropdown-toggle.btn-pure:hover, fieldset[disabled] .btn-pure.focus, fieldset[disabled] .btn-pure:focus, fieldset[disabled] .btn-pure:hover {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-pure.btn-default {
    color: #a3afb7
}

.btn-pure.btn-default.active, .btn-pure.btn-default:active, .btn-pure.btn-default:focus, .btn-pure.btn-default:hover, .open > .dropdown-toggle.btn-pure.btn-default {
    color: #ccd5db
}

.btn-pure.btn-default.active.focus, .btn-pure.btn-default.active:focus, .btn-pure.btn-default.active:hover, .btn-pure.btn-default:active.focus, .btn-pure.btn-default:active:focus, .btn-pure.btn-default:active:hover, .btn-pure.btn-default:focus.focus, .btn-pure.btn-default:focus:focus, .btn-pure.btn-default:focus:hover, .btn-pure.btn-default:hover.focus, .btn-pure.btn-default:hover:focus, .btn-pure.btn-default:hover:hover, .open > .dropdown-toggle.btn-pure.btn-default.focus, .open > .dropdown-toggle.btn-pure.btn-default:focus, .open > .dropdown-toggle.btn-pure.btn-default:hover {
    color: #ccd5db
}

.btn-pure.btn-default.active .badge, .btn-pure.btn-default:active .badge, .btn-pure.btn-default:focus .badge, .btn-pure.btn-default:hover .badge, .open > .dropdown-toggle.btn-pure.btn-default .badge {
    color: #ccd5db
}

.btn-pure.btn-primary {
    color: #62a8ea
}

.btn-pure.btn-primary.active, .btn-pure.btn-primary:active, .btn-pure.btn-primary:focus, .btn-pure.btn-primary:hover, .open > .dropdown-toggle.btn-pure.btn-primary {
    color: #a2caee
}

.btn-pure.btn-primary.active.focus, .btn-pure.btn-primary.active:focus, .btn-pure.btn-primary.active:hover, .btn-pure.btn-primary:active.focus, .btn-pure.btn-primary:active:focus, .btn-pure.btn-primary:active:hover, .btn-pure.btn-primary:focus.focus, .btn-pure.btn-primary:focus:focus, .btn-pure.btn-primary:focus:hover, .btn-pure.btn-primary:hover.focus, .btn-pure.btn-primary:hover:focus, .btn-pure.btn-primary:hover:hover, .open > .dropdown-toggle.btn-pure.btn-primary.focus, .open > .dropdown-toggle.btn-pure.btn-primary:focus, .open > .dropdown-toggle.btn-pure.btn-primary:hover {
    color: #a2caee
}

.btn-pure.btn-primary.active .badge, .btn-pure.btn-primary:active .badge, .btn-pure.btn-primary:focus .badge, .btn-pure.btn-primary:hover .badge, .open > .dropdown-toggle.btn-pure.btn-primary .badge {
    color: #a2caee
}

.btn-pure.btn-success {
    color: #46be8a
}

.btn-pure.btn-success.active, .btn-pure.btn-success:active, .btn-pure.btn-success:focus, .btn-pure.btn-success:hover, .open > .dropdown-toggle.btn-pure.btn-success {
    color: #7dd3ae
}

.btn-pure.btn-success.active.focus, .btn-pure.btn-success.active:focus, .btn-pure.btn-success.active:hover, .btn-pure.btn-success:active.focus, .btn-pure.btn-success:active:focus, .btn-pure.btn-success:active:hover, .btn-pure.btn-success:focus.focus, .btn-pure.btn-success:focus:focus, .btn-pure.btn-success:focus:hover, .btn-pure.btn-success:hover.focus, .btn-pure.btn-success:hover:focus, .btn-pure.btn-success:hover:hover, .open > .dropdown-toggle.btn-pure.btn-success.focus, .open > .dropdown-toggle.btn-pure.btn-success:focus, .open > .dropdown-toggle.btn-pure.btn-success:hover {
    color: #7dd3ae
}

.btn-pure.btn-success.active .badge, .btn-pure.btn-success:active .badge, .btn-pure.btn-success:focus .badge, .btn-pure.btn-success:hover .badge, .open > .dropdown-toggle.btn-pure.btn-success .badge {
    color: #7dd3ae
}

.btn-pure.btn-info {
    color: #57c7d4
}

.btn-pure.btn-info.active, .btn-pure.btn-info:active, .btn-pure.btn-info:focus, .btn-pure.btn-info:hover, .open > .dropdown-toggle.btn-pure.btn-info {
    color: #9ae1e9
}

.btn-pure.btn-info.active.focus, .btn-pure.btn-info.active:focus, .btn-pure.btn-info.active:hover, .btn-pure.btn-info:active.focus, .btn-pure.btn-info:active:focus, .btn-pure.btn-info:active:hover, .btn-pure.btn-info:focus.focus, .btn-pure.btn-info:focus:focus, .btn-pure.btn-info:focus:hover, .btn-pure.btn-info:hover.focus, .btn-pure.btn-info:hover:focus, .btn-pure.btn-info:hover:hover, .open > .dropdown-toggle.btn-pure.btn-info.focus, .open > .dropdown-toggle.btn-pure.btn-info:focus, .open > .dropdown-toggle.btn-pure.btn-info:hover {
    color: #9ae1e9
}

.btn-pure.btn-info.active .badge, .btn-pure.btn-info:active .badge, .btn-pure.btn-info:focus .badge, .btn-pure.btn-info:hover .badge, .open > .dropdown-toggle.btn-pure.btn-info .badge {
    color: #9ae1e9
}

.btn-pure.btn-warning {
    color: #f2a654
}

.btn-pure.btn-warning.active, .btn-pure.btn-warning:active, .btn-pure.btn-warning:focus, .btn-pure.btn-warning:hover, .open > .dropdown-toggle.btn-pure.btn-warning {
    color: #f6be80
}

.btn-pure.btn-warning.active.focus, .btn-pure.btn-warning.active:focus, .btn-pure.btn-warning.active:hover, .btn-pure.btn-warning:active.focus, .btn-pure.btn-warning:active:focus, .btn-pure.btn-warning:active:hover, .btn-pure.btn-warning:focus.focus, .btn-pure.btn-warning:focus:focus, .btn-pure.btn-warning:focus:hover, .btn-pure.btn-warning:hover.focus, .btn-pure.btn-warning:hover:focus, .btn-pure.btn-warning:hover:hover, .open > .dropdown-toggle.btn-pure.btn-warning.focus, .open > .dropdown-toggle.btn-pure.btn-warning:focus, .open > .dropdown-toggle.btn-pure.btn-warning:hover {
    color: #f6be80
}

.btn-pure.btn-warning.active .badge, .btn-pure.btn-warning:active .badge, .btn-pure.btn-warning:focus .badge, .btn-pure.btn-warning:hover .badge, .open > .dropdown-toggle.btn-pure.btn-warning .badge {
    color: #f6be80
}

.btn-pure.btn-danger {
    color: #f96868
}

.btn-pure.btn-danger.active, .btn-pure.btn-danger:active, .btn-pure.btn-danger:focus, .btn-pure.btn-danger:hover, .open > .dropdown-toggle.btn-pure.btn-danger {
    color: #fa9898
}

.btn-pure.btn-danger.active.focus, .btn-pure.btn-danger.active:focus, .btn-pure.btn-danger.active:hover, .btn-pure.btn-danger:active.focus, .btn-pure.btn-danger:active:focus, .btn-pure.btn-danger:active:hover, .btn-pure.btn-danger:focus.focus, .btn-pure.btn-danger:focus:focus, .btn-pure.btn-danger:focus:hover, .btn-pure.btn-danger:hover.focus, .btn-pure.btn-danger:hover:focus, .btn-pure.btn-danger:hover:hover, .open > .dropdown-toggle.btn-pure.btn-danger.focus, .open > .dropdown-toggle.btn-pure.btn-danger:focus, .open > .dropdown-toggle.btn-pure.btn-danger:hover {
    color: #fa9898
}

.btn-pure.btn-danger.active .badge, .btn-pure.btn-danger:active .badge, .btn-pure.btn-danger:focus .badge, .btn-pure.btn-danger:hover .badge, .open > .dropdown-toggle.btn-pure.btn-danger .badge {
    color: #fa9898
}

.btn-pure.btn-dark {
    color: #526069
}

.btn-pure.btn-dark.active, .btn-pure.btn-dark:active, .btn-pure.btn-dark:focus, .btn-pure.btn-dark:hover, .open > .dropdown-toggle.btn-pure.btn-dark {
    color: #76838f
}

.btn-pure.btn-dark.active.focus, .btn-pure.btn-dark.active:focus, .btn-pure.btn-dark.active:hover, .btn-pure.btn-dark:active.focus, .btn-pure.btn-dark:active:focus, .btn-pure.btn-dark:active:hover, .btn-pure.btn-dark:focus.focus, .btn-pure.btn-dark:focus:focus, .btn-pure.btn-dark:focus:hover, .btn-pure.btn-dark:hover.focus, .btn-pure.btn-dark:hover:focus, .btn-pure.btn-dark:hover:hover, .open > .dropdown-toggle.btn-pure.btn-dark.focus, .open > .dropdown-toggle.btn-pure.btn-dark:focus, .open > .dropdown-toggle.btn-pure.btn-dark:hover {
    color: #76838f
}

.btn-pure.btn-dark.active .badge, .btn-pure.btn-dark:active .badge, .btn-pure.btn-dark:focus .badge, .btn-pure.btn-dark:hover .badge, .open > .dropdown-toggle.btn-pure.btn-dark .badge {
    color: #76838f
}

.btn-pure.btn-inverse {
    color: #fff
}

.btn-pure.btn-inverse.active, .btn-pure.btn-inverse:active, .btn-pure.btn-inverse:focus, .btn-pure.btn-inverse:hover, .open > .dropdown-toggle.btn-pure.btn-inverse {
    color: #fff
}

.btn-pure.btn-inverse.active.focus, .btn-pure.btn-inverse.active:focus, .btn-pure.btn-inverse.active:hover, .btn-pure.btn-inverse:active.focus, .btn-pure.btn-inverse:active:focus, .btn-pure.btn-inverse:active:hover, .btn-pure.btn-inverse:focus.focus, .btn-pure.btn-inverse:focus:focus, .btn-pure.btn-inverse:focus:hover, .btn-pure.btn-inverse:hover.focus, .btn-pure.btn-inverse:hover:focus, .btn-pure.btn-inverse:hover:hover, .open > .dropdown-toggle.btn-pure.btn-inverse.focus, .open > .dropdown-toggle.btn-pure.btn-inverse:focus, .open > .dropdown-toggle.btn-pure.btn-inverse:hover {
    color: #fff
}

.btn-pure.btn-inverse.active .badge, .btn-pure.btn-inverse:active .badge, .btn-pure.btn-inverse:focus .badge, .btn-pure.btn-inverse:hover .badge, .open > .dropdown-toggle.btn-pure.btn-inverse .badge {
    color: #fff
}

.caret {
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    -webkit-transform: scale(1.001);
    -ms-transform: scale(1.001);
    -o-transform: scale(1.001);
    transform: scale(1.001);
    border-top: 4px solid
}

.btn-group .btn + .dropdown-toggle .caret {
    margin-left: 0
}

.dropdown-toggle.btn .caret {
    margin-left: .3em
}

.dropdown-toggle.btn.btn-xs .caret {
    margin-left: 0
}

.btn-group > .btn + .dropdown-toggle {
    padding-right: .8em;
    padding-left: .8em
}

.dropdown-menu {
    margin-top: 5px;
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, .05);
    box-shadow: 0 3px 12px rgba(0, 0, 0, .05);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.dropdown-menu .divider {
    margin: 6px 0
}

.dropdown-menu > li {
    padding: 0 5px;
    margin: 2px 0
}

.dropdown-menu > li > a {
    padding: 8px 15px;
    border-radius: 3px;
    -webkit-transition: background-color .25s;
    -o-transition: background-color .25s;
    transition: background-color .25s
}

.dropdown-menu li .icon:first-child, .dropdown-menu li > a .icon:first-child {
    margin-right: .5em;
    width: 1em;
    text-align: center
}

.dropdown-menu.bullet {
    margin-top: 12px
}

.dropdown-menu.bullet:after, .dropdown-menu.bullet:before {
    position: absolute;
    left: 10px;
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top-width: 0
}

.dropdown-menu.bullet:before {
    top: -7px;
    border-bottom-color: #e4eaec
}

.dropdown-menu.bullet:after {
    top: -6px;
    border-bottom-color: #fff
}

.dropdown-menu-right.bullet:after, .dropdown-menu-right.bullet:before {
    right: 10px;
    left: auto
}

.dropdown-menu.animate {
    overflow: hidden
}

.dropdown-menu.animate > li {
    -webkit-animation-name: slide-left;
    -o-animation-name: slide-left;
    animation-name: slide-left;
    -webkit-animation-duration: .5s;
    -o-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both
}

.dropdown-menu.animate > li:nth-child(1) {
    -webkit-animation-delay: .02s;
    -o-animation-delay: .02s;
    animation-delay: .02s
}

.dropdown-menu.animate > li:nth-child(2) {
    -webkit-animation-delay: .04s;
    -o-animation-delay: .04s;
    animation-delay: .04s
}

.dropdown-menu.animate > li:nth-child(3) {
    -webkit-animation-delay: .06s;
    -o-animation-delay: .06s;
    animation-delay: .06s
}

.dropdown-menu.animate > li:nth-child(4) {
    -webkit-animation-delay: .08s;
    -o-animation-delay: .08s;
    animation-delay: .08s
}

.dropdown-menu.animate > li:nth-child(5) {
    -webkit-animation-delay: .1s;
    -o-animation-delay: .1s;
    animation-delay: .1s
}

.dropdown-menu.animate > li:nth-child(6) {
    -webkit-animation-delay: .12s;
    -o-animation-delay: .12s;
    animation-delay: .12s
}

.dropdown-menu.animate > li:nth-child(7) {
    -webkit-animation-delay: .14s;
    -o-animation-delay: .14s;
    animation-delay: .14s
}

.dropdown-menu.animate > li:nth-child(8) {
    -webkit-animation-delay: .16s;
    -o-animation-delay: .16s;
    animation-delay: .16s
}

.dropdown-menu.animate > li:nth-child(9) {
    -webkit-animation-delay: .18s;
    -o-animation-delay: .18s;
    animation-delay: .18s
}

.dropdown-menu.animate > li:nth-child(10) {
    -webkit-animation-delay: .2s;
    -o-animation-delay: .2s;
    animation-delay: .2s
}

.dropdown-menu.animate > li.divider {
    -webkit-animation-name: none;
    -o-animation-name: none;
    animation-name: none
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(1) {
    -webkit-animation-delay: .02s;
    -o-animation-delay: .02s;
    animation-delay: .02s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(2) {
    -webkit-animation-delay: .04s;
    -o-animation-delay: .04s;
    animation-delay: .04s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(3) {
    -webkit-animation-delay: .06s;
    -o-animation-delay: .06s;
    animation-delay: .06s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(4) {
    -webkit-animation-delay: .08s;
    -o-animation-delay: .08s;
    animation-delay: .08s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(5) {
    -webkit-animation-delay: .1s;
    -o-animation-delay: .1s;
    animation-delay: .1s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(6) {
    -webkit-animation-delay: .12s;
    -o-animation-delay: .12s;
    animation-delay: .12s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(7) {
    -webkit-animation-delay: .14s;
    -o-animation-delay: .14s;
    animation-delay: .14s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(8) {
    -webkit-animation-delay: .16s;
    -o-animation-delay: .16s;
    animation-delay: .16s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(9) {
    -webkit-animation-delay: .18s;
    -o-animation-delay: .18s;
    animation-delay: .18s
}

.dropdown-menu.animate.animate-reverse > li:nth-last-child(10) {
    -webkit-animation-delay: .2s;
    -o-animation-delay: .2s;
    animation-delay: .2s
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
    margin-bottom: 6px;
    -webkit-box-shadow: 0 -3px 12px rgba(0, 0, 0, .05);
    box-shadow: 0 -3px 12px rgba(0, 0, 0, .05)
}

.dropup .dropdown-menu.bullet, .navbar-fixed-bottom .dropdown .dropdown-menu.bullet {
    margin-bottom: 12px
}

.dropup .dropdown-menu.bullet:after, .dropup .dropdown-menu.bullet:before, .navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after, .navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
    top: auto;
    border-top-width: 7px;
    border-bottom-width: 0
}

.dropup .dropdown-menu.bullet:before, .navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
    bottom: -7px;
    border-top-color: #e4eaec
}

.dropup .dropdown-menu.bullet:after, .navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
    bottom: -6px;
    border-top-color: #fff
}

.dropdown-menu > .dropdown-header {
    padding: 8px 20px 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: default
}

.dropdown-menu > .dropdown-submenu {
    position: relative
}

.dropdown-menu > .dropdown-submenu > a {
    position: relative
}

.dropdown-menu > .dropdown-submenu > a:after {
    position: absolute;
    right: 10px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-top: 6px;
    vertical-align: middle;
    content: '';
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px dashed
}

.dropdown-menu > .dropdown-submenu .dropdown-menu {
    left: 100%;
    margin: 0
}

.dropdown-menu > .dropdown-submenu.dropdown-menu-left .dropdown-menu {
    left: -100%
}

.dropdown-menu > .dropdown-submenu:hover .dropdown-menu {
    display: block
}

.dropdown .dropdown-submenu .dropdown-menu {
    top: 0
}

.dropup .dropdown-submenu .dropdown-menu {
    bottom: 0
}

.dropdown-menu-media {
    padding-top: 0;
    padding-bottom: 0;
    width: 360px
}

.dropdown-menu-media > li {
    padding: 0;
    margin: 0
}

.dropdown-menu-media .dropdown-menu-header {
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid #e4eaec;
    position: relative
}

.dropdown-menu-media .dropdown-menu-header > h3, .dropdown-menu-media .dropdown-menu-header > h4, .dropdown-menu-media .dropdown-menu-header > h5 {
    margin: 0
}

.dropdown-menu-media .dropdown-menu-header .badge, .dropdown-menu-media .dropdown-menu-header .label {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.dropdown-menu-media .list-group {
    font-size: 12px;
    margin: 0;
    border-radius: 0;
    max-height: 270px
}

.dropdown-menu-media .list-group-item {
    padding: 0 20px;
    border-radius: 0 !important;
    border: none
}

.dropdown-menu-media .list-group-item .media {
    padding: 15px 0;
    border-top: 1px solid #e4eaec
}

.dropdown-menu-media .list-group-item:first-child .media {
    border-top: none
}

.dropdown-menu-media > .dropdown-menu-footer {
    border-top: 1px solid #e4eaec;
    background-color: #f3f7f9
}

.dropdown-menu-media > .dropdown-menu-footer > a {
    padding: 15px 20px !important;
    color: #a3afb7 !important
}

.dropdown-menu-media > .dropdown-menu-footer > a:hover {
    background-color: transparent !important;
    color: #89bceb !important
}

.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn {
    position: absolute;
    right: 0
}

.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn:hover {
    color: #89bceb !important;
    background-color: transparent !important
}

.dropdown-menu-primary > .active > a, .dropdown-menu-primary > .active > a:focus, .dropdown-menu-primary > .active > a:hover {
    color: #fff;
    background-color: #62a8ea
}

.dropdown-menu-success > .active > a, .dropdown-menu-success > .active > a:focus, .dropdown-menu-success > .active > a:hover {
    color: #fff;
    background-color: #46be8a
}

.dropdown-menu-info > .active > a, .dropdown-menu-info > .active > a:focus, .dropdown-menu-info > .active > a:hover {
    color: #fff;
    background-color: #57c7d4
}

.dropdown-menu-warning > .active > a, .dropdown-menu-warning > .active > a:focus, .dropdown-menu-warning > .active > a:hover {
    color: #fff;
    background-color: #f2a654
}

.dropdown-menu-danger > .active > a, .dropdown-menu-danger > .active > a:focus, .dropdown-menu-danger > .active > a:hover {
    color: #fff;
    background-color: #f96868
}

.dropdown-menu-dark > .active > a, .dropdown-menu-dark > .active > a:focus, .dropdown-menu-dark > .active > a:hover {
    color: #fff;
    background-color: #526069
}

.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05)
}

.btn-group:focus .dropdown-toggle {
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.input-group-addon {
    -webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
    -o-transition: border .25s linear, color .25s linear, background-color .25s linear;
    transition: border .25s linear, color .25s linear, background-color .25s linear
}

.input-group-btn .btn {
    padding: 6px 10px
}

.input-group-btn .btn > .icon {
    vertical-align: bottom
}

.input-group-btn .dropdown-toggle.btn .caret {
    margin-left: 2px
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
    z-index: 1
}

.nav > li > a {
    overflow: hidden
}

.nav > li > a:focus {
    outline: 0
}

.nav > li > a .close {
    display: inline-block;
    margin-left: 10px
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    border-color: transparent
}

.nav-quick {
    padding: 0;
    margin-bottom: 22px;
    background-color: #fff;
    border-radius: 3px;
    margin-left: 0;
    margin-right: 0;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.nav-quick li {
    position: relative;
    display: block;
    list-style: none;
    padding: 0;
    text-align: center
}

.nav-quick a {
    display: block;
    padding: 16px 0;
    color: #76838f
}

.nav-quick a .icon {
    display: block;
    margin-bottom: .2em;
    font-size: 32px
}

.nav-quick a:hover {
    text-decoration: none;
    background-color: #f3f7f9
}

.nav-quick .badge, .nav-quick .label {
    position: absolute;
    top: 0;
    right: 0
}

.nav-quick-sm a {
    padding: 12px 0
}

.nav-quick-sm a .icon {
    font-size: 24px
}

.nav-quick-lg a {
    padding: 22px 0
}

.nav-quick-lg a .icon {
    font-size: 40px
}

.nav-quick-bordered {
    border-top: 1px solid #e4eaec;
    border-left: 1px solid #e4eaec
}

.nav-quick-bordered li {
    border-bottom: 1px solid #e4eaec;
    border-right: 1px solid #e4eaec
}

.nav-tabs > li > a {
    padding: 10px 20px;
    color: #76838f;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.nav-tabs > li > a > .icon {
    line-height: 1;
    margin-right: .5em
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #fff;
    background-color: #62a8ea;
    border-color: transparent;
    border-bottom-color: #62a8ea
}

.nav-tabs.nav-justified > li > a {
    border-radius: 4px 4px 0 0
}

.nav-tabs.nav-justified > li.active > a, .nav-tabs.nav-justified > li.active > a:focus, .nav-tabs.nav-justified > li.active > a:hover {
    border-color: transparent;
    border-bottom-color: #62a8ea
}

.nav-tabs.nav-tabs-bottom {
    border-top: 1px solid #e4eaec;
    border-bottom: none
}

.nav-tabs.nav-tabs-bottom > li {
    margin-top: -1px;
    margin-bottom: 0
}

.nav-tabs.nav-tabs-bottom > li > a {
    border-radius: 0 0 4px 4px
}

.nav-tabs.nav-tabs-bottom > li > a:focus, .nav-tabs.nav-tabs-bottom > li > a:hover {
    border-bottom-color: transparent;
    border-top-color: #e4eaec
}

.nav-tabs.nav-tabs-bottom.nav-justified {
    border-top: none
}

.nav-tabs.nav-tabs-bottom.nav-justified > li > a {
    border-bottom-color: transparent;
    border-top-color: #e4eaec
}

.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a, .nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:focus, .nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:hover {
    border-top: 1px solid #62a8ea
}

.nav-tabs-reverse > li {
    float: right
}

.nav-tabs-reverse > li > a {
    margin-right: 0;
    margin-left: 2px
}

.nav-tabs-solid {
    border-bottom-color: #f3f7f9
}

.nav-tabs-solid > li > a:hover {
    border-color: transparent
}

.nav-tabs-solid > li.active > a, .nav-tabs-solid > li.active > a:focus, .nav-tabs-solid > li.active > a:hover {
    color: #76838f;
    background-color: #f3f7f9;
    border-color: transparent
}

.nav-tabs-solid ~ .tab-content {
    padding: 20px;
    background-color: #f3f7f9
}

.nav-tabs-solid.nav-justified > li > a {
    border: none
}

.nav-tabs-solid.nav-justified > li.active > a, .nav-tabs-solid.nav-justified > li.active > a:focus, .nav-tabs-solid.nav-justified > li.active > a:hover {
    border: none
}

.nav-tabs-solid.nav-tabs-bottom > li.active > a, .nav-tabs-solid.nav-tabs-bottom > li.active > a:focus, .nav-tabs-solid.nav-tabs-bottom > li.active > a:hover {
    border: none
}

.nav-tabs-line > li > a {
    padding: 10px 20px;
    border-bottom: 2px solid transparent
}

.nav-tabs-line > li > a:focus, .nav-tabs-line > li > a:hover {
    background-color: transparent
}

.nav-tabs-line > li > a:hover {
    border-bottom-color: #ccd5db
}

.nav-tabs-line > li.active > a, .nav-tabs-line > li.active > a:focus, .nav-tabs-line > li.active > a:hover {
    color: #62a8ea;
    background-color: transparent;
    border-bottom: 2px solid #62a8ea
}

.nav-tabs-line .open > a, .nav-tabs-line .open > a:focus, .nav-tabs-line .open > a:hover {
    border-color: transparent;
    border-bottom-color: #ccd5db
}

.nav-tabs-line.nav-tabs-bottom > li > a {
    border-top: 2px solid transparent;
    border-bottom: none
}

.nav-tabs-line.nav-tabs-bottom > li > a:hover {
    border-top-color: #ccd5db;
    border-bottom-color: transparent
}

.nav-tabs-line.nav-tabs-bottom > li.active > a, .nav-tabs-line.nav-tabs-bottom > li.active > a:focus, .nav-tabs-line.nav-tabs-bottom > li.active > a:hover {
    border-top: 2px solid #62a8ea;
    border-bottom: none
}

.nav-tabs-line.nav-justified > li > a {
    border-bottom: 2px solid #e4eaec
}

.nav-tabs-line.nav-justified > li > a:hover {
    border-bottom-color: #ccd5db
}

.nav-tabs-line.nav-justified > li.active > a, .nav-tabs-line.nav-justified > li.active > a:focus, .nav-tabs-line.nav-justified > li.active > a:hover {
    border-color: transparent;
    border-bottom: 2px solid #62a8ea
}

.nav-tabs-line.nav-justified.nav-tabs-bottom {
    border-top: none
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a {
    border-top: 2px solid #e4eaec;
    border-bottom: none
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a:hover {
    border-top-color: #ccd5db
}

.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a, .nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:focus, .nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:hover {
    border-top-color: #62a8ea;
    border-bottom: none
}

.nav-tabs-vertical:after, .nav-tabs-vertical:before {
    content: " ";
    display: table
}

.nav-tabs-vertical:after {
    clear: both
}

.nav-tabs-vertical .nav-tabs {
    float: left;
    border-right: 1px solid #e4eaec;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs > li {
    float: none;
    margin-right: -1px;
    margin-bottom: 0
}

.nav-tabs-vertical .nav-tabs > li > a {
    padding: 10px 20px;
    margin-right: 0;
    margin-bottom: 2px;
    border-radius: 4px 0 0 4px
}

.nav-tabs-vertical .nav-tabs > li > a:hover {
    border-bottom-color: transparent;
    border-right-color: #e4eaec
}

.nav-tabs-vertical .nav-tabs > li.active > a, .nav-tabs-vertical .nav-tabs > li.active > a:focus, .nav-tabs-vertical .nav-tabs > li.active > a:hover {
    border-right-color: #62a8ea
}

.nav-tabs-vertical .nav-tabs-reverse {
    float: right;
    border-right: none;
    border-left: 1px solid #e4eaec
}

.nav-tabs-vertical .nav-tabs-reverse > li {
    margin-right: 0;
    margin-left: -1px
}

.nav-tabs-vertical .nav-tabs-reverse > li > a {
    margin-left: 0;
    border-radius: 0 4px 4px 0
}

.nav-tabs-vertical .nav-tabs-reverse > li > a:hover {
    border-right-color: transparent;
    border-left-color: #e4eaec
}

.nav-tabs-vertical .nav-tabs-reverse > li.active > a, .nav-tabs-vertical .nav-tabs-reverse > li.active > a:focus, .nav-tabs-vertical .nav-tabs-reverse > li.active > a:hover {
    border-left-color: #62a8ea
}

.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #f3f7f9
}

.nav-tabs-vertical .nav-tabs-solid > li > a:hover {
    border-color: transparent
}

.nav-tabs-vertical .nav-tabs-solid > li.active > a, .nav-tabs-vertical .nav-tabs-solid > li.active > a:focus, .nav-tabs-vertical .nav-tabs-solid > li.active > a:hover {
    border-color: transparent
}

.nav-tabs-vertical .nav-tabs-solid + .tab-content {
    padding: 20px
}

.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #f3f7f9
}

.nav-tabs-vertical .nav-tabs-line > li > a {
    border-right: 2px solid transparent;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs-line > li > a:hover {
    border-right-color: #ccd5db
}

.nav-tabs-vertical .nav-tabs-line > li.active > a, .nav-tabs-vertical .nav-tabs-line > li.active > a:focus, .nav-tabs-vertical .nav-tabs-line > li.active > a:hover {
    border-right: 2px solid #62a8ea;
    border-bottom: none
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a {
    border-right-width: 1px;
    border-left: 2px solid transparent
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a:hover {
    border-color: transparent;
    border-left-color: #ccd5db
}

.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a, .nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:focus, .nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:hover {
    border-right: 1px solid transparent;
    border-left: 2px solid #62a8ea
}

.nav-tabs-vertical .tab-content {
    overflow: hidden
}

.nav-tabs-inverse .nav-tabs-solid {
    border-bottom-color: #fff
}

.nav-tabs-inverse .nav-tabs-solid > li.active > a, .nav-tabs-inverse .nav-tabs-solid > li.active > a:focus, .nav-tabs-inverse .nav-tabs-solid > li.active > a:hover {
    color: #76838f;
    background-color: #fff
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #fff
}

.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #fff
}

.nav-tabs-inverse .tab-content {
    background: #fff
}

.nav-tabs-animate .tab-content {
    overflow: hidden
}

.nav-tabs-lg > li > a {
    padding: 12px 20px;
    font-size: 18px;
    line-height: 1.3333333
}

.nav-tabs-sm > li > a {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5
}

.navbar-toggle {
    -webkit-transition: color .25s linear;
    -o-transition: color .25s linear;
    transition: color .25s linear;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 22px 15px;
    line-height: 22px;
    height: 66px;
    margin-top: 0;
    margin-bottom: 0;
    background: 0 0 !important
}

.navbar-toggle .icon {
    margin-top: -1px
}

.navbar-toggle:hover {
    background: 0 0 !important
}

.navbar-toggle-left {
    float: left;
    margin-left: 15px;
    margin-right: 0
}

.navbar {
    border: none;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08)
}

.navbar-fixed-bottom, .navbar-fixed-top {
    width: 100%
}

@media (min-width: 768px) {
    .hidden-float {
        display: block
    }
}

@media (max-width: 767px) {
    .hidden-float {
        display: none !important
    }
}

.navbar-brand {
    font-weight: 500;
    padding: 22px 20px
}

.navbar-brand > .navbar-brand-logo {
    display: inline-block
}

.navbar-brand-logo {
    margin-top: -5px;
    height: 32px
}

.navbar-brand-text {
    margin-left: 6px
}

@media (max-width: 767px) {
    .navbar-brand-center {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0)
    }
}

@media (min-width: 768px) {
    .navbar-mega .container, .navbar-mega .container-fluid {
        position: relative
    }
}

.navbar-mega .dropdown-menu {
    left: auto
}

.navbar-mega .dropdown-mega {
    position: static
}

.navbar-mega .mega-content {
    padding: 20px 30px
}

.navbar-mega .mega-menu {
    min-width: 150px;
    max-width: 100%
}

.navbar-mega .mega-menu > ul {
    padding-left: 0
}

.navbar-mega .mega-menu .list-icons {
    margin-bottom: 6px
}

.navbar-mega .dropdown.dropdown-fw .dropdown-menu {
    right: 5px;
    left: 5px
}

@media (max-width: 767px) {
    .navbar-mega .dropdown.dropdown-fw .dropdown-menu {
        right: 0;
        left: 0
    }
}

.navbar-nav > li > a.navbar-avatar, .navbar-toolbar > li > a.navbar-avatar {
    padding-top: 18px;
    padding-bottom: 18px
}

@media (max-width: 767px) {
    .navbar-nav > li > a.navbar-avatar {
        padding-top: 6px;
        padding-bottom: 6px
    }
}

.navbar-avatar .avatar {
    width: 30px
}

.navbar-form .icon {
    font-size: 16px;
    color: rgba(55, 71, 79, .4)
}

.navbar-form .form-control {
    background-color: #f3f7f9;
    border: none;
    border-radius: 38px
}

@media (min-width: 768px) {
    .navbar-search.collapse {
        display: block !important;
        height: auto !important;
        overflow: visible !important;
        visibility: visible !important
    }
}

@media (max-width: 767px) {
    .navbar-search {
        padding-right: 15px;
        padding-left: 15px
    }
}

@media (max-width: 767px) {
    .navbar-search .navbar-form {
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: none
    }
}

.container-fluid > .navbar-search, .container > .navbar-search {
    margin-right: -15px;
    margin-left: -15px
}

@media (min-width: 768px) {
    .container-fluid > .navbar-search, .container > .navbar-search {
        margin-right: 0;
        margin-left: 0
    }
}

.navbar-search-overlap {
    left: 0;
    right: 0;
    top: 0;
    position: absolute !important;
    background-color: #fff
}

.navbar-search-overlap .form-control, .navbar-search-overlap .form-group {
    display: block !important;
    margin: 0
}

.navbar-search-overlap .form-control {
    background-color: transparent !important;
    height: 66px !important;
    border-radius: 0
}

.navbar-search-overlap .form-control:focus {
    border-color: transparent
}

.navbar-collapse-toolbar.in {
    overflow-y: visible
}

.navbar-toolbar {
    float: left
}

.navbar-toolbar:after, .navbar-toolbar:before {
    content: " ";
    display: table
}

.navbar-toolbar:after {
    clear: both
}

.navbar-toolbar > li {
    float: left
}

.navbar-toolbar > li:after, .navbar-toolbar > li:before {
    content: " ";
    display: table
}

.navbar-toolbar > li:after {
    clear: both
}

.navbar-toolbar > li > a {
    padding-top: 22px;
    padding-bottom: 22px;
    line-height: 22px
}

.navbar-toolbar .dropdown-menu {
    -webkit-transform-origin: 100% 0;
    -ms-transform-origin: 100% 0;
    -o-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-animation-duration: .3s;
    -o-animation-duration: .3s;
    animation-duration: .3s
}

@media (max-width: 767px) {
    .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .navbar-toolbar .open {
        position: static
    }

    .navbar-toolbar .open .dropdown-menu {
        left: 0;
        right: 0;
        width: auto;
        margin-top: 0;
        float: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0
    }
}

@media (max-width: 767px) and (max-device-width: 480px) and (orientation: landscape) {
    .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
        max-height: 200px
    }
}

@media (max-width: 767px) {
    .navbar-toolbar-left {
        float: left !important
    }

    .navbar-toolbar-right {
        float: right !important
    }
}

.icon-fullscreen {
    font-family: "Web Icons"
}

.icon-fullscreen:before {
    content: "\f11d"
}

.icon-fullscreen.active:before {
    content: "\f11e"
}

.icon-menubar {
    font-family: "Web Icons"
}

.icon-menubar:before {
    content: "\f119"
}

.icon-menubar.active:before {
    content: "\f119"
}

.navbar-default .navbar-toolbar > li > a {
    color: #76838f
}

.navbar-default .navbar-toolbar > li > a:focus, .navbar-default .navbar-toolbar > li > a:hover {
    color: #526069;
    background-color: rgba(243, 247, 249, .3)
}

.navbar-default .navbar-toolbar > .active > a, .navbar-default .navbar-toolbar > .active > a:focus, .navbar-default .navbar-toolbar > .active > a:hover {
    color: #526069;
    background-color: rgba(243, 247, 249, .6)
}

.navbar-default .navbar-toolbar > .disabled > a, .navbar-default .navbar-toolbar > .disabled > a:focus, .navbar-default .navbar-toolbar > .disabled > a:hover {
    color: #ccd5db;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    color: #76838f
}

.navbar-default .navbar-toolbar > .open > a, .navbar-default .navbar-toolbar > .open > a:focus, .navbar-default .navbar-toolbar > .open > a:hover {
    color: #526069;
    background-color: rgba(243, 247, 249, .6)
}

.navbar-inverse .navbar-toolbar > li > a {
    color: #fff
}

.navbar-inverse .navbar-toolbar > li > a:focus, .navbar-inverse .navbar-toolbar > li > a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-toolbar > .active > a, .navbar-inverse .navbar-toolbar > .active > a:focus, .navbar-inverse .navbar-toolbar > .active > a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.navbar-inverse .navbar-toolbar > .disabled > a, .navbar-inverse .navbar-toolbar > .disabled > a:focus, .navbar-inverse .navbar-toolbar > .disabled > a:hover {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    color: #fff
}

.navbar-inverse .navbar-toolbar > .open > a, .navbar-inverse .navbar-toolbar > .open > a:focus, .navbar-inverse .navbar-toolbar > .open > a:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, .1)
}

.breadcrumb {
    margin-bottom: 10px
}

.breadcrumb li + li:before {
    padding: 0 5px
}

.breadcrumb li .icon {
    text-decoration: none
}

.breadcrumb li .icon:before {
    margin-right: 10px
}

.breadcrumb-arrow > li + li:before {
    content: "\00bb\00a0"
}

.pagination li > a, .pagination li > span {
    padding: 9px 15px;
    -webkit-transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
    -o-transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
    transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out
}

.pagination li > a:focus, .pagination li > a:hover, .pagination li > span:focus, .pagination li > span:hover {
    -webkit-transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
    -o-transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
    transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out
}

.pagination li .icon {
    margin-top: -1px
}

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
    color: #ccd5db;
    cursor: not-allowed;
    background-color: transparent;
    border-color: #e4eaec
}

.pagination-gap > li > a {
    margin: 0 5px;
    border-radius: 5px
}

.pagination-gap > li > a:hover {
    background-color: transparent;
    border-color: #62a8ea
}

.pagination-gap > li:first-child > a, .pagination-gap > li:last-child > a {
    border-radius: 5px
}

.pagination-no-border > li > a {
    border: none
}

.pagination-lg > li > a, .pagination-lg > li > span {
    padding: 10px 17px;
    font-size: 16px;
    line-height: 1.3333333
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px
}

.pagination-sm > li > a, .pagination-sm > li > span {
    padding: 6px 11px;
    font-size: 14px;
    line-height: 1.5
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px
}

.pager li > a, .pager li > span {
    padding: 10px 20px;
    color: #76838f;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease
}

.pager li > a:focus, .pager li > a:hover {
    color: #62a8ea
}

.pager li .icon {
    margin-top: -1px
}

.pager li > a:focus, .pager li > a:hover {
    border-color: #62a8ea
}

.pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
    border-color: #e4eaec
}

.pager-round li > a, .pager-round li > span {
    border-radius: 1000px
}

.label {
    padding: .25em .6em .25em;
    font-weight: 300;
    border-radius: .3em
}

.label.label-outline {
    color: #f3f7f9;
    background-color: transparent;
    border-color: #f3f7f9
}

.label-outline {
    border: 1px solid transparent
}

.label-round {
    border-radius: 1em
}

.label-default {
    color: #76838f;
    background-color: #e4eaec
}

.label-default[href]:focus, .label-default[href]:hover {
    background-color: #f3f7f9
}

.label-default.label-outline {
    color: #e4eaec;
    background-color: transparent;
    border-color: #e4eaec
}

.label-default[href]:focus, .label-default[href]:hover {
    color: #a3afb7
}

.label-default.label-outline {
    color: #76838f
}

.label-primary {
    background-color: #62a8ea
}

.label-primary[href]:focus, .label-primary[href]:hover {
    background-color: #89bceb
}

.label-primary.label-outline {
    color: #62a8ea;
    background-color: transparent;
    border-color: #62a8ea
}

.label-success {
    background-color: #46be8a
}

.label-success[href]:focus, .label-success[href]:hover {
    background-color: #5cd29d
}

.label-success.label-outline {
    color: #46be8a;
    background-color: transparent;
    border-color: #46be8a
}

.label-info {
    background-color: #57c7d4
}

.label-info[href]:focus, .label-info[href]:hover {
    background-color: #77d6e1
}

.label-info.label-outline {
    color: #57c7d4;
    background-color: transparent;
    border-color: #57c7d4
}

.label-warning {
    background-color: #f2a654
}

.label-warning[href]:focus, .label-warning[href]:hover {
    background-color: #f4b066
}

.label-warning.label-outline {
    color: #f2a654;
    background-color: transparent;
    border-color: #f2a654
}

.label-danger {
    background-color: #f96868
}

.label-danger[href]:focus, .label-danger[href]:hover {
    background-color: #fa7a7a
}

.label-danger.label-outline {
    color: #f96868;
    background-color: transparent;
    border-color: #f96868
}

.label-dark {
    background-color: #526069
}

.label-dark[href]:focus, .label-dark[href]:hover {
    background-color: #76838f
}

.label-dark.label-outline {
    color: #526069;
    background-color: transparent;
    border-color: #526069
}

.label-lg {
    font-size: 16px
}

.label-sm {
    padding: .1em .5em .1em;
    font-size: 10px
}

.badge {
    padding: 3px 6px
}

.btn .badge {
    top: 0
}

.badge.up {
    position: relative;
    top: -10px;
    margin: 0 -.8em;
    border-radius: 15px
}

.badge.badge-absolute {
    position: absolute;
    top: -8px;
    right: -10px;
    z-index: 5
}

.badge-radius {
    border-radius: 3px
}

.badge-primary {
    color: #fff;
    background-color: #62a8ea
}

.badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    background-color: #358fe4
}

.list-group-item.active > .badge-primary, .nav-pills > .active > a > .badge-primary {
    color: #fff;
    background-color: #62a8ea
}

.badge-success {
    color: #fff;
    background-color: #46be8a
}

.badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    background-color: #369b6f
}

.list-group-item.active > .badge-success, .nav-pills > .active > a > .badge-success {
    color: #fff;
    background-color: #46be8a
}

.badge-info {
    color: #fff;
    background-color: #57c7d4
}

.badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    background-color: #33b6c5
}

.list-group-item.active > .badge-info, .nav-pills > .active > a > .badge-info {
    color: #fff;
    background-color: #57c7d4
}

.badge-warning {
    color: #fff;
    background-color: #f2a654
}

.badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #fff;
    background-color: #ee8d25
}

.list-group-item.active > .badge-warning, .nav-pills > .active > a > .badge-warning {
    color: #fff;
    background-color: #f2a654
}

.badge-danger {
    color: #fff;
    background-color: #f96868
}

.badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    background-color: #f73737
}

.list-group-item.active > .badge-danger, .nav-pills > .active > a > .badge-danger {
    color: #fff;
    background-color: #f96868
}

.badge-dark {
    color: #fff;
    background-color: #526069
}

.badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    background-color: #3c464c
}

.list-group-item.active > .badge-dark, .nav-pills > .active > a > .badge-dark {
    color: #fff;
    background-color: #526069
}

.badge-lg {
    padding: 5px 9px 8px;
    font-size: 16px
}

.badge-sm {
    padding: 2px 5px;
    font-size: 10px
}

.jumbotron {
    padding: 0;
    border-radius: 3px
}

.jumbotron > .jumbotron-photo img {
    width: 100%;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px
}

.jumbotron-contents {
    padding: 20px
}

.jumbotron .carousel, .jumbotron .carousel-inner, .jumbotron .carousel-inner > .item.active img {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px
}

.jumbotron .carousel-inner > .item > a > img, .jumbotron .carousel-inner > .item > img {
    width: 100%
}

.jumbotron .h1, .jumbotron h1 {
    font-size: 28px
}

.jumbotron .h2, .jumbotron h2 {
    font-size: 24px
}

@media screen and (min-width: 768px) {
    .container .jumbotron, .jumbotron {
        padding: 0
    }

    .jumbotron .h1, .jumbotron h1 {
        font-size: 28px
    }
}

.thumbnail {
    padding: 0;
    border: none;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out
}

.thumbnail .caption {
    position: relative;
    display: block;
    padding-right: 0;
    padding-left: 0
}

.alert {
    padding-right: 20px;
    padding-left: 20px
}

.alert ul {
    padding-left: 13px
}

.alert ul li {
    padding-left: 7px
}

.panel > .alert {
    margin: 0
}

.alert-alt {
    color: #76838f;
    background-color: rgba(243, 247, 249, .8);
    border: none;
    border-left: 3px solid transparent
}

.alert-alt .alert-link, .alert-alt a {
    text-decoration: none
}

.alert-dismissible {
    padding-right: 40px
}

.alert-dismissible .close {
    top: 0;
    text-decoration: none;
    opacity: .6
}

.alert-dismissible .close:focus, .alert-dismissible .close:hover {
    opacity: 1
}

.alert-dismissible.alert-alt .close {
    color: #a3afb7;
    opacity: .6
}

.alert-dismissible.alert-alt .close:focus, .alert-dismissible.alert-alt .close:hover {
    color: #a3afb7;
    opacity: 1
}

.alert-icon {
    position: relative;
    padding-left: 45px
}

.alert-icon > .icon {
    position: absolute;
    top: 18px;
    left: 20px;
    font-size: 16px;
    width: 1em;
    text-align: center
}

.alert-avatar {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 75px
}

.alert-avatar > .avatar {
    position: absolute;
    top: 12px;
    left: 20px
}

.page-alert .alert-wrap {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    -webkit-transition: max-height .7s linear 0s;
    -o-transition: max-height .7s linear 0s;
    transition: max-height .7s linear 0s
}

.page-alert .alert-wrap.in {
    max-height: 500px;
    -webkit-transition: max-height 1s linear 0s;
    -o-transition: max-height 1s linear 0s;
    transition: max-height 1s linear 0s
}

.page-alert .alert-wrap .alert {
    margin: 0;
    text-align: left;
    border-radius: 0
}

.alert-primary {
    background-color: rgba(232, 241, 248, .8);
    border-color: #e8f1f8;
    color: #62a8ea
}

.alert-primary hr {
    border-top-color: #d4e5f2
}

.alert-primary .alert-link {
    color: #358fe4
}

.alert-primary .close {
    color: #62a8ea
}

.alert-primary .close:focus, .alert-primary .close:hover {
    color: #62a8ea
}

.alert-primary .alert-link {
    color: #4e97d9
}

.alert-alt.alert-primary {
    border-color: #62a8ea
}

.alert-alt.alert-primary .alert-link, .alert-alt.alert-primary a {
    color: #62a8ea
}

.alert-success .alert-link {
    color: #36ab7a
}

.alert-alt.alert-success {
    border-color: #46be8a
}

.alert-alt.alert-success .alert-link, .alert-alt.alert-success a {
    color: #46be8a
}

.alert-info .alert-link {
    color: #47b8c6
}

.alert-alt.alert-info {
    border-color: #57c7d4
}

.alert-alt.alert-info .alert-link, .alert-alt.alert-info a {
    color: #57c7d4
}

.alert-warning .alert-link {
    color: #ec9940
}

.alert-alt.alert-warning {
    border-color: #f2a654
}

.alert-alt.alert-warning .alert-link, .alert-alt.alert-warning a {
    color: #f2a654
}

.alert-danger .alert-link {
    color: #e9595b
}

.alert-alt.alert-danger {
    border-color: #f96868
}

.alert-alt.alert-danger .alert-link, .alert-alt.alert-danger a {
    color: #f96868
}

.alert-social {
    position: relative;
    padding-left: 65px
}

.alert-social > .icon {
    position: absolute;
    top: 12px;
    left: 20px;
    bottom: 0;
    font-size: 30px;
    width: 1em;
    text-align: center
}

.alert-facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff
}

.alert-facebook hr {
    border-top-color: #344e86
}

.alert-facebook .alert-link {
    color: #e6e6e6
}

.alert-facebook .close {
    color: #fff
}

.alert-facebook .close:focus, .alert-facebook .close:hover {
    color: #fff
}

.alert-facebook .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-twitter {
    background-color: #55acee;
    border-color: #55acee;
    color: #fff
}

.alert-twitter hr {
    border-top-color: #3ea1ec
}

.alert-twitter .alert-link {
    color: #e6e6e6
}

.alert-twitter .close {
    color: #fff
}

.alert-twitter .close:focus, .alert-twitter .close:hover {
    color: #fff
}

.alert-twitter .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-google-plus {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #fff
}

.alert-google-plus hr {
    border-top-color: #d73925
}

.alert-google-plus .alert-link {
    color: #e6e6e6
}

.alert-google-plus .close {
    color: #fff
}

.alert-google-plus .close:focus, .alert-google-plus .close:hover {
    color: #fff
}

.alert-google-plus .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-linkedin {
    background-color: #0976b4;
    border-color: #0976b4;
    color: #fff
}

.alert-linkedin hr {
    border-top-color: #08669c
}

.alert-linkedin .alert-link {
    color: #e6e6e6
}

.alert-linkedin .close {
    color: #fff
}

.alert-linkedin .close:focus, .alert-linkedin .close:hover {
    color: #fff
}

.alert-linkedin .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-flickr {
    background-color: #ff0084;
    border-color: #ff0084;
    color: #fff
}

.alert-flickr hr {
    border-top-color: #e60077
}

.alert-flickr .alert-link {
    color: #e6e6e6
}

.alert-flickr .close {
    color: #fff
}

.alert-flickr .close:focus, .alert-flickr .close:hover {
    color: #fff
}

.alert-flickr .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-tumblr {
    background-color: #35465c;
    border-color: #35465c;
    color: #fff
}

.alert-tumblr hr {
    border-top-color: #2c3a4c
}

.alert-tumblr .alert-link {
    color: #e6e6e6
}

.alert-tumblr .close {
    color: #fff
}

.alert-tumblr .close:focus, .alert-tumblr .close:hover {
    color: #fff
}

.alert-tumblr .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-github {
    background-color: #4183c4;
    border-color: #4183c4;
    color: #fff
}

.alert-github hr {
    border-top-color: #3876b4
}

.alert-github .alert-link {
    color: #e6e6e6
}

.alert-github .close {
    color: #fff
}

.alert-github .close:focus, .alert-github .close:hover {
    color: #fff
}

.alert-github .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-dribbble {
    background-color: #c32361;
    border-color: #c32361;
    color: #fff
}

.alert-dribbble hr {
    border-top-color: #ad1f56
}

.alert-dribbble .alert-link {
    color: #e6e6e6
}

.alert-dribbble .close {
    color: #fff
}

.alert-dribbble .close:focus, .alert-dribbble .close:hover {
    color: #fff
}

.alert-dribbble .alert-link {
    color: #fff;
    font-weight: 500
}

.alert-youtube {
    background-color: #b31217;
    border-color: #b31217;
    color: #fff
}

.alert-youtube hr {
    border-top-color: #9c1014
}

.alert-youtube .alert-link {
    color: #e6e6e6
}

.alert-youtube .close {
    color: #fff
}

.alert-youtube .close:focus, .alert-youtube .close:hover {
    color: #fff
}

.alert-youtube .alert-link {
    color: #fff;
    font-weight: 500
}

.alert.dark .alert-link {
    color: #fff !important;
    font-weight: 500
}

.alert.dark .alert-left-border {
    border: none;
    border-left: 3px solid transparent
}

.alert.dark.alert-dismissible.alert-alt .close {
    color: #fff
}

.alert.dark.alert-dismissible.alert-alt .close:focus, .alert.dark.alert-dismissible.alert-alt .close:hover {
    color: #fff
}

.alert.dark.alert-primary {
    background-color: #62a8ea;
    border-color: #62a8ea;
    color: #fff
}

.alert.dark.alert-primary hr {
    border-top-color: #4c9ce7
}

.alert.dark.alert-primary .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-primary .close {
    color: #fff
}

.alert.dark.alert-primary .close:focus, .alert.dark.alert-primary .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-primary {
    border-color: #2771b4
}

.alert-alt.alert.dark.alert-primary .alert-link, .alert-alt.alert.dark.alert-primary a {
    color: #fff
}

.alert.dark.alert-success {
    background-color: #46be8a;
    border-color: #46be8a;
    color: #fff
}

.alert.dark.alert-success hr {
    border-top-color: #3dae7d
}

.alert.dark.alert-success .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-success .close {
    color: #fff
}

.alert.dark.alert-success .close:focus, .alert.dark.alert-success .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-success {
    border-color: #247151
}

.alert-alt.alert.dark.alert-success .alert-link, .alert-alt.alert.dark.alert-success a {
    color: #fff
}

.alert.dark.alert-info {
    background-color: #57c7d4;
    border-color: #57c7d4;
    color: #fff
}

.alert.dark.alert-info hr {
    border-top-color: #43c0cf
}

.alert.dark.alert-info .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-info .close {
    color: #fff
}

.alert.dark.alert-info .close:focus, .alert.dark.alert-info .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-info {
    border-color: #2e8893
}

.alert-alt.alert.dark.alert-info .alert-link, .alert-alt.alert.dark.alert-info a {
    color: #fff
}

.alert.dark.alert-warning {
    background-color: #f2a654;
    border-color: #f2a654;
    color: #fff
}

.alert.dark.alert-warning hr {
    border-top-color: #f09a3c
}

.alert.dark.alert-warning .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-warning .close {
    color: #fff
}

.alert.dark.alert-warning .close:focus, .alert.dark.alert-warning .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-warning {
    border-color: #cb7314
}

.alert-alt.alert.dark.alert-warning .alert-link, .alert-alt.alert.dark.alert-warning a {
    color: #fff
}

.alert.dark.alert-danger {
    background-color: #f96868;
    border-color: #f96868;
    color: #fff
}

.alert.dark.alert-danger hr {
    border-top-color: #f84f4f
}

.alert.dark.alert-danger .alert-link {
    color: #e6e6e6
}

.alert.dark.alert-danger .close {
    color: #fff
}

.alert.dark.alert-danger .close:focus, .alert.dark.alert-danger .close:hover {
    color: #fff
}

.alert-alt.alert.dark.alert-danger {
    border-color: #d91d1f
}

.alert-alt.alert.dark.alert-danger .alert-link, .alert-alt.alert.dark.alert-danger a {
    color: #fff
}

.progress {
    height: 15px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress-bar {
    line-height: 15px;
    -webkit-box-shadow: none;
    box-shadow: none
}

.progress-square {
    border-radius: 0
}

.progress-circle {
    border-radius: 1000px
}

.progress-vertical {
    min-height: 250px;
    height: 250px;
    width: 15px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 30px
}

.progress-vertical .progress-bar {
    width: 100%
}

.progress-bar-indicating.active {
    position: relative;
    -webkit-animation: none;
    -o-animation: none;
    animation: none
}

.progress-bar-indicating.active:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: #fff;
    border-radius: inherit;
    opacity: 0;
    -webkit-animation: progress-active 3s ease 0s infinite;
    -o-animation: progress-active 3s ease 0s infinite;
    animation: progress-active 3s ease 0s infinite
}

.progress-vertical .progress-bar-indicating.active:before {
    -webkit-animation-name: progress-vertical-active;
    -o-animation-name: progress-vertical-active;
    animation-name: progress-vertical-active
}

.progress-skill {
    position: relative
}

.progress-skill .progress-bar > span {
    position: absolute;
    top: 0;
    right: 10px;
    color: #526069
}

.progress-lg {
    height: 22px
}

.progress-lg.progress-vertical {
    width: 25px
}

.progress-lg .progress-bar {
    line-height: 22px
}

.progress-sm {
    height: 10px
}

.progress-sm.progress-vertical {
    width: 10px
}

.progress-sm .progress-bar {
    line-height: 10px
}

.progress-xs {
    height: 4px;
    border-radius: 1px
}

.progress-xs.progress-vertical {
    width: 4px
}

.progress-xs .progress-bar {
    line-height: 4px
}

.contextual-progress {
    margin: 20px 0
}

.contextual-progress .progress-title {
    float: left
}

.contextual-progress .progress-label {
    position: relative;
    float: right
}

.contextual-progress .progress {
    height: 2px;
    margin: 5px 0
}

@-webkit-keyframes progress-active {
    0% {
        width: 0;
        opacity: .4
    }
    100% {
        width: 100%;
        opacity: 0
    }
}

@-o-keyframes progress-active {
    0% {
        width: 0;
        opacity: .4
    }
    100% {
        width: 100%;
        opacity: 0
    }
}

@keyframes progress-active {
    0% {
        width: 0;
        opacity: .4
    }
    100% {
        width: 100%;
        opacity: 0
    }
}

@-webkit-keyframes progress-vertical-active {
    0% {
        top: 0;
        opacity: 0
    }
    100% {
        top: 175px;
        opacity: .4
    }
}

@-o-keyframes progress-vertical-active {
    0% {
        top: 0;
        opacity: 0
    }
    100% {
        top: 175px;
        opacity: .4
    }
}

@keyframes progress-vertical-active {
    0% {
        top: 0;
        opacity: 0
    }
    100% {
        top: 175px;
        opacity: .4
    }
}

.media-object {
    width: 120px
}

.media-left, .media > .pull-left {
    padding-right: 20px
}

.media-right, .media > .pull-right {
    padding-left: 20px
}

.media-body {
    overflow: auto
}

.media .media {
    padding-bottom: 0;
    border-bottom: none
}

.media-meta {
    color: #526069;
    font-size: 12px;
    margin-bottom: 3px
}

.media-lg .media-object {
    width: 160px
}

.media-lg .media {
    margin-left: -110px
}

.media-sm .media-object {
    width: 80px
}

.media-sm .media {
    margin-left: -70px
}

.media-xs .media-object {
    width: 60px
}

.media-xs .media {
    margin-left: -60px
}

@media screen and (min-width: 768px) {
    .media-body {
        overflow: hidden
    }

    .media .media {
        margin-left: 0
    }
}

.list-group .media {
    padding: 2px 0;
    border-bottom: 0
}

.list-group .media .media-left, .list-group .media .pull-left {
    padding-right: 20px
}

.list-group .media .media-right, .list-group .media .pull-right {
    padding-left: 20px
}

.list-group .media .pull-right .status {
    margin-top: 15px;
    margin-right: 5px
}

.list-group .media .media-heading {
    font-size: 14px
}

.list-group-full > .list-group-item {
    padding-left: 0;
    padding-right: 0
}

a.list-group-item {
    border-radius: 3px
}

a.list-group-item.disabled, a.list-group-item.disabled:focus, a.list-group-item.disabled:hover {
    color: #ccd5db;
    background-color: #f3f7f9
}

a.list-group-item.active, a.list-group-item.active:focus, a.list-group-item.active:hover {
    color: #fff;
    background-color: #62a8ea
}

.list-group-item .icon {
    margin-right: 10px
}

.list-group.bg-inherit {
    border-radius: 3px
}

.list-group.bg-inherit .list-group-item {
    background-color: transparent;
    border-bottom-color: rgba(0, 0, 0, .075)
}

.list-group.bg-inherit .list-group-item:last-child {
    border-bottom-color: transparent
}

.list-group.bg-inherit .list-group-item:hover {
    background-color: rgba(0, 0, 0, .075);
    border-color: transparent
}

.list-group-bordered .list-group-item {
    border-color: #e4eaec
}

.list-group-bordered .list-group-item.active, .list-group-bordered .list-group-item.active:focus, .list-group-bordered .list-group-item.active:hover {
    color: #fff;
    background-color: #4e97d9;
    border-color: #4e97d9
}

.list-group-dividered .list-group-item {
    border-top-color: #e4eaec
}

.list-group-dividered .list-group-item.active:hover {
    border-top-color: #e4eaec
}

.list-group-dividered .list-group-item:last-child {
    border-bottom-color: #e4eaec
}

.list-group-dividered .list-group-item:first-child {
    border-top-color: transparent
}

.list-group-dividered .list-group-item:first-child.active:hover {
    border-top-color: transparent
}

.list-group-gap .list-group-item {
    margin-bottom: 2px;
    border-radius: 3px
}

.list-group-full .list-group-item {
    padding-right: 0;
    padding-left: 0
}

.list-group-item-dark {
    color: #fff;
    background-color: #526069
}

a.list-group-item-dark, button.list-group-item-dark {
    color: #fff
}

a.list-group-item-dark .list-group-item-heading, button.list-group-item-dark .list-group-item-heading {
    color: inherit
}

a.list-group-item-dark:focus, a.list-group-item-dark:hover, button.list-group-item-dark:focus, button.list-group-item-dark:hover {
    color: #fff;
    background-color: #47535b
}

a.list-group-item-dark.active, a.list-group-item-dark.active:focus, a.list-group-item-dark.active:hover, button.list-group-item-dark.active, button.list-group-item-dark.active:focus, button.list-group-item-dark.active:hover {
    color: #fff;
    background-color: #fff;
    border-color: #fff
}

.panel {
    position: relative;
    border-width: 0;
    margin-bottom: 30px
}

.panel > .nav-tabs-vertical .nav-tabs {
    margin-left: -1px
}

.panel > .nav-tabs-vertical .nav-tabs > li > a {
    border-left: none;
    border-radius: 0
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse {
    margin-right: -1px
}

.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse > li > a {
    border-right: none;
    border-radius: 0
}

.panel:hover .panel-actions .show-on-hover {
    display: inline-block
}

.panel .panel-actions .show-on-hover {
    display: none
}

.panel.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    border-radius: 0
}

.panel.is-fullscreen .panel-loading {
    border-radius: 0
}

.panel.is-fullscreen .panel-actions [data-toggle=collapse] {
    display: none
}

.panel.is-fullscreen .panel-body {
    overflow: auto;
    max-height: 100%
}

.panel.is-close {
    display: none
}

.panel.is-collapse .panel-body {
    display: none;
    height: 0
}

.panel > .alert {
    padding-left: 30px;
    padding-right: 30px
}

.panel > .alert-dismissible {
    padding-right: 50px
}

@media screen and (max-width: 480px) {
    .panel > .alert {
        padding-left: 20px;
        padding-right: 20px
    }

    .panel > .alert-dismissible {
        padding-right: 40px
    }
}

.panel > .table-responsive > .table > tbody > tr > td:first-child, .panel > .table-responsive > .table > tbody > tr > th:first-child, .panel > .table-responsive > .table > tfoot > tr > td:first-child, .panel > .table-responsive > .table > tfoot > tr > th:first-child, .panel > .table-responsive > .table > thead > tr > td:first-child, .panel > .table-responsive > .table > thead > tr > th:first-child, .panel > .table-responsive > .table > tr > td:first-child, .panel > .table-responsive > .table > tr > th:first-child, .panel > .table > tbody > tr > td:first-child, .panel > .table > tbody > tr > th:first-child, .panel > .table > tfoot > tr > td:first-child, .panel > .table > tfoot > tr > th:first-child, .panel > .table > thead > tr > td:first-child, .panel > .table > thead > tr > th:first-child, .panel > .table > tr > td:first-child, .panel > .table > tr > th:first-child {
    padding-left: 30px
}

@media screen and (max-width: 480px) {
    .panel > .table-responsive > .table > tbody > tr > td:first-child, .panel > .table-responsive > .table > tbody > tr > th:first-child, .panel > .table-responsive > .table > tfoot > tr > td:first-child, .panel > .table-responsive > .table > tfoot > tr > th:first-child, .panel > .table-responsive > .table > thead > tr > td:first-child, .panel > .table-responsive > .table > thead > tr > th:first-child, .panel > .table-responsive > .table > tr > td:first-child, .panel > .table-responsive > .table > tr > th:first-child, .panel > .table > tbody > tr > td:first-child, .panel > .table > tbody > tr > th:first-child, .panel > .table > tfoot > tr > td:first-child, .panel > .table > tfoot > tr > th:first-child, .panel > .table > thead > tr > td:first-child, .panel > .table > thead > tr > th:first-child, .panel > .table > tr > td:first-child, .panel > .table > tr > th:first-child {
        padding-left: 20px
    }
}

.panel > .table-responsive > .table > tbody > tr > td:last-child, .panel > .table-responsive > .table > tbody > tr > th:last-child, .panel > .table-responsive > .table > tfoot > tr > td:last-child, .panel > .table-responsive > .table > tfoot > tr > th:last-child, .panel > .table-responsive > .table > thead > tr > td:last-child, .panel > .table-responsive > .table > thead > tr > th:last-child, .panel > .table-responsive > .table > tr > td:last-child, .panel > .table-responsive > .table > tr > th:last-child, .panel > .table > tbody > tr > td:last-child, .panel > .table > tbody > tr > th:last-child, .panel > .table > tfoot > tr > td:last-child, .panel > .table > tfoot > tr > th:last-child, .panel > .table > thead > tr > td:last-child, .panel > .table > thead > tr > th:last-child, .panel > .table > tr > td:last-child, .panel > .table > tr > th:last-child {
    padding-right: 30px
}

@media screen and (max-width: 480px) {
    .panel > .table-responsive > .table > tbody > tr > td:last-child, .panel > .table-responsive > .table > tbody > tr > th:last-child, .panel > .table-responsive > .table > tfoot > tr > td:last-child, .panel > .table-responsive > .table > tfoot > tr > th:last-child, .panel > .table-responsive > .table > thead > tr > td:last-child, .panel > .table-responsive > .table > thead > tr > th:last-child, .panel > .table-responsive > .table > tr > td:last-child, .panel > .table-responsive > .table > tr > th:last-child, .panel > .table > tbody > tr > td:last-child, .panel > .table > tbody > tr > th:last-child, .panel > .table > tfoot > tr > td:last-child, .panel > .table > tfoot > tr > th:last-child, .panel > .table > thead > tr > td:last-child, .panel > .table > thead > tr > th:last-child, .panel > .table > tr > td:last-child, .panel > .table > tr > th:last-child {
        padding-right: 20px
    }
}

.panel > .table > tbody:first-child > tr:first-child td, .panel > .table > tbody:first-child > tr:first-child th {
    border-top: 1px solid #e4eaec
}

.panel > .list-group > .list-group-item {
    padding-right: 30px;
    padding-left: 30px
}

@media screen and (max-width: 480px) {
    .panel > .list-group > .list-group-item {
        padding-left: 20px;
        padding-right: 20px
    }
}

.panel-content > .row {
    padding-left: 30px;
    padding-right: 30px
}

.panel-content > .row > [class*=col-] {
    padding-left: 30px;
    padding-right: 30px
}

.panel-heading {
    position: relative;
    padding: 0;
    border-bottom: 1px solid transparent
}

.panel-heading + .alert {
    border-radius: 0
}

.panel-heading > .nav-tabs {
    border-bottom: none
}

.panel-heading + .nav-tabs {
    margin-top: -10px
}

.panel-body {
    position: relative
}

.panel-heading + .panel-body {
    padding-top: 0
}

.panel-body .h1:first-child, .panel-body .h2:first-child, .panel-body .h3:first-child, .panel-body .h4:first-child, .panel-body .h5:first-child, .panel-body .h6:first-child, .panel-body h1:first-child, .panel-body h2:first-child, .panel-body h3:first-child, .panel-body h4:first-child, .panel-body h5:first-child, .panel-body h6:first-child {
    margin-top: 0
}

.panel-body > :last-child {
    margin-bottom: 0
}

.panel-body > .list-group-dividered:only-child > .list-group-item:last-child {
    border-bottom-color: transparent
}

.panel-footer {
    border-top: 1px solid transparent
}

.table + .panel-footer {
    padding-top: 15px;
    border-color: #e4eaec
}

.panel-title {
    display: block;
    padding: 20px 30px;
    font-size: 18px;
    color: #37474f
}

.panel-title > .icon {
    margin-right: 10px
}

.panel-title > .label {
    margin-left: 10px
}

.panel-title small {
    color: #76838f
}

.panel-desc {
    display: block;
    padding: 5px 0 0;
    margin: 0;
    font-size: 14px;
    color: #76838f
}

.panel-actions {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin: auto;
    z-index: 1
}

@media screen and (max-width: 480px) {
    .panel-actions {
        right: 20px
    }
}

ul.panel-actions {
    list-style: none
}

ul.panel-actions > li {
    display: inline-block;
    margin-left: 8px
}

ul.panel-actions > li:first-child {
    margin-left: 0
}

.panel-actions a {
    color: inherit
}

.panel-actions a.dropdown-toggle {
    text-decoration: none
}

.panel-actions .dropdown {
    display: inline-block
}

.panel-actions .dropdown-toggle {
    display: inline-block
}

.panel-actions .panel-action {
    display: inline-block;
    padding: 8px 10px;
    color: #a3afb7;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent
}

.panel-actions .panel-action:hover {
    color: #526069
}

.panel-actions .panel-action:active {
    color: #526069
}

.panel-actions .panel-action:focus {
    outline: 0
}

.panel-actions .progress {
    width: 100px;
    margin: 0
}

.panel-actions .pagination {
    margin: 0
}

.panel-toolbar {
    padding: 5px 15px;
    margin: 0;
    background-color: transparent;
    border-bottom: 1px solid #e4eaec;
    border-top: 1px solid #e4eaec
}

.panel-bordered .panel-toolbar {
    border-top-color: transparent
}

.panel-toolbar .btn {
    color: #a3afb7;
    padding: 5px 10px
}

.panel-toolbar .btn.icon {
    width: 1em;
    text-align: center
}

.panel-toolbar .btn.active, .panel-toolbar .btn:active, .panel-toolbar .btn:hover {
    color: #76838f
}

.panel-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    opacity: .6
}

.panel-loading .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.panel > :not(.panel-loading):not(.collapsing) {
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.panel.is-loading > :not(.panel-loading) {
    opacity: .3
}

.panel.is-loading .panel-loading {
    display: block;
    opacity: 1
}

.panel-footer-chart {
    padding: 0
}

.panel-control {
    padding: 0;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.panel-body.scrollable-vertical {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
    padding-left: 30px;
    padding-right: 30px
}

@media screen and (max-width: 480px) {
    .panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
        padding-left: 20px;
        padding-right: 20px
    }
}

.panel-body.scrollable-vertical > .scrollable-bar {
    margin-top: 0;
    margin-bottom: 30px;
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    -ms-transform: translateX(-26px);
    -webkit-transform: translateX(-26px);
    -o-transform: translateX(-26px);
    transform: translateX(-26px)
}

.panel-bordered > .panel-body.scrollable-vertical > .scrollable-bar {
    margin-bottom: 30px;
    height: -webkit-calc(100% - 60px);
    height: calc(100% - 60px)
}

.panel-body.scrollable-horizontal {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
    padding-top: 0;
    padding-bottom: 30px
}

.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
    padding-top: 30px;
    padding-bottom: 30px
}

.panel-body.scrollable-horizontal > .scrollable-bar {
    margin-left: 0;
    margin-right: 30px;
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
    -ms-transform: translateY(-26px);
    -webkit-transform: translateY(-26px);
    -o-transform: translateY(-26px);
    transform: translateY(-26px)
}

@media screen and (max-width: 480px) {
    .panel-body.scrollable-horizontal > .scrollable-bar {
        margin-right: 20px;
        width: -webkit-calc(100% - 40px);
        width: calc(100% - 40px)
    }
}

.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-bar {
    -ms-transform: translateY(-26px);
    -webkit-transform: translateY(-26px);
    -o-transform: translateY(-26px);
    transform: translateY(-26px)
}

.panel-bordered > .panel-heading {
    border-bottom: 1px solid #e4eaec
}

.panel-bordered > .panel-heading > .panel-title {
    padding-bottom: 20px
}

.panel-bordered > .panel-footer {
    border-top: 1px solid #e4eaec;
    padding-top: 15px
}

.panel-bordered > .panel-body {
    padding-top: 30px
}

.panel-bordered > .table > tbody:first-child > tr:first-child td, .panel-bordered > .table > tbody:first-child > tr:first-child th {
    border-top: 0
}

.panel.is-dragging {
    opacity: .8
}

.panel.is-dragging {
    cursor: move
}

.panel.panel-transparent {
    background: 0 0;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.panel.panel-transparent > .panel-footer, .panel.panel-transparent > .panel-heading {
    border-color: transparent
}

.panel-dark {
    border-color: #526069
}

.panel-dark > .panel-heading {
    color: #fff;
    background-color: #526069;
    border-color: #526069
}

.panel-dark > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #526069
}

.panel-dark > .panel-heading .badge {
    color: #526069;
    background-color: #fff
}

.panel-dark > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #526069
}

.panel-danger, .panel-dark, .panel-info, .panel-primary, .panel-success, .panel-warning {
    border: none
}

.panel-danger .panel-heading, .panel-dark .panel-heading, .panel-info .panel-heading, .panel-primary .panel-heading, .panel-success .panel-heading, .panel-warning .panel-heading {
    border: none
}

.panel-danger .panel-title, .panel-dark .panel-title, .panel-info .panel-title, .panel-primary .panel-title, .panel-success .panel-title, .panel-warning .panel-title {
    color: #fff
}

.panel-danger .panel-action, .panel-dark .panel-action, .panel-info .panel-action, .panel-primary .panel-action, .panel-success .panel-action, .panel-warning .panel-action {
    color: #fff
}

@media screen and (max-width: 480px) {
    .panel-actions {
        right: 20px
    }

    .panel-actions .progress {
        min-width: 80px
    }

    .panel-actions .show-on-hover {
        display: none
    }

    .panel-body, .panel-footer, .panel-title {
        padding-left: 20px;
        padding-right: 20px
    }
}

.well {
    padding: 20px
}

.well-lg {
    padding: 24px
}

.well-sm {
    padding: 12px
}

.well {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .02);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .02)
}

.well-primary {
    color: #fff;
    background-color: #62a8ea
}

.well-success {
    color: #fff;
    background-color: #46be8a
}

.well-info {
    color: #fff;
    background-color: #57c7d4
}

.well-warning {
    color: #fff;
    background-color: #f2a654
}

.well-danger {
    color: #fff;
    background-color: #f96868
}

.close.icon {
    font-size: inherit
}

body {
    font-weight: 300
}

th {
    font-weight: 400
}

b, strong {
    font-weight: 500
}

optgroup {
    font-weight: 500
}

.h1 .small, .h1 small, .h2 .small, .h2 small, .h3 .small, .h3 small, .h4 .small, .h4 small, .h5 .small, .h5 small, .h6 .small, .h6 small, h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small, h5 .small, h5 small, h6 .small, h6 small {
    font-weight: 300
}

.lead {
    font-weight: 100
}

dt {
    font-weight: 500
}

kbd kbd {
    font-weight: 500
}

label {
    font-weight: 300
}

.checkbox label, .radio label {
    font-weight: 300
}

.checkbox-inline, .radio-inline {
    font-weight: 300
}

.btn-link {
    font-weight: 300
}

.dropdown-menu > li > a {
    font-weight: 100
}

.input-group-addon {
    font-weight: 300
}

.label {
    font-weight: 500
}

.popover {
    font-weight: 300
}

.tooltip {
    font-weight: 300
}

.modal-content {
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 12px rgba(0, 0, 0, .2)
}

.modal-header {
    padding: 15px 20px;
    border-bottom: none
}

.modal-header .close {
    margin-top: 1px
}

.modal-body {
    padding: 20px
}

.modal-footer {
    padding: 6px 20px 20px;
    border-top: none
}

.modal-top {
    margin: 0 auto
}

.modal-center {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    height: 100%;
    margin: 0 auto;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: center;
    align-content: center
}

.modal-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    height: 100%;
    margin: 0 auto;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: center;
    align-content: center
}

.modal-sidebar {
    position: absolute;
    right: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    height: 100%;
    margin: 0;
    background-color: #fff;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: center;
    align-content: center
}

.modal-sidebar .modal-content {
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-sidebar .modal-header {
    border-bottom: none
}

.modal-sidebar .modal-footer {
    border-top: none
}

.modal-sidebar button.close {
    position: fixed;
    top: 20px;
    right: 20px
}

.modal.fade .modal-dialog.modal-sidebar {
    -webkit-transform: translate(25%, 0);
    -ms-transform: translate(25%, 0);
    -o-transform: translate(25%, 0);
    transform: translate(25%, 0)
}

.modal.in .modal-dialog.modal-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal-fill-in {
    background-color: transparent
}

.modal-fill-in.in {
    background-color: rgba(255, 255, 255, .95);
    opacity: 1
}

.modal-fill-in .modal-dialog {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center
}

@media (min-width: 768px) {
    .modal-fill-in .modal-dialog > * {
        width: 600px
    }

    .modal-fill-in .modal-dialog.modal-sm > * {
        width: 300px
    }

    .modal-fill-in .modal-dialog button.close {
        position: fixed;
        top: 20px;
        right: 20px;
        opacity: .5;
        filter: alpha(opacity=50);
        z-index: 1;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@media (min-width: 992px) {
    .modal-fill-in .modal-dialog.modal-lg > * {
        width: 900px
    }
}

.modal-fill-in .modal-content {
    background-color: transparent;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.modal-fill-in .modal-header {
    border-bottom: none
}

.modal-fill-in .modal-footer {
    border-top: none
}

.modal-primary .modal-header {
    background-color: #62a8ea;
    border-radius: 4px 4px 0 0
}

.modal-primary .modal-header * {
    color: #fff
}

.modal-primary .modal-header .close {
    opacity: .6
}

.modal-success .modal-header {
    background-color: #46be8a;
    border-radius: 4px 4px 0 0
}

.modal-success .modal-header * {
    color: #fff
}

.modal-success .modal-header .close {
    opacity: .6
}

.modal-info .modal-header {
    background-color: #57c7d4;
    border-radius: 4px 4px 0 0
}

.modal-info .modal-header * {
    color: #fff
}

.modal-info .modal-header .close {
    opacity: .6
}

.modal-warning .modal-header {
    background-color: #f2a654;
    border-radius: 4px 4px 0 0
}

.modal-warning .modal-header * {
    color: #fff
}

.modal-warning .modal-header .close {
    opacity: .6
}

.modal-danger .modal-header {
    background-color: #f96868;
    border-radius: 4px 4px 0 0
}

.modal-danger .modal-header * {
    color: #fff
}

.modal-danger .modal-header .close {
    opacity: .6
}

.modal.modal-fade-in-scale-up .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(.7);
    -ms-transform: scale(.7);
    -o-transform: scale(.7);
    transform: scale(.7);
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-fade-in-scale-up.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.modal.modal-slide-in-right .modal-dialog {
    opacity: 0;
    -webkit-transform: translate(20%, 0);
    -ms-transform: translate(20%, 0);
    -o-transform: translate(20%, 0);
    transform: translate(20%, 0);
    -webkit-transition: all .3s cubic-bezier(.25, .5, .5, .9) 0s;
    -o-transition: all .3s cubic-bezier(.25, .5, .5, .9) 0s;
    transition: all .3s cubic-bezier(.25, .5, .5, .9) 0s
}

.modal.modal-slide-in-right.in .modal-dialog {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal.modal-slide-from-bottom .modal-dialog {
    opacity: 0;
    -webkit-transform: translate(0, 20%);
    -ms-transform: translate(0, 20%);
    -o-transform: translate(0, 20%);
    transform: translate(0, 20%);
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-slide-from-bottom.in .modal-dialog {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.modal.modal-newspaper .modal-dialog {
    opacity: 0;
    -ms-transform: scale(0) rotate(720deg);
    -webkit-transform: scale(0) rotate(720deg);
    -o-transform: scale(0) rotate(720deg);
    transform: scale(0) rotate(720deg);
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s
}

.modal.modal-newspaper.in .modal-dialog {
    opacity: 1;
    -ms-transform: scale(1) rotate(0);
    -webkit-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0)
}

.modal.modal-fall {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-fall .modal-dialog {
    opacity: 0;
    -ms-transform: translateZ(600px) rotateX(20deg);
    -webkit-transform: translateZ(600px) rotateX(20deg);
    transform: translateZ(600px) rotateX(20deg);
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.modal.modal-fall.in .modal-dialog {
    opacity: 1;
    -ms-transform: translateZ(0) rotateX(0);
    -webkit-transform: translateZ(0) rotateX(0);
    transform: translateZ(0) rotateX(0);
    -webkit-transition: all .3s ease-in 0s;
    -o-transition: all .3s ease-in 0s;
    transition: all .3s ease-in 0s
}

.modal.modal-side-fall {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-side-fall .modal-dialog {
    -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
    -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
    transform: translate(30%) translateZ(600px) rotate(10deg);
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.modal.modal-side-fall.in .modal-dialog {
    -ms-transform: translate(0) translateZ(0) rotate(0);
    -webkit-transform: translate(0) translateZ(0) rotate(0);
    transform: translate(0) translateZ(0) rotate(0);
    -webkit-transition: all .3s ease-in 0s;
    -o-transition: all .3s ease-in 0s;
    transition: all .3s ease-in 0s
}

.modal.modal-3d-flip-horizontal {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-3d-flip-horizontal .modal-dialog {
    -webkit-transform: rotateY(-70deg);
    -ms-transform: rotateY(-70deg);
    -o-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-3d-flip-horizontal.in .modal-dialog {
    -webkit-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
    transform: rotateY(0)
}

.modal.modal-3d-flip-vertical {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-3d-flip-vertical .modal-dialog {
    -webkit-transform: rotateX(-70deg);
    -ms-transform: rotateX(-70deg);
    -o-transform: rotateX(-70deg);
    transform: rotateX(-70deg);
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-3d-flip-vertical.in .modal-dialog {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    -o-transform: rotateX(0);
    transform: rotateX(0)
}

.modal.modal-3d-sign {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-3d-sign .modal-dialog {
    -webkit-transform: rotateX(-60deg);
    -ms-transform: rotateX(-60deg);
    -o-transform: rotateX(-60deg);
    transform: rotateX(-60deg);
    -webkit-transform-origin: 50% 0 0;
    -ms-transform-origin: 50% 0 0;
    -o-transform-origin: 50% 0 0;
    transform-origin: 50% 0 0;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-3d-sign.in .modal-dialog {
    -webkit-transform: rotateX(0);
    -ms-transform: rotateX(0);
    -o-transform: rotateX(0);
    transform: rotateX(0)
}

.modal.modal-super-scaled .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(2);
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-super-scaled.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.modal.modal-just-me .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(.8);
    -ms-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.modal.modal-just-me .modal-backdrop {
    background-color: #fff
}

.modal.modal-just-me.in {
    background: #fff
}

.modal.modal-just-me.in .modal-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.modal.modal-just-me.in .modal-backdrop {
    opacity: 1
}

.modal.modal-3d-slit {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-3d-slit .modal-dialog {
    opacity: 0;
    -ms-transform: translateZ(-3000px) rotateY(90deg);
    -webkit-transform: translateZ(-3000px) rotateY(90deg);
    transform: translateZ(-3000px) rotateY(90deg);
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s
}

.modal.modal-3d-slit.in .modal-dialog {
    -webkit-animation-duration: .7s;
    -o-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: slit;
    -o-animation-name: slit;
    animation-name: slit
}

.modal.modal-rotate-from-bottom {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-rotate-from-bottom .modal-dialog {
    -ms-transform: translateY(100%) rotateX(90deg);
    -webkit-transform: translateY(100%) rotateX(90deg);
    transform: translateY(100%) rotateX(90deg);
    -webkit-transform-origin: 0 100% 0;
    -ms-transform-origin: 0 100% 0;
    -o-transform-origin: 0 100% 0;
    transform-origin: 0 100% 0;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.modal.modal-rotate-from-bottom.in .modal-dialog {
    -ms-transform: translateY(0) rotateX(0);
    -webkit-transform: translateY(0) rotateX(0);
    transform: translateY(0) rotateX(0)
}

.modal.modal-rotate-from-left {
    -webkit-perspective: 1300px;
    perspective: 1300px
}

.modal.modal-rotate-from-left .modal-dialog {
    -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    -webkit-transform-origin: 0 100% 0;
    -ms-transform-origin: 0 100% 0;
    -o-transform-origin: 0 100% 0;
    transform-origin: 0 100% 0;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.modal.modal-rotate-from-left.in .modal-dialog {
    -ms-transform: translateZ(0) translateX(0) rotateY(0);
    -webkit-transform: translateZ(0) translateX(0) rotateY(0);
    transform: translateZ(0) translateX(0) rotateY(0)
}

@-webkit-keyframes slit {
    50% {
        opacity: .5;
        -ms-transform: translateZ(-250px) rotateY(89deg);
        -webkit-transform: translateZ(-250px) rotateY(89deg);
        transform: translateZ(-250px) rotateY(89deg)
    }
    100% {
        opacity: 1;
        -ms-transform: translateZ(0) rotateY(0);
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0)
    }
}

@-o-keyframes slit {
    50% {
        opacity: .5;
        -ms-transform: translateZ(-250px) rotateY(89deg);
        -webkit-transform: translateZ(-250px) rotateY(89deg);
        transform: translateZ(-250px) rotateY(89deg)
    }
    100% {
        opacity: 1;
        -ms-transform: translateZ(0) rotateY(0);
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0)
    }
}

@keyframes slit {
    50% {
        opacity: .5;
        -ms-transform: translateZ(-250px) rotateY(89deg);
        -webkit-transform: translateZ(-250px) rotateY(89deg);
        transform: translateZ(-250px) rotateY(89deg)
    }
    100% {
        opacity: 1;
        -ms-transform: translateZ(0) rotateY(0);
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0)
    }
}

.tooltip-inner {
    padding: 6px 12px
}

.tooltip-primary + .tooltip .tooltip-inner {
    color: #fff;
    background-color: #62a8ea
}

.tooltip-primary + .tooltip.top .tooltip-arrow {
    border-top-color: #62a8ea
}

.tooltip-primary + .tooltip.right .tooltip-arrow {
    border-right-color: #62a8ea
}

.tooltip-primary + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #62a8ea
}

.tooltip-primary + .tooltip.left .tooltip-arrow {
    border-left-color: #62a8ea
}

.tooltip-success + .tooltip .tooltip-inner {
    color: #fff;
    background-color: #46be8a
}

.tooltip-success + .tooltip.top .tooltip-arrow {
    border-top-color: #46be8a
}

.tooltip-success + .tooltip.right .tooltip-arrow {
    border-right-color: #46be8a
}

.tooltip-success + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #46be8a
}

.tooltip-success + .tooltip.left .tooltip-arrow {
    border-left-color: #46be8a
}

.tooltip-info + .tooltip .tooltip-inner {
    color: #fff;
    background-color: #57c7d4
}

.tooltip-info + .tooltip.top .tooltip-arrow {
    border-top-color: #57c7d4
}

.tooltip-info + .tooltip.right .tooltip-arrow {
    border-right-color: #57c7d4
}

.tooltip-info + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #57c7d4
}

.tooltip-info + .tooltip.left .tooltip-arrow {
    border-left-color: #57c7d4
}

.tooltip-warning + .tooltip .tooltip-inner {
    color: #fff;
    background-color: #f2a654
}

.tooltip-warning + .tooltip.top .tooltip-arrow {
    border-top-color: #f2a654
}

.tooltip-warning + .tooltip.right .tooltip-arrow {
    border-right-color: #f2a654
}

.tooltip-warning + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f2a654
}

.tooltip-warning + .tooltip.left .tooltip-arrow {
    border-left-color: #f2a654
}

.tooltip-danger + .tooltip .tooltip-inner {
    color: #fff;
    background-color: #f96868
}

.tooltip-danger + .tooltip.top .tooltip-arrow {
    border-top-color: #f96868
}

.tooltip-danger + .tooltip.right .tooltip-arrow {
    border-right-color: #f96868
}

.tooltip-danger + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f96868
}

.tooltip-danger + .tooltip.left .tooltip-arrow {
    border-left-color: #f96868
}

.tooltip-rotate + .tooltip {
    -webkit-animation: tooltip-rotate3d 1s ease .1s forwards;
    -o-animation: tooltip-rotate3d 1s ease .1s forwards;
    animation: tooltip-rotate3d 1s ease .1s forwards;
    opacity: 0
}

@-webkit-keyframes tooltip-rotate3d {
    0% {
        opacity: 0;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@-o-keyframes tooltip-rotate3d {
    0% {
        opacity: 0;
        -o-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -o-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes tooltip-rotate3d {
    0% {
        opacity: 0;
        -webkit-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
}

.tooltip-scale + .tooltip {
    -webkit-animation: tooltip-scale3d 1s ease 0s forwards;
    -o-animation: tooltip-scale3d 1s ease 0s forwards;
    animation: tooltip-scale3d 1s ease 0s forwards
}

@-webkit-keyframes tooltip-scale3d {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.7, .3, 1) translate(50%, 50%);
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

@-o-keyframes tooltip-scale3d {
    0% {
        opacity: 0;
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

@keyframes tooltip-scale3d {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.7, .3, 1) translate(50%, 50%);
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

.popover {
    padding: 0;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, .05)
}

.popover.bottom > .arrow:after {
    border-bottom-color: #f3f7f9
}

.popover-content {
    padding: 20px
}

.popover-primary + .popover .popover-title {
    color: #fff;
    background-color: #62a8ea;
    border-color: #62a8ea
}

.popover-primary + .popover.bottom .arrow {
    border-bottom-color: #62a8ea
}

.popover-primary + .popover.bottom .arrow:after {
    border-bottom-color: #62a8ea
}

.popover-success + .popover .popover-title {
    color: #fff;
    background-color: #46be8a;
    border-color: #46be8a
}

.popover-success + .popover.bottom .arrow {
    border-bottom-color: #46be8a
}

.popover-success + .popover.bottom .arrow:after {
    border-bottom-color: #46be8a
}

.popover-info + .popover .popover-title {
    color: #fff;
    background-color: #57c7d4;
    border-color: #57c7d4
}

.popover-info + .popover.bottom .arrow {
    border-bottom-color: #57c7d4
}

.popover-info + .popover.bottom .arrow:after {
    border-bottom-color: #57c7d4
}

.popover-warning + .popover .popover-title {
    color: #fff;
    background-color: #f2a654;
    border-color: #f2a654
}

.popover-warning + .popover.bottom .arrow {
    border-bottom-color: #f2a654
}

.popover-warning + .popover.bottom .arrow:after {
    border-bottom-color: #f2a654
}

.popover-danger + .popover .popover-title {
    color: #fff;
    background-color: #f96868;
    border-color: #f96868
}

.popover-danger + .popover.bottom .arrow {
    border-bottom-color: #f96868
}

.popover-danger + .popover.bottom .arrow:after {
    border-bottom-color: #f96868
}

.popover-rotate + .popover {
    -webkit-animation: popover-rotate3d 1s ease .1s forwards;
    -o-animation: popover-rotate3d 1s ease .1s forwards;
    animation: popover-rotate3d 1s ease .1s forwards;
    opacity: 0
}

@-webkit-keyframes popover-rotate3d {
    0% {
        opacity: 0;
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@-o-keyframes popover-rotate3d {
    0% {
        opacity: 0;
        -o-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -o-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes popover-rotate3d {
    0% {
        opacity: 0;
        -webkit-transform: rotate(15deg);
        -o-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    100% {
        opacity: 1;
        -webkit-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0)
    }
}

.popover-scale + .popover {
    -webkit-animation: popover-scale3d 1s ease 0s forwards;
    -o-animation: popover-scale3d 1s ease 0s forwards;
    animation: popover-scale3d 1s ease 0s forwards
}

@-webkit-keyframes popover-scale3d {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.7, .3, 1) translate(50%, 50%);
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

@-o-keyframes popover-scale3d {
    0% {
        opacity: 0;
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

@keyframes popover-scale3d {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.7, .3, 1) translate(50%, 50%);
        transform: scale3d(.7, .3, 1) translate(50%, 50%)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
        transform: scale3d(1, 1, 1) translate(50%, 50%)
    }
}

.carousel-control {
    min-width: 50px
}

.carousel-control:focus, .carousel-control:hover {
    opacity: .4;
    filter: alpha(opacity=40)
}

.carousel-control .icon {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-top: -8px
}

.carousel-control.left .icon {
    left: 50%;
    margin-left: -8px
}

.carousel-control.right .icon {
    right: 50%;
    margin-right: -8px
}

.carousel-caption h1, .carousel-caption h2, .carousel-caption h3, .carousel-caption h4, .carousel-caption h5, .carousel-caption h6 {
    color: inherit
}

.carousel-indicators {
    margin-bottom: 0
}

.carousel-indicators li {
    margin: 3px;
    background-color: rgba(255, 255, 255, .3);
    border: none
}

.carousel-indicators .active {
    width: 10px;
    height: 10px;
    margin: 3px
}

.carousel-indicators-scaleup li {
    border: none;
    -webkit-transition: -webkit-transform .3s ease 0s, background-color .3s ease 0s;
    -o-transition: -o-transform .3s ease 0s, background-color .3s ease 0s;
    transition: transform .3s ease 0s, background-color .3s ease 0s
}

.carousel-indicators-scaleup .active {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5)
}

.carousel-indicators-fillin li {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 2px #fff inset;
    box-shadow: 0 0 0 2px #fff inset;
    -webkit-transition: -webkit-box-shadow .3s ease 0s;
    -o-transition: box-shadow .3s ease 0s;
    transition: box-shadow .3s ease 0s
}

.carousel-indicators-fillin .active {
    -webkit-box-shadow: 0 0 0 8px #fff inset;
    box-shadow: 0 0 0 8px #fff inset
}

.carousel-indicators-fall li {
    position: relative;
    -webkit-transition: -webkit-transform .3s ease 0s, background-color .3s ease 0s;
    -o-transition: -o-transform .3s ease 0s, background-color .3s ease 0s;
    transition: transform .3s ease 0s, background-color .3s ease 0s
}

.carousel-indicators-fall li:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    content: '';
    background-color: rgba(0, 0, 0, .3);
    border-radius: 50%;
    opacity: 0;
    -webkit-transform: translate(0, -200%);
    -ms-transform: translate(0, -200%);
    -o-transform: translate(0, -200%);
    transform: translate(0, -200%);
    -webkit-transition: opacity .3s ease 0s, visibility 0s ease .3s;
    -o-transition: opacity .3s ease 0s, visibility 0s ease .3s;
    transition: opacity .3s ease 0s, visibility 0s ease .3s
}

.carousel-indicators-fall .active {
    background-color: transparent;
    -webkit-transform: translate(0, 200%);
    -ms-transform: translate(0, 200%);
    -o-transform: translate(0, 200%);
    transform: translate(0, 200%)
}

.carousel-indicators-fall .active:after {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .3s ease 0s;
    -o-transition: opacity .3s ease 0s;
    transition: opacity .3s ease 0s
}

@media screen and (min-width: 768px) {
    .carousel-control .icon {
        width: 24px;
        height: 24px;
        margin-top: -12px;
        font-size: 24px
    }

    .carousel-control.left .icon {
        margin-left: -12px
    }

    .carousel-control.right .icon {
        margin-right: -12px
    }
}

.vertical-align {
    font-size: 0
}

.vertical-align:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: ""
}

.vertical-align-bottom, .vertical-align-middle {
    display: inline-block;
    max-width: 100%;
    font-size: 14px
}

.vertical-align-middle {
    vertical-align: middle
}

.vertical-align-bottom {
    vertical-align: bottom
}

.inline {
    display: inline !important
}

.inline-block {
    display: inline-block !important
}

.block {
    display: block !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    white-space: nowrap
}

.text-break {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    white-space: normal
}

.text-nowrap {
    white-space: nowrap
}

.text-top {
    vertical-align: top !important
}

.text-middle {
    vertical-align: middle !important
}

.text-bottom {
    vertical-align: bottom !important
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.text-justify {
    text-align: justify !important
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-unset {
    font-weight: unset !important
}

.font-weight-100 {
    font-weight: 100 !important
}

.font-weight-200 {
    font-weight: 200 !important
}

.font-weight-300 {
    font-weight: 300 !important
}

.font-weight-400 {
    font-weight: 400 !important
}

.font-weight-500 {
    font-weight: 500 !important
}

.font-weight-600 {
    font-weight: 600 !important
}

.font-weight-700 {
    font-weight: 700 !important
}

.font-weight-800 {
    font-weight: 800 !important
}

.font-weight-900 {
    font-weight: 900 !important
}

.font-weight-light {
    font-weight: 100 !important
}

.font-weight-normal {
    font-weight: 300 !important
}

.font-weight-medium {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 500 !important
}

.font-size-0 {
    font-size: 0 !important
}

.font-size-10 {
    font-size: 10px !important
}

.font-size-12 {
    font-size: 12px !important
}

.font-size-14 {
    font-size: 14px !important
}

.font-size-16 {
    font-size: 16px !important
}

.font-size-18 {
    font-size: 18px !important
}

.font-size-20 {
    font-size: 20px !important
}

.font-size-24 {
    font-size: 24px !important
}

.font-size-26 {
    font-size: 26px !important
}

.font-size-30 {
    font-size: 30px !important
}

.font-size-40 {
    font-size: 40px !important
}

.font-size-50 {
    font-size: 50px !important
}

.font-size-60 {
    font-size: 60px !important
}

.font-size-70 {
    font-size: 70px !important
}

.font-size-80 {
    font-size: 80px !important
}

.visible-xlg {
    display: none !important
}

.visible-xlg-block, .visible-xlg-inline, .visible-xlg-inline-block {
    display: none !important
}

@media (min-width: 1600px) {
    .visible-xlg {
        display: block !important
    }

    table.visible-xlg {
        display: table !important
    }

    tr.visible-xlg {
        display: table-row !important
    }

    td.visible-xlg, th.visible-xlg {
        display: table-cell !important
    }
}

@media (min-width: 1600px) {
    .visible-xlg-block {
        display: block !important
    }
}

@media (min-width: 1600px) {
    .visible-xlg-inline {
        display: inline !important
    }
}

@media (min-width: 1200px) {
    .visible-xlg-inline-block {
        display: inline-block !important
    }
}

@media (min-width: 1600px) {
    .hidden-xlg {
        display: none !important
    }
}