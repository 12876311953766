/*!
 * Remark (http://getbootstrapadmin.com/remark)
 * Copyright 2015 amazingsurge
 * Licensed under the Themeforest Standard Licenses
 */

body {
    color: #76838f;
    background-color: #ffffff;
}
a {
    color: #f96868;
}
a:hover,
a:focus {
    color: #fa7a7a;
}
img {
    vertical-align: middle;
}
.img-thumbnail {
    background-color: #ffffff;
    border: 1px solid #e4eaec;
}
hr {
    border-top: 1px solid #e4eaec;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #37474f;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    color: #a3afb7;
}
mark,
.mark {
    background-color: #f2a654;
}
.text-muted {
    color: #526069;
}
.text-primary {
    color: #f96868;
}
a.text-primary:hover,
a.text-primary:focus {
    color: #f73737;
}
.text-success {
    color: #ffffff;
}
a.text-success:hover,
a.text-success:focus {
    color: #e6e6e6;
}
.text-info {
    color: #ffffff;
}
a.text-info:hover,
a.text-info:focus {
    color: #e6e6e6;
}
.text-warning {
    color: #ffffff;
}
a.text-warning:hover,
a.text-warning:focus {
    color: #e6e6e6;
}
.text-danger {
    color: #ffffff;
}
a.text-danger:hover,
a.text-danger:focus {
    color: #e6e6e6;
}
.bg-primary {
    color: #fff;
    background-color: #f96868;
}
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #f73737;
}
.bg-success {
    background-color: #46be8a;
}
a.bg-success:hover,
a.bg-success:focus {
    background-color: #369b6f;
}
.bg-info {
    background-color: #57c7d4;
}
a.bg-info:hover,
a.bg-info:focus {
    background-color: #33b6c5;
}
.bg-warning {
    background-color: #f2a654;
}
a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #ee8d25;
}
.bg-danger {
    background-color: #f96868;
}
a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #f73737;
}
.page-header {
    border-bottom: 1px solid transparent;
}
abbr[title],
abbr[data-original-title] {
    border-bottom: 1px dotted #e4eaec;
}
blockquote {
    border-left: 5px solid #e4eaec;
}
blockquote footer,
blockquote small,
blockquote .small {
    color: #a3afb7;
}
.blockquote-reverse,
blockquote.pull-right {
    border-right: 5px solid #e4eaec;
}
code {
    color: #a6666d;
    background-color: rgba(255, 234, 234, 0.1);
}
kbd {
    color: #ffffff;
    background-color: #f96868;
}
pre {
    color: inherit;
    background-color: #ffffff;
    border: 1px solid #feeded;
}
table {
    background-color: transparent;
}
caption {
    color: #526069;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top: 1px solid #e4eaec;
}
.table > thead > tr > th {
    border-bottom: 2px solid #e4eaec;
}
.table > tbody + tbody {
    border-top: 2px solid #e4eaec;
}
.table .table {
    background-color: #ffffff;
}
.table-bordered {
    border: 1px solid #e4eaec;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border: 1px solid #e4eaec;
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(243, 247, 249, 0.3);
}
.table-hover > tbody > tr:hover {
    background-color: #f3f7f9;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
    background-color: #f3f7f9;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
    background-color: #e2ecf1;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
    background-color: #46be8a;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
    background-color: #3dae7d;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
    background-color: #57c7d4;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
    background-color: #43c0cf;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    background-color: #f2a654;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
    background-color: #f09a3c;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
    background-color: #f96868;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
    background-color: #f84f4f;
}
@media screen and (max-width: 767px) {
    .table-responsive {
        border: 1px solid #e4eaec;
    }
}
legend {
    color: inherit;
    border-bottom: 1px solid transparent;
}
output {
    color: #76838f;
}
.form-control {
    color: #76838f;
    background-color: #ffffff;
    border: 1px solid #e4eaec;
}
.form-control:focus {
    border-color: #f96868;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(249, 104, 104, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(249, 104, 104, 0.6);
}
.form-control.focus,
.form-control:focus {
    border-color: #f96868;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #f3f7f9;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
    color: #ffffff;
}
.has-success .form-control {
    border-color: #ffffff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
}
.has-success .input-group-addon {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #46be8a;
}
.has-success .form-control-feedback {
    color: #ffffff;
}
.has-success .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-success .form-control:focus {
    border-color: #ffffff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.has-success .form-control.focus,
.has-success .form-control:focus {
    border-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
    color: #ffffff;
}
.has-warning .form-control {
    border-color: #ffffff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
}
.has-warning .input-group-addon {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #f2a654;
}
.has-warning .form-control-feedback {
    color: #ffffff;
}
.has-warning .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-warning .form-control:focus {
    border-color: #ffffff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.has-warning .form-control.focus,
.has-warning .form-control:focus {
    border-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: #ffffff;
}
.has-error .form-control {
    border-color: #ffffff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
    border-color: #e6e6e6;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffffff;
}
.has-error .input-group-addon {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #f96868;
}
.has-error .form-control-feedback {
    color: #ffffff;
}
.has-error .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-error .form-control:focus {
    border-color: #ffffff;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
}
.has-error .form-control.focus,
.has-error .form-control:focus {
    border-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.help-block {
    color: #bcc2c8;
}
.btn:hover,
.btn:focus,
.btn.focus {
    color: #76838f;
}
.btn-default {
    color: #76838f;
    background-color: #e4eaec;
    border-color: #e4eaec;
}
.btn-default:focus,
.btn-default.focus {
    color: #76838f;
    background-color: #c6d3d7;
    border-color: #99b0b7;
}
.btn-default:hover {
    color: #76838f;
    background-color: #c6d3d7;
    border-color: #c0ced3;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #76838f;
    background-color: #c6d3d7;
    border-color: #c0ced3;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
    color: #76838f;
    background-color: #b1c2c8;
    border-color: #99b0b7;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
    background-color: #e4eaec;
    border-color: #e4eaec;
}
.btn-default .badge {
    color: #e4eaec;
    background-color: #76838f;
}
.btn-primary {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.btn-primary:focus,
.btn-primary.focus {
    color: #ffffff;
    background-color: #f73737;
    border-color: #d90909;
}
.btn-primary:hover {
    color: #ffffff;
    background-color: #f73737;
    border-color: #f72d2d;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: #ffffff;
    background-color: #f73737;
    border-color: #f72d2d;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    color: #ffffff;
    background-color: #f61515;
    border-color: #d90909;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
    background-color: #f96868;
    border-color: #f96868;
}
.btn-primary .badge {
    color: #f96868;
    background-color: #ffffff;
}
.btn-success {
    color: #ffffff;
    background-color: #46be8a;
    border-color: #46be8a;
}
.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: #369b6f;
    border-color: #226246;
}
.btn-success:hover {
    color: #ffffff;
    background-color: #369b6f;
    border-color: #34936a;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    color: #ffffff;
    background-color: #369b6f;
    border-color: #34936a;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
    color: #ffffff;
    background-color: #2d805c;
    border-color: #226246;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    background-image: none;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
    background-color: #46be8a;
    border-color: #46be8a;
}
.btn-success .badge {
    color: #46be8a;
    background-color: #ffffff;
}
.btn-info {
    color: #ffffff;
    background-color: #57c7d4;
    border-color: #57c7d4;
}
.btn-info:focus,
.btn-info.focus {
    color: #ffffff;
    background-color: #33b6c5;
    border-color: #237e89;
}
.btn-info:hover {
    color: #ffffff;
    background-color: #33b6c5;
    border-color: #30afbd;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
    color: #ffffff;
    background-color: #33b6c5;
    border-color: #30afbd;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
    color: #ffffff;
    background-color: #2b9ca9;
    border-color: #237e89;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
    background-image: none;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
    background-color: #57c7d4;
    border-color: #57c7d4;
}
.btn-info .badge {
    color: #57c7d4;
    background-color: #ffffff;
}
.btn-warning {
    color: #ffffff;
    background-color: #f2a654;
    border-color: #f2a654;
}
.btn-warning:focus,
.btn-warning.focus {
    color: #ffffff;
    background-color: #ee8d25;
    border-color: #b8660e;
}
.btn-warning:hover {
    color: #ffffff;
    background-color: #ee8d25;
    border-color: #ee881b;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    color: #ffffff;
    background-color: #ee8d25;
    border-color: #ee881b;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
    color: #ffffff;
    background-color: #de7c11;
    border-color: #b8660e;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    background-image: none;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
    background-color: #f2a654;
    border-color: #f2a654;
}
.btn-warning .badge {
    color: #f2a654;
    background-color: #ffffff;
}
.btn-danger {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.btn-danger:focus,
.btn-danger.focus {
    color: #ffffff;
    background-color: #f73737;
    border-color: #d90909;
}
.btn-danger:hover {
    color: #ffffff;
    background-color: #f73737;
    border-color: #f72d2d;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
    color: #ffffff;
    background-color: #f73737;
    border-color: #f72d2d;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
    color: #ffffff;
    background-color: #f61515;
    border-color: #d90909;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
    background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
    background-color: #f96868;
    border-color: #f96868;
}
.btn-danger .badge {
    color: #f96868;
    background-color: #ffffff;
}
.btn-link {
    color: #f96868;
}
.btn-link:hover,
.btn-link:focus {
    color: #fa7a7a;
    text-decoration: underline;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
    color: #a3afb7;
}
.dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid #e4eaec;
}
.dropdown-menu .divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e4eaec;
}
.dropdown-menu > li > a {
    color: #76838f;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    color: #76838f;
    background-color: #f3f7f9;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #76838f;
    background-color: #f3f7f9;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #ccd5db;
}
.dropdown-header {
    color: #37474f;
}
.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
    clip: rect(0, 0, 0, 0);
}
.input-group-addon {
    color: #76838f;
    background-color: #f3f7f9;
    border: 1px solid #e4eaec;
}
.nav > li > a:hover,
.nav > li > a:focus {
    background-color: #f3f7f9;
}
.nav > li.disabled > a {
    color: #a3afb7;
}
.nav > li.disabled > a:hover,
.nav > li.disabled > a:focus {
    color: #a3afb7;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: #f3f7f9;
    border-color: #f96868;
}
.nav-tabs {
    border-bottom: 1px solid #e4eaec;
}
.nav-tabs > li > a:hover {
    border-color: transparent transparent #e4eaec;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #e9595b;
    background-color: #ffffff;
    border: 1px solid #e4eaec;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #ffffff;
    background-color: #f96868;
}
.nav-tabs-justified > .active > a,
.nav-tabs-justified > .active > a:hover,
.nav-tabs-justified > .active > a:focus {
    border: 1px solid #e4eaec;
}
@media (min-width: 768px) {
    .nav-tabs-justified > li > a {
        border-bottom: 1px solid #e4eaec;
    }
    .nav-tabs-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus {
        border-bottom-color: #ffffff;
    }
}
.navbar-default {
    background-color: #ffffff;
    border-color: #e4eaec;
}
.navbar-default .navbar-brand {
    color: #37474f;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #37474f;
    background-color: none;
}
.navbar-default .navbar-text {
    color: #76838f;
}
.navbar-default .navbar-nav > li > a {
    color: #76838f;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.6);
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
    color: #ccd5db;
    background-color: transparent;
}
.navbar-default .navbar-toggle {
    border-color: transparent;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #76838f;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e4eaec;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: rgba(243, 247, 249, 0.6);
    color: #526069;
}
@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #76838f;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #526069;
        background-color: rgba(243, 247, 249, 0.3);
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #526069;
        background-color: rgba(243, 247, 249, 0.6);
    }
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #ccd5db;
        background-color: transparent;
    }
}
.navbar-default .navbar-link {
    color: #76838f;
}
.navbar-default .navbar-link:hover {
    color: #526069;
}
.navbar-default .btn-link {
    color: #76838f;
}
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
    color: #526069;
}
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:hover,
.navbar-default .btn-link[disabled]:focus,
fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccd5db;
}
.navbar-inverse {
    background-color: #f96868;
    border-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-brand {
    color: #ffffff;
}
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
    color: #ffffff;
    background-color: none;
}
.navbar-inverse .navbar-text {
    color: #ffffff;
}
.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
    color: #ffffff;
    background-color: transparent;
}
.navbar-inverse .navbar-toggle {
    border-color: transparent;
}
.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
    background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #f84646;
}
.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    color: #ffffff;
}
@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
        border-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #ffffff;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.1);
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
        color: #ffffff;
        background-color: transparent;
    }
}
.navbar-inverse .navbar-link {
    color: #ffffff;
}
.navbar-inverse .navbar-link:hover {
    color: #ffffff;
}
.navbar-inverse .btn-link {
    color: #ffffff;
}
.navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link:focus {
    color: #ffffff;
}
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:hover,
.navbar-inverse .btn-link[disabled]:focus,
fieldset[disabled] .navbar-inverse .btn-link:focus {
    color: #ffffff;
}
.breadcrumb {
    background-color: transparent;
}
.breadcrumb > li + li:before {
    color: #f96868;
}
.breadcrumb > .active {
    color: #76838f;
}
.pagination > li > a,
.pagination > li > span {
    color: #76838f;
    background-color: transparent;
    border: 1px solid #e4eaec;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #fa7a7a;
    background-color: #f3f7f9;
    border-color: #e4eaec;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #ccd5db;
    background-color: transparent;
    border-color: #e4eaec;
}
.pager li > a,
.pager li > span {
    background-color: transparent;
    border: 1px solid #e4eaec;
}
.pager li > a:hover,
.pager li > a:focus {
    background-color: #ffffff;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
    color: #ccd5db;
    background-color: transparent;
}
.label {
    color: #ffffff;
}
a.label:hover,
a.label:focus {
    color: #ffffff;
}
.label-default {
    background-color: #e4eaec;
}
.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #c6d3d7;
}
.label-primary {
    background-color: #f96868;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #f73737;
}
.label-success {
    background-color: #46be8a;
}
.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #369b6f;
}
.label-info {
    background-color: #57c7d4;
}
.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #33b6c5;
}
.label-warning {
    background-color: #f2a654;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #ee8d25;
}
.label-danger {
    background-color: #f96868;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #f73737;
}
.badge {
    color: #76838f;
    background-color: #e4eaec;
}
a.badge:hover,
a.badge:focus {
    color: #a3afb7;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
    color: #526069;
    background-color: #e4eaec;
}
.jumbotron {
    color: inherit;
    background-color: #e4eaec;
}
.jumbotron h1,
.jumbotron .h1 {
    color: inherit;
}
.jumbotron > hr {
    border-top-color: #c6d3d7;
}
.thumbnail {
    background-color: #ffffff;
    border: 1px solid #e4eaec;
}
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
    border-color: #f96868;
}
.thumbnail .caption {
    color: #76838f;
}
.alert-success {
    background-color: rgba(231, 250, 242, 0.8);
    border-color: #e7faf2;
    color: #46be8a;
}
.alert-success hr {
    border-top-color: #d2f6e7;
}
.alert-success .alert-link {
    color: #369b6f;
}
.alert-success .close {
    color: #46be8a;
}
.alert-success .close:hover,
.alert-success .close:focus {
    color: #46be8a;
}
.alert-info {
    background-color: rgba(236, 249, 250, 0.8);
    border-color: #ecf9fa;
    color: #57c7d4;
}
.alert-info hr {
    border-top-color: #d8f3f5;
}
.alert-info .alert-link {
    color: #33b6c5;
}
.alert-info .close {
    color: #57c7d4;
}
.alert-info .close:hover,
.alert-info .close:focus {
    color: #57c7d4;
}
.alert-warning {
    background-color: rgba(255, 243, 230, 0.8);
    border-color: #fff3e6;
    color: #f2a654;
}
.alert-warning hr {
    border-top-color: #ffe7cc;
}
.alert-warning .alert-link {
    color: #ee8d25;
}
.alert-warning .close {
    color: #f2a654;
}
.alert-warning .close:hover,
.alert-warning .close:focus {
    color: #f2a654;
}
.alert-danger {
    background-color: rgba(255, 234, 234, 0.8);
    border-color: #ffeaea;
    color: #f96868;
}
.alert-danger hr {
    border-top-color: #ffd0d0;
}
.alert-danger .alert-link {
    color: #f73737;
}
.alert-danger .close {
    color: #f96868;
}
.alert-danger .close:hover,
.alert-danger .close:focus {
    color: #f96868;
}
.progress {
    background-color: #e4eaec;
}
.progress-bar {
    color: #ffffff;
    background-color: #f96868;
}
.progress-bar-success {
    background-color: #46be8a;
}
.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
    background-color: #57c7d4;
}
.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
    background-color: #f2a654;
}
.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
    background-color: #f96868;
}
.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.list-group-item {
    background-color: #ffffff;
    border: 1px solid transparent;
}
a.list-group-item,
button.list-group-item {
    color: #76838f;
}
a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
    color: #37474f;
}
a.list-group-item:hover,
button.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:focus {
    color: #76838f;
    background-color: #f3f7f9;
}
.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
    background-color: transparent;
    color: #ccd5db;
}
.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
    color: #ccd5db;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    color: #f96868;
    background-color: transparent;
    border-color: transparent;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
    color: #ffffff;
}
.list-group-item-success {
    color: #ffffff;
    background-color: #46be8a;
}
a.list-group-item-success,
button.list-group-item-success {
    color: #ffffff;
}
a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
    color: inherit;
}
a.list-group-item-success:hover,
button.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:focus {
    color: #ffffff;
    background-color: #3dae7d;
}
a.list-group-item-success.active,
button.list-group-item-success.active,
a.list-group-item-success.active:hover,
button.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active:focus {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.list-group-item-info {
    color: #ffffff;
    background-color: #57c7d4;
}
a.list-group-item-info,
button.list-group-item-info {
    color: #ffffff;
}
a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
    color: inherit;
}
a.list-group-item-info:hover,
button.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:focus {
    color: #ffffff;
    background-color: #43c0cf;
}
a.list-group-item-info.active,
button.list-group-item-info.active,
a.list-group-item-info.active:hover,
button.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active:focus {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.list-group-item-warning {
    color: #ffffff;
    background-color: #f2a654;
}
a.list-group-item-warning,
button.list-group-item-warning {
    color: #ffffff;
}
a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit;
}
a.list-group-item-warning:hover,
button.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:focus {
    color: #ffffff;
    background-color: #f09a3c;
}
a.list-group-item-warning.active,
button.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active:focus {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.list-group-item-danger {
    color: #ffffff;
    background-color: #f96868;
}
a.list-group-item-danger,
button.list-group-item-danger {
    color: #ffffff;
}
a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
    color: inherit;
}
a.list-group-item-danger:hover,
button.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:focus {
    color: #ffffff;
    background-color: #f84f4f;
}
a.list-group-item-danger.active,
button.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active:focus {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.panel {
    background-color: #ffffff;
}
.panel-footer {
    background-color: transparent;
    border-top: 1px solid #e4eaec;
}
.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
    border-top: 1px solid #e4eaec;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #e4eaec;
}
.panel-default {
    border-color: #e4eaec;
}
.panel-default > .panel-heading {
    color: #76838f;
    background-color: #e4eaec;
    border-color: #e4eaec;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #e4eaec;
}
.panel-default > .panel-heading .badge {
    color: #e4eaec;
    background-color: #76838f;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #e4eaec;
}
.panel-primary {
    border-color: #f96868;
}
.panel-primary > .panel-heading {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #f96868;
}
.panel-primary > .panel-heading .badge {
    color: #f96868;
    background-color: #ffffff;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f96868;
}
.panel-success {
    border-color: #3dae6a;
}
.panel-success > .panel-heading {
    color: #ffffff;
    background-color: #46be8a;
    border-color: #3dae6a;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #3dae6a;
}
.panel-success > .panel-heading .badge {
    color: #46be8a;
    background-color: #ffffff;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #3dae6a;
}
.panel-info {
    border-color: #3bcdc4;
}
.panel-info > .panel-heading {
    color: #ffffff;
    background-color: #57c7d4;
    border-color: #3bcdc4;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #3bcdc4;
}
.panel-info > .panel-heading .badge {
    color: #57c7d4;
    background-color: #ffffff;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #3bcdc4;
}
.panel-warning {
    border-color: #f18246;
}
.panel-warning > .panel-heading {
    color: #ffffff;
    background-color: #f2a654;
    border-color: #f18246;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #f18246;
}
.panel-warning > .panel-heading .badge {
    color: #f2a654;
    background-color: #ffffff;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f18246;
}
.panel-danger {
    border-color: #f85974;
}
.panel-danger > .panel-heading {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f85974;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #f85974;
}
.panel-danger > .panel-heading .badge {
    color: #f96868;
    background-color: #ffffff;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #f85974;
}
.well {
    background-color: #f3f7f9;
    border: 1px solid #e4eaec;
}
.close {
    color: #000000;
    text-shadow: none;
}
.close:hover,
.close:focus {
    color: #000000;
}
.modal-content {
    background-color: #ffffff;
    border: 1px solid #999999;
    border: 1px solid transparent;
}
.modal-backdrop {
    background-color: #000000;
}
.modal-header {
    border-bottom: 1px solid #e4eaec;
}
.modal-footer {
    border-top: 1px solid #e4eaec;
}
.tooltip-inner {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
}
.tooltip.top .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, 0.8);
}
.tooltip.top-left .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, 0.8);
}
.tooltip.top-right .tooltip-arrow {
    border-top-color: rgba(0, 0, 0, 0.8);
}
.tooltip.right .tooltip-arrow {
    border-right-color: rgba(0, 0, 0, 0.8);
}
.tooltip.left .tooltip-arrow {
    border-left-color: rgba(0, 0, 0, 0.8);
}
.tooltip.bottom .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, 0.8);
}
.tooltip.bottom-left .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, 0.8);
}
.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: rgba(0, 0, 0, 0.8);
}
.popover {
    background-color: #ffffff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #e4eaec;
    border: 1px solid rgba(204, 213, 219, 0.8);
}
.popover-title {
    background-color: #f3f7f9;
    border-bottom: 1px solid #e2ecf1;
}
.popover.top > .arrow {
    border-top-color: #a8bbc2;
    border-top-color: rgba(204, 213, 219, 0.85);
}
.popover.top > .arrow:after {
    border-top-color: #ffffff;
}
.popover.right > .arrow {
    border-right-color: #a8bbc2;
    border-right-color: rgba(204, 213, 219, 0.85);
}
.popover.right > .arrow:after {
    border-right-color: #ffffff;
}
.popover.bottom > .arrow {
    border-bottom-color: #a8bbc2;
    border-bottom-color: rgba(204, 213, 219, 0.85);
}
.popover.bottom > .arrow:after {
    border-bottom-color: #ffffff;
}
.popover.left > .arrow {
    border-left-color: #a8bbc2;
    border-left-color: rgba(204, 213, 219, 0.85);
}
.popover.left > .arrow:after {
    border-left-color: #ffffff;
}
.carousel-control {
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-control:hover,
.carousel-control:focus {
    color: #ffffff;
}
.carousel-indicators li {
    border: 1px solid #ffffff;
}
.carousel-indicators .active {
    background-color: #ffffff;
}
.carousel-caption {
    color: #ffffff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
a.text-action {
    color: #a3afb7;
}
a.text-action,
a.text-action:hover,
a.text-action:focus {
    text-decoration: none;
}
a.text-action:hover,
a.text-action:focus {
    color: #ccd5db;
}
a.text-like {
    color: #a3afb7 !important;
}
a.text-like,
a.text-like:hover,
a.text-like:focus {
    text-decoration: none;
}
a.text-like.active,
a.text-like:hover,
a.text-like:focus {
    color: #f96868 !important;
}
.img-bordered {
    border: 1px solid #e4eaec;
}
.img-bordered-primary {
    border-color: #f96868 !important;
}
.img-bordered-purple {
    border-color: #7c51d1 !important;
}
.img-bordered-red {
    border-color: #e9595b !important;
}
.img-bordered-green {
    border-color: #7dd3ae !important;
}
.img-bordered-orange {
    border-color: #ec9940 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    text-shadow: rgba(0, 0, 0, 0.15) 0 0 1px;
}
mark,
.mark {
    color: #ffffff;
}
.drop-cap {
    color: #263238;
}
.drop-cap-reversed {
    color: #ffffff;
    background-color: #263238;
}
.text-primary {
    color: #f96868;
}
a.text-primary:hover,
a.text-primary:focus {
    color: #f73737;
}
.text-success {
    color: #46be8a;
}
a.text-success:hover,
a.text-success:focus {
    color: #369b6f;
}
.text-info {
    color: #57c7d4;
}
a.text-info:hover,
a.text-info:focus {
    color: #33b6c5;
}
.text-warning {
    color: #f2a654;
}
a.text-warning:hover,
a.text-warning:focus {
    color: #ee8d25;
}
.text-danger {
    color: #f96868;
}
a.text-danger:hover,
a.text-danger:focus {
    color: #f73737;
}
blockquote {
    color: #526069;
    border-left-width: 2px;
}
.blockquote-reverse {
    border-right-width: 2px;
}
.blockquote {
    border-left-width: 4px;
}
.blockquote.blockquote-reverse {
    border-right-width: 4px;
}
.blockquote-success {
    background-color: rgba(70, 190, 138, 0.1);
    border-color: #46be8a;
}
.blockquote-info {
    background-color: rgba(87, 199, 212, 0.1);
    border-color: #57c7d4;
}
.blockquote-warning {
    background-color: rgba(242, 166, 84, 0.1);
    border-color: #f2a654;
}
.blockquote-danger {
    background-color: rgba(249, 104, 104, 0.1);
    border-color: #f96868;
}
code {
    border: 1px solid #fab4b4;
}
.table {
    color: #76838f;
}
.table > thead > tr > th,
.table > tfoot > tr > th {
    color: #526069;
}
.table > thead > tr > th {
    border-bottom: 1px solid #e4eaec;
}
.table > tbody + tbody {
    border-top: 1px solid #e4eaec;
}
.table .success,
.table .warning,
.table .danger,
.table .info {
    color: #ffffff;
}
.table .success a,
.table .warning a,
.table .danger a,
.table .info a {
    color: #ffffff;
}
.table-primary thead tr,
.table-success thead tr,
.table-info thead tr,
.table-warning thead tr,
.table-danger thead tr,
.table-dark thead tr {
    color: #ffffff;
}
.table-default thead tr {
    background: #f3f7f9;
}
.table-primary thead tr {
    background: #f96868;
}
.table-success thead tr {
    background: #46be8a;
}
.table-info thead tr {
    background: #57c7d4;
}
.table-warning thead tr {
    background: #f2a654;
}
.table-danger thead tr {
    background: #f96868;
}
.table-dark thead tr {
    background: #526069;
}
.table-gray thead tr {
    color: #526069;
    background: #ccd5db;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 1px;
}
.table-bordered > thead:first-child > tr:first-child > th {
    border: 1px solid #e4eaec;
}
.table-section.active tr {
    background-color: #f3f7f9;
}
.form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-control:not(select) {
    -webkit-appearance: none;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
    color: #46be8a;
}
.has-success .form-control {
    border-color: #46be8a;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
    border-color: #369b6f;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #91d9ba;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #91d9ba;
}
.has-success .input-group-addon {
    color: #46be8a;
    border-color: #46be8a;
    background-color: #ffffff;
}
.has-success .form-control-feedback {
    color: #46be8a;
}
.has-success .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-success .form-control:focus {
    border-color: #46be8a;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(70, 190, 138, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(70, 190, 138, 0.6);
}
.has-success .form-control.focus,
.has-success .form-control:focus {
    border-color: #46be8a;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
    color: #f2a654;
}
.has-warning .form-control {
    border-color: #f2a654;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
    border-color: #ee8d25;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f9d7b3;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f9d7b3;
}
.has-warning .input-group-addon {
    color: #f2a654;
    border-color: #f2a654;
    background-color: #ffffff;
}
.has-warning .form-control-feedback {
    color: #f2a654;
}
.has-warning .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-warning .form-control:focus {
    border-color: #f2a654;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(242, 166, 84, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(242, 166, 84, 0.6);
}
.has-warning .form-control.focus,
.has-warning .form-control:focus {
    border-color: #f2a654;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
    color: #f96868;
}
.has-error .form-control {
    border-color: #f96868;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
    border-color: #f73737;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #fdcaca;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #fdcaca;
}
.has-error .input-group-addon {
    color: #f96868;
    border-color: #f96868;
    background-color: #ffffff;
}
.has-error .form-control-feedback {
    color: #f96868;
}
.has-error .form-control {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-error .form-control:focus {
    border-color: #f96868;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(249, 104, 104, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(249, 104, 104, 0.6);
}
.has-error .form-control.focus,
.has-error .form-control:focus {
    border-color: #f96868;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.input-group-file input[type="text"] {
    background-color: #ffffff;
}
.input-group-file .btn-file.btn-outline {
    border: 1px solid #e4eaec;
    border-left: none;
}
.input-group-file .btn-file.btn-outline:hover {
    border-left: none;
}
.input-search-close {
    color: #000000;
    text-shadow: none;
}
.input-search-close:hover,
.input-search-close:focus {
    color: #000000;
    text-decoration: none;
}
button.input-search-close {
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}
.input-search .input-search-icon {
    color: #a3afb7;
}
.input-search-btn {
    background: transparent;
    border: none;
}
.input-search-dark .input-search-icon {
    color: #76838f;
}
.input-search-dark .form-control {
    background: #f3f7f9;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.input-search-dark .form-control:focus {
    background-color: transparent;
}
/*@btn-floating-xs-padding:                10px;*/
/*@btn-floating-sm-padding:                13px;*/
/*@btn-floating-lg-padding:                15px;*/
.btn:focus,
.btn:active:focus,
.btn.active:focus {
    outline: 0;
}
.btn:active,
.btn.active {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-outline.btn-default {
    color: #76838f;
    background-color: transparent;
}
.btn-outline.btn-default:hover,
.btn-outline.btn-default:focus,
.btn-outline.btn-default:active,
.btn-outline.btn-default.active,
.open > .dropdown-toggle.btn-outline.btn-default {
    border-color: #e4eaec;
    background-color: rgba(118, 131, 143, 0.1);
    color: #76838f;
}
.btn-outline.btn-default:hover .badge,
.btn-outline.btn-default:focus .badge,
.btn-outline.btn-default:active .badge,
.btn-outline.btn-default.active .badge,
.open > .dropdown-toggle.btn-outline.btn-default .badge {
    color: #76838f;
    background-color: #76838f;
}
.btn-outline.btn-primary {
    color: #f96868;
    background-color: transparent;
}
.btn-outline.btn-primary:hover,
.btn-outline.btn-primary:focus,
.btn-outline.btn-primary:active,
.btn-outline.btn-primary.active,
.open > .dropdown-toggle.btn-outline.btn-primary {
    border-color: #f96868;
    background-color: #f96868;
    color: #ffffff;
}
.btn-outline.btn-primary:hover .badge,
.btn-outline.btn-primary:focus .badge,
.btn-outline.btn-primary:active .badge,
.btn-outline.btn-primary.active .badge,
.open > .dropdown-toggle.btn-outline.btn-primary .badge {
    color: #f96868;
    background-color: #ffffff;
}
.btn-outline.btn-success {
    color: #46be8a;
    background-color: transparent;
}
.btn-outline.btn-success:hover,
.btn-outline.btn-success:focus,
.btn-outline.btn-success:active,
.btn-outline.btn-success.active,
.open > .dropdown-toggle.btn-outline.btn-success {
    border-color: #46be8a;
    background-color: #46be8a;
    color: #ffffff;
}
.btn-outline.btn-success:hover .badge,
.btn-outline.btn-success:focus .badge,
.btn-outline.btn-success:active .badge,
.btn-outline.btn-success.active .badge,
.open > .dropdown-toggle.btn-outline.btn-success .badge {
    color: #46be8a;
    background-color: #ffffff;
}
.btn-outline.btn-info {
    color: #57c7d4;
    background-color: transparent;
}
.btn-outline.btn-info:hover,
.btn-outline.btn-info:focus,
.btn-outline.btn-info:active,
.btn-outline.btn-info.active,
.open > .dropdown-toggle.btn-outline.btn-info {
    border-color: #57c7d4;
    background-color: #57c7d4;
    color: #ffffff;
}
.btn-outline.btn-info:hover .badge,
.btn-outline.btn-info:focus .badge,
.btn-outline.btn-info:active .badge,
.btn-outline.btn-info.active .badge,
.open > .dropdown-toggle.btn-outline.btn-info .badge {
    color: #57c7d4;
    background-color: #ffffff;
}
.btn-outline.btn-warning {
    color: #f2a654;
    background-color: transparent;
}
.btn-outline.btn-warning:hover,
.btn-outline.btn-warning:focus,
.btn-outline.btn-warning:active,
.btn-outline.btn-warning.active,
.open > .dropdown-toggle.btn-outline.btn-warning {
    border-color: #f2a654;
    background-color: #f2a654;
    color: #ffffff;
}
.btn-outline.btn-warning:hover .badge,
.btn-outline.btn-warning:focus .badge,
.btn-outline.btn-warning:active .badge,
.btn-outline.btn-warning.active .badge,
.open > .dropdown-toggle.btn-outline.btn-warning .badge {
    color: #f2a654;
    background-color: #ffffff;
}
.btn-outline.btn-danger {
    color: #f96868;
    background-color: transparent;
}
.btn-outline.btn-danger:hover,
.btn-outline.btn-danger:focus,
.btn-outline.btn-danger:active,
.btn-outline.btn-danger.active,
.open > .dropdown-toggle.btn-outline.btn-danger {
    border-color: #f96868;
    background-color: #f96868;
    color: #ffffff;
}
.btn-outline.btn-danger:hover .badge,
.btn-outline.btn-danger:focus .badge,
.btn-outline.btn-danger:active .badge,
.btn-outline.btn-danger.active .badge,
.open > .dropdown-toggle.btn-outline.btn-danger .badge {
    color: #f96868;
    background-color: #ffffff;
}
.btn-outline.btn-dark {
    color: #526069;
    background-color: transparent;
}
.btn-outline.btn-dark:hover,
.btn-outline.btn-dark:focus,
.btn-outline.btn-dark:active,
.btn-outline.btn-dark.active,
.open > .dropdown-toggle.btn-outline.btn-dark {
    border-color: #526069;
    background-color: #526069;
    color: #ffffff;
}
.btn-outline.btn-dark:hover .badge,
.btn-outline.btn-dark:focus .badge,
.btn-outline.btn-dark:active .badge,
.btn-outline.btn-dark.active .badge,
.open > .dropdown-toggle.btn-outline.btn-dark .badge {
    color: #526069;
    background-color: #ffffff;
}
.btn-outline.btn-inverse {
    color: #ffffff;
    background-color: transparent;
}
.btn-outline.btn-inverse:hover,
.btn-outline.btn-inverse:focus,
.btn-outline.btn-inverse:active,
.btn-outline.btn-inverse.active,
.open > .dropdown-toggle.btn-outline.btn-inverse {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #76838f;
}
.btn-outline.btn-inverse:hover .badge,
.btn-outline.btn-inverse:focus .badge,
.btn-outline.btn-inverse:active .badge,
.btn-outline.btn-inverse.active .badge,
.open > .dropdown-toggle.btn-outline.btn-inverse .badge {
    color: #ffffff;
    background-color: #76838f;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
    background-color: #f3f7f9;
    border-color: #f3f7f9;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-color: #ccd5db;
    border-color: #ccd5db;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
    background-color: #ccd5db;
    border-color: #ccd5db;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    color: #76838f;
    background-color: #f3f7f9;
    border-color: #f3f7f9;
}
.btn-default.btn-up:before {
    border-bottom-color: #e4eaec;
}
.btn-default.btn-up:hover:before,
.btn-default.btn-up:focus:before {
    border-bottom-color: #f3f7f9;
}
.btn-default.btn-up:active:before,
.btn-default.btn-up.active:before,
.open > .dropdown-toggle.btn-default.btn-up:before {
    border-bottom-color: #ccd5db;
}
.btn-default.btn-right:before {
    border-left-color: #e4eaec;
}
.btn-default.btn-right:hover:before,
.btn-default.btn-right:focus:before {
    border-left-color: #f3f7f9;
}
.btn-default.btn-right:active:before,
.btn-default.btn-right.active:before,
.open > .dropdown-toggle.btn-default.btn-right:before {
    border-left-color: #ccd5db;
}
.btn-default.btn-bottom:before {
    border-top-color: #e4eaec;
}
.btn-default.btn-bottom:hover:before,
.btn-default.btn-bottom:focus:before {
    border-top-color: #f3f7f9;
}
.btn-default.btn-bottom:active:before,
.btn-default.btn-bottom.active:before,
.open > .dropdown-toggle.btn-default.btn-bottom:before {
    border-top-color: #ccd5db;
}
.btn-default.btn-left:before {
    border-right-color: #e4eaec;
}
.btn-default.btn-left:hover:before,
.btn-default.btn-left:focus:before {
    border-right-color: #f3f7f9;
}
.btn-default.btn-left:active:before,
.btn-default.btn-left.active:before,
.open > .dropdown-toggle.btn-default.btn-left:before {
    border-right-color: #ccd5db;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
    background-color: #fa7a7a;
    border-color: #fa7a7a;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-color: #e9595b;
    border-color: #e9595b;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    background-color: #e9595b;
    border-color: #e9595b;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    color: #ffffff;
    background-color: #fa9898;
    border-color: #fa9898;
}
.btn-primary.btn-up:before {
    border-bottom-color: #f96868;
}
.btn-primary.btn-up:hover:before,
.btn-primary.btn-up:focus:before {
    border-bottom-color: #fa7a7a;
}
.btn-primary.btn-up:active:before,
.btn-primary.btn-up.active:before,
.open > .dropdown-toggle.btn-primary.btn-up:before {
    border-bottom-color: #e9595b;
}
.btn-primary.btn-right:before {
    border-left-color: #f96868;
}
.btn-primary.btn-right:hover:before,
.btn-primary.btn-right:focus:before {
    border-left-color: #fa7a7a;
}
.btn-primary.btn-right:active:before,
.btn-primary.btn-right.active:before,
.open > .dropdown-toggle.btn-primary.btn-right:before {
    border-left-color: #e9595b;
}
.btn-primary.btn-bottom:before {
    border-top-color: #f96868;
}
.btn-primary.btn-bottom:hover:before,
.btn-primary.btn-bottom:focus:before {
    border-top-color: #fa7a7a;
}
.btn-primary.btn-bottom:active:before,
.btn-primary.btn-bottom.active:before,
.open > .dropdown-toggle.btn-primary.btn-bottom:before {
    border-top-color: #e9595b;
}
.btn-primary.btn-left:before {
    border-right-color: #f96868;
}
.btn-primary.btn-left:hover:before,
.btn-primary.btn-left:focus:before {
    border-right-color: #fa7a7a;
}
.btn-primary.btn-left:active:before,
.btn-primary.btn-left.active:before,
.open > .dropdown-toggle.btn-primary.btn-left:before {
    border-right-color: #e9595b;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
    background-color: #5cd29d;
    border-color: #5cd29d;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
    background-color: #36ab7a;
    border-color: #36ab7a;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
    background-color: #36ab7a;
    border-color: #36ab7a;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
    color: #ffffff;
    background-color: #7dd3ae;
    border-color: #7dd3ae;
}
.btn-success.btn-up:before {
    border-bottom-color: #46be8a;
}
.btn-success.btn-up:hover:before,
.btn-success.btn-up:focus:before {
    border-bottom-color: #5cd29d;
}
.btn-success.btn-up:active:before,
.btn-success.btn-up.active:before,
.open > .dropdown-toggle.btn-success.btn-up:before {
    border-bottom-color: #36ab7a;
}
.btn-success.btn-right:before {
    border-left-color: #46be8a;
}
.btn-success.btn-right:hover:before,
.btn-success.btn-right:focus:before {
    border-left-color: #5cd29d;
}
.btn-success.btn-right:active:before,
.btn-success.btn-right.active:before,
.open > .dropdown-toggle.btn-success.btn-right:before {
    border-left-color: #36ab7a;
}
.btn-success.btn-bottom:before {
    border-top-color: #46be8a;
}
.btn-success.btn-bottom:hover:before,
.btn-success.btn-bottom:focus:before {
    border-top-color: #5cd29d;
}
.btn-success.btn-bottom:active:before,
.btn-success.btn-bottom.active:before,
.open > .dropdown-toggle.btn-success.btn-bottom:before {
    border-top-color: #36ab7a;
}
.btn-success.btn-left:before {
    border-right-color: #46be8a;
}
.btn-success.btn-left:hover:before,
.btn-success.btn-left:focus:before {
    border-right-color: #5cd29d;
}
.btn-success.btn-left:active:before,
.btn-success.btn-left.active:before,
.open > .dropdown-toggle.btn-success.btn-left:before {
    border-right-color: #36ab7a;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
    background-color: #77d6e1;
    border-color: #77d6e1;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
    background-color: #47b8c6;
    border-color: #47b8c6;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
    background-color: #47b8c6;
    border-color: #47b8c6;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
    color: #ffffff;
    background-color: #9ae1e9;
    border-color: #9ae1e9;
}
.btn-info.btn-up:before {
    border-bottom-color: #57c7d4;
}
.btn-info.btn-up:hover:before,
.btn-info.btn-up:focus:before {
    border-bottom-color: #77d6e1;
}
.btn-info.btn-up:active:before,
.btn-info.btn-up.active:before,
.open > .dropdown-toggle.btn-info.btn-up:before {
    border-bottom-color: #47b8c6;
}
.btn-info.btn-right:before {
    border-left-color: #57c7d4;
}
.btn-info.btn-right:hover:before,
.btn-info.btn-right:focus:before {
    border-left-color: #77d6e1;
}
.btn-info.btn-right:active:before,
.btn-info.btn-right.active:before,
.open > .dropdown-toggle.btn-info.btn-right:before {
    border-left-color: #47b8c6;
}
.btn-info.btn-bottom:before {
    border-top-color: #57c7d4;
}
.btn-info.btn-bottom:hover:before,
.btn-info.btn-bottom:focus:before {
    border-top-color: #77d6e1;
}
.btn-info.btn-bottom:active:before,
.btn-info.btn-bottom.active:before,
.open > .dropdown-toggle.btn-info.btn-bottom:before {
    border-top-color: #47b8c6;
}
.btn-info.btn-left:before {
    border-right-color: #57c7d4;
}
.btn-info.btn-left:hover:before,
.btn-info.btn-left:focus:before {
    border-right-color: #77d6e1;
}
.btn-info.btn-left:active:before,
.btn-info.btn-left.active:before,
.open > .dropdown-toggle.btn-info.btn-left:before {
    border-right-color: #47b8c6;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
    background-color: #f4b066;
    border-color: #f4b066;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
    background-color: #ec9940;
    border-color: #ec9940;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
    background-color: #ec9940;
    border-color: #ec9940;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
    color: #ffffff;
    background-color: #f6be80;
    border-color: #f6be80;
}
.btn-warning.btn-up:before {
    border-bottom-color: #f2a654;
}
.btn-warning.btn-up:hover:before,
.btn-warning.btn-up:focus:before {
    border-bottom-color: #f4b066;
}
.btn-warning.btn-up:active:before,
.btn-warning.btn-up.active:before,
.open > .dropdown-toggle.btn-warning.btn-up:before {
    border-bottom-color: #ec9940;
}
.btn-warning.btn-right:before {
    border-left-color: #f2a654;
}
.btn-warning.btn-right:hover:before,
.btn-warning.btn-right:focus:before {
    border-left-color: #f4b066;
}
.btn-warning.btn-right:active:before,
.btn-warning.btn-right.active:before,
.open > .dropdown-toggle.btn-warning.btn-right:before {
    border-left-color: #ec9940;
}
.btn-warning.btn-bottom:before {
    border-top-color: #f2a654;
}
.btn-warning.btn-bottom:hover:before,
.btn-warning.btn-bottom:focus:before {
    border-top-color: #f4b066;
}
.btn-warning.btn-bottom:active:before,
.btn-warning.btn-bottom.active:before,
.open > .dropdown-toggle.btn-warning.btn-bottom:before {
    border-top-color: #ec9940;
}
.btn-warning.btn-left:before {
    border-right-color: #f2a654;
}
.btn-warning.btn-left:hover:before,
.btn-warning.btn-left:focus:before {
    border-right-color: #f4b066;
}
.btn-warning.btn-left:active:before,
.btn-warning.btn-left.active:before,
.open > .dropdown-toggle.btn-warning.btn-left:before {
    border-right-color: #ec9940;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
    background-color: #fa7a7a;
    border-color: #fa7a7a;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
    background-color: #e9595b;
    border-color: #e9595b;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
    background-color: #e9595b;
    border-color: #e9595b;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
    color: #ffffff;
    background-color: #fa9898;
    border-color: #fa9898;
}
.btn-danger.btn-up:before {
    border-bottom-color: #f96868;
}
.btn-danger.btn-up:hover:before,
.btn-danger.btn-up:focus:before {
    border-bottom-color: #fa7a7a;
}
.btn-danger.btn-up:active:before,
.btn-danger.btn-up.active:before,
.open > .dropdown-toggle.btn-danger.btn-up:before {
    border-bottom-color: #e9595b;
}
.btn-danger.btn-right:before {
    border-left-color: #f96868;
}
.btn-danger.btn-right:hover:before,
.btn-danger.btn-right:focus:before {
    border-left-color: #fa7a7a;
}
.btn-danger.btn-right:active:before,
.btn-danger.btn-right.active:before,
.open > .dropdown-toggle.btn-danger.btn-right:before {
    border-left-color: #e9595b;
}
.btn-danger.btn-bottom:before {
    border-top-color: #f96868;
}
.btn-danger.btn-bottom:hover:before,
.btn-danger.btn-bottom:focus:before {
    border-top-color: #fa7a7a;
}
.btn-danger.btn-bottom:active:before,
.btn-danger.btn-bottom.active:before,
.open > .dropdown-toggle.btn-danger.btn-bottom:before {
    border-top-color: #e9595b;
}
.btn-danger.btn-left:before {
    border-right-color: #f96868;
}
.btn-danger.btn-left:hover:before,
.btn-danger.btn-left:focus:before {
    border-right-color: #fa7a7a;
}
.btn-danger.btn-left:active:before,
.btn-danger.btn-left.active:before,
.open > .dropdown-toggle.btn-danger.btn-left:before {
    border-right-color: #e9595b;
}
.btn-inverse {
    color: #76838f;
    background-color: #ffffff;
    border-color: #e4eaec;
}
.btn-inverse:focus,
.btn-inverse.focus {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #99b0b7;
}
.btn-inverse:hover {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #c0ced3;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
    color: #76838f;
    background-color: #e6e6e6;
    border-color: #c0ced3;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
    color: #76838f;
    background-color: #d4d4d4;
    border-color: #99b0b7;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
    background-image: none;
}
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus {
    background-color: #ffffff;
    border-color: #e4eaec;
}
.btn-inverse .badge {
    color: #ffffff;
    background-color: #76838f;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus {
    background-color: #ffffff;
    border-color: #f3f7f9;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
    background-color: #ffffff;
    border-color: #ccd5db;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
    background-color: #ffffff;
    border-color: #ccd5db;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
    color: #ccd5db;
    background-color: #ffffff;
    border-color: #a3afb7;
}
.btn-inverse.btn-up:before {
    border-bottom-color: #ffffff;
}
.btn-inverse.btn-up:hover:before,
.btn-inverse.btn-up:focus:before {
    border-bottom-color: #ffffff;
}
.btn-inverse.btn-up:active:before,
.btn-inverse.btn-up.active:before,
.open > .dropdown-toggle.btn-inverse.btn-up:before {
    border-bottom-color: #ffffff;
}
.btn-inverse.btn-right:before {
    border-left-color: #ffffff;
}
.btn-inverse.btn-right:hover:before,
.btn-inverse.btn-right:focus:before {
    border-left-color: #ffffff;
}
.btn-inverse.btn-right:active:before,
.btn-inverse.btn-right.active:before,
.open > .dropdown-toggle.btn-inverse.btn-right:before {
    border-left-color: #ffffff;
}
.btn-inverse.btn-bottom:before {
    border-top-color: #ffffff;
}
.btn-inverse.btn-bottom:hover:before,
.btn-inverse.btn-bottom:focus:before {
    border-top-color: #ffffff;
}
.btn-inverse.btn-bottom:active:before,
.btn-inverse.btn-bottom.active:before,
.open > .dropdown-toggle.btn-inverse.btn-bottom:before {
    border-top-color: #ffffff;
}
.btn-inverse.btn-left:before {
    border-right-color: #ffffff;
}
.btn-inverse.btn-left:hover:before,
.btn-inverse.btn-left:focus:before {
    border-right-color: #ffffff;
}
.btn-inverse.btn-left:active:before,
.btn-inverse.btn-left.active:before,
.open > .dropdown-toggle.btn-inverse.btn-left:before {
    border-right-color: #ffffff;
}
.btn-dark {
    color: #ffffff;
    background-color: #526069;
    border-color: #526069;
}
.btn-dark:focus,
.btn-dark.focus {
    color: #ffffff;
    background-color: #3c464c;
    border-color: #1a1f21;
}
.btn-dark:hover {
    color: #ffffff;
    background-color: #3c464c;
    border-color: #374147;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
    color: #ffffff;
    background-color: #3c464c;
    border-color: #374147;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
    color: #ffffff;
    background-color: #2c3338;
    border-color: #1a1f21;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
    background-image: none;
}
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
    background-color: #526069;
    border-color: #526069;
}
.btn-dark .badge {
    color: #526069;
    background-color: #ffffff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
    background-color: #76838f;
    border-color: #76838f;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
    background-color: #37474f;
    border-color: #37474f;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
    background-color: #37474f;
    border-color: #37474f;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
    color: #ffffff;
    background-color: #a3afb7;
    border-color: #a3afb7;
}
.btn-dark.btn-up:before {
    border-bottom-color: #526069;
}
.btn-dark.btn-up:hover:before,
.btn-dark.btn-up:focus:before {
    border-bottom-color: #76838f;
}
.btn-dark.btn-up:active:before,
.btn-dark.btn-up.active:before,
.open > .dropdown-toggle.btn-dark.btn-up:before {
    border-bottom-color: #37474f;
}
.btn-dark.btn-right:before {
    border-left-color: #526069;
}
.btn-dark.btn-right:hover:before,
.btn-dark.btn-right:focus:before {
    border-left-color: #76838f;
}
.btn-dark.btn-right:active:before,
.btn-dark.btn-right.active:before,
.open > .dropdown-toggle.btn-dark.btn-right:before {
    border-left-color: #37474f;
}
.btn-dark.btn-bottom:before {
    border-top-color: #526069;
}
.btn-dark.btn-bottom:hover:before,
.btn-dark.btn-bottom:focus:before {
    border-top-color: #76838f;
}
.btn-dark.btn-bottom:active:before,
.btn-dark.btn-bottom.active:before,
.open > .dropdown-toggle.btn-dark.btn-bottom:before {
    border-top-color: #37474f;
}
.btn-dark.btn-left:before {
    border-right-color: #526069;
}
.btn-dark.btn-left:hover:before,
.btn-dark.btn-left:focus:before {
    border-right-color: #76838f;
}
.btn-dark.btn-left:active:before,
.btn-dark.btn-left.active:before,
.open > .dropdown-toggle.btn-dark.btn-left:before {
    border-right-color: #37474f;
}
.btn-dark:hover,
.btn-dark:focus {
    color: #ffffff;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
    color: #ffffff;
}
.btn-dark.btn-flat {
    color: #526069;
}
.btn-flat {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-flat.disabled {
    color: #a3afb7;
}
.btn-raised {
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.21);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.21);
    -webkit-transition: -webkit-box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-raised:hover,
.btn-raised:active,
.btn-raised.active,
.open > .dropdown-toggle.btn-raised {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.2);
}
.btn-raised.disabled,
.btn-raised[disabled],
fieldset[disabled] .btn-raised {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-label {
    background-color: rgba(0, 0, 0, 0.15);
}
.btn-direction:before {
    border: 8px solid transparent;
}
.btn-up:before {
    border-bottom-color: #e4eaec;
}
.btn-right:before {
    border-left-color: #e4eaec;
}
.btn-bottom:before {
    border-top-color: #e4eaec;
}
.btn-left:before {
    border-right-color: #e4eaec;
}
.btn-pure,
.btn-pure:hover,
.btn-pure:focus,
.btn-pure:active,
.btn-pure.active,
.open > .dropdown-toggle.btn-pure,
.btn-pure[disabled],
fieldset[disabled] .btn-pure {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-pure:hover,
.btn-pure:hover:hover,
.btn-pure:focus:hover,
.btn-pure:active:hover,
.btn-pure.active:hover,
.open > .dropdown-toggle.btn-pure:hover,
.btn-pure[disabled]:hover,
fieldset[disabled] .btn-pure:hover,
.btn-pure:focus,
.btn-pure:hover:focus,
.btn-pure:focus:focus,
.btn-pure:active:focus,
.btn-pure.active:focus,
.open > .dropdown-toggle.btn-pure:focus,
.btn-pure[disabled]:focus,
fieldset[disabled] .btn-pure:focus,
.btn-pure.focus,
.btn-pure:hover.focus,
.btn-pure:focus.focus,
.btn-pure:active.focus,
.btn-pure.active.focus,
.open > .dropdown-toggle.btn-pure.focus,
.btn-pure[disabled].focus,
fieldset[disabled] .btn-pure.focus {
    background-color: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.btn-pure.btn-default {
    color: #a3afb7;
}
.btn-pure.btn-default:hover,
.btn-pure.btn-default:focus,
.btn-pure.btn-default:active,
.btn-pure.btn-default.active,
.open > .dropdown-toggle.btn-pure.btn-default {
    color: #ccd5db;
}
.btn-pure.btn-default:hover:hover,
.btn-pure.btn-default:focus:hover,
.btn-pure.btn-default:active:hover,
.btn-pure.btn-default.active:hover,
.open > .dropdown-toggle.btn-pure.btn-default:hover,
.btn-pure.btn-default:hover:focus,
.btn-pure.btn-default:focus:focus,
.btn-pure.btn-default:active:focus,
.btn-pure.btn-default.active:focus,
.open > .dropdown-toggle.btn-pure.btn-default:focus,
.btn-pure.btn-default:hover.focus,
.btn-pure.btn-default:focus.focus,
.btn-pure.btn-default:active.focus,
.btn-pure.btn-default.active.focus,
.open > .dropdown-toggle.btn-pure.btn-default.focus {
    color: #ccd5db;
}
.btn-pure.btn-default:hover .badge,
.btn-pure.btn-default:focus .badge,
.btn-pure.btn-default:active .badge,
.btn-pure.btn-default.active .badge,
.open > .dropdown-toggle.btn-pure.btn-default .badge {
    color: #ccd5db;
}
.btn-pure.btn-primary {
    color: #f96868;
}
.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:active,
.btn-pure.btn-primary.active,
.open > .dropdown-toggle.btn-pure.btn-primary {
    color: #fa9898;
}
.btn-pure.btn-primary:hover:hover,
.btn-pure.btn-primary:focus:hover,
.btn-pure.btn-primary:active:hover,
.btn-pure.btn-primary.active:hover,
.open > .dropdown-toggle.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:hover:focus,
.btn-pure.btn-primary:focus:focus,
.btn-pure.btn-primary:active:focus,
.btn-pure.btn-primary.active:focus,
.open > .dropdown-toggle.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:hover.focus,
.btn-pure.btn-primary:focus.focus,
.btn-pure.btn-primary:active.focus,
.btn-pure.btn-primary.active.focus,
.open > .dropdown-toggle.btn-pure.btn-primary.focus {
    color: #fa9898;
}
.btn-pure.btn-primary:hover .badge,
.btn-pure.btn-primary:focus .badge,
.btn-pure.btn-primary:active .badge,
.btn-pure.btn-primary.active .badge,
.open > .dropdown-toggle.btn-pure.btn-primary .badge {
    color: #fa9898;
}
.btn-pure.btn-success {
    color: #46be8a;
}
.btn-pure.btn-success:hover,
.btn-pure.btn-success:focus,
.btn-pure.btn-success:active,
.btn-pure.btn-success.active,
.open > .dropdown-toggle.btn-pure.btn-success {
    color: #7dd3ae;
}
.btn-pure.btn-success:hover:hover,
.btn-pure.btn-success:focus:hover,
.btn-pure.btn-success:active:hover,
.btn-pure.btn-success.active:hover,
.open > .dropdown-toggle.btn-pure.btn-success:hover,
.btn-pure.btn-success:hover:focus,
.btn-pure.btn-success:focus:focus,
.btn-pure.btn-success:active:focus,
.btn-pure.btn-success.active:focus,
.open > .dropdown-toggle.btn-pure.btn-success:focus,
.btn-pure.btn-success:hover.focus,
.btn-pure.btn-success:focus.focus,
.btn-pure.btn-success:active.focus,
.btn-pure.btn-success.active.focus,
.open > .dropdown-toggle.btn-pure.btn-success.focus {
    color: #7dd3ae;
}
.btn-pure.btn-success:hover .badge,
.btn-pure.btn-success:focus .badge,
.btn-pure.btn-success:active .badge,
.btn-pure.btn-success.active .badge,
.open > .dropdown-toggle.btn-pure.btn-success .badge {
    color: #7dd3ae;
}
.btn-pure.btn-info {
    color: #57c7d4;
}
.btn-pure.btn-info:hover,
.btn-pure.btn-info:focus,
.btn-pure.btn-info:active,
.btn-pure.btn-info.active,
.open > .dropdown-toggle.btn-pure.btn-info {
    color: #9ae1e9;
}
.btn-pure.btn-info:hover:hover,
.btn-pure.btn-info:focus:hover,
.btn-pure.btn-info:active:hover,
.btn-pure.btn-info.active:hover,
.open > .dropdown-toggle.btn-pure.btn-info:hover,
.btn-pure.btn-info:hover:focus,
.btn-pure.btn-info:focus:focus,
.btn-pure.btn-info:active:focus,
.btn-pure.btn-info.active:focus,
.open > .dropdown-toggle.btn-pure.btn-info:focus,
.btn-pure.btn-info:hover.focus,
.btn-pure.btn-info:focus.focus,
.btn-pure.btn-info:active.focus,
.btn-pure.btn-info.active.focus,
.open > .dropdown-toggle.btn-pure.btn-info.focus {
    color: #9ae1e9;
}
.btn-pure.btn-info:hover .badge,
.btn-pure.btn-info:focus .badge,
.btn-pure.btn-info:active .badge,
.btn-pure.btn-info.active .badge,
.open > .dropdown-toggle.btn-pure.btn-info .badge {
    color: #9ae1e9;
}
.btn-pure.btn-warning {
    color: #f2a654;
}
.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:active,
.btn-pure.btn-warning.active,
.open > .dropdown-toggle.btn-pure.btn-warning {
    color: #f6be80;
}
.btn-pure.btn-warning:hover:hover,
.btn-pure.btn-warning:focus:hover,
.btn-pure.btn-warning:active:hover,
.btn-pure.btn-warning.active:hover,
.open > .dropdown-toggle.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:hover:focus,
.btn-pure.btn-warning:focus:focus,
.btn-pure.btn-warning:active:focus,
.btn-pure.btn-warning.active:focus,
.open > .dropdown-toggle.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:hover.focus,
.btn-pure.btn-warning:focus.focus,
.btn-pure.btn-warning:active.focus,
.btn-pure.btn-warning.active.focus,
.open > .dropdown-toggle.btn-pure.btn-warning.focus {
    color: #f6be80;
}
.btn-pure.btn-warning:hover .badge,
.btn-pure.btn-warning:focus .badge,
.btn-pure.btn-warning:active .badge,
.btn-pure.btn-warning.active .badge,
.open > .dropdown-toggle.btn-pure.btn-warning .badge {
    color: #f6be80;
}
.btn-pure.btn-danger {
    color: #f96868;
}
.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:active,
.btn-pure.btn-danger.active,
.open > .dropdown-toggle.btn-pure.btn-danger {
    color: #fa9898;
}
.btn-pure.btn-danger:hover:hover,
.btn-pure.btn-danger:focus:hover,
.btn-pure.btn-danger:active:hover,
.btn-pure.btn-danger.active:hover,
.open > .dropdown-toggle.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:hover:focus,
.btn-pure.btn-danger:focus:focus,
.btn-pure.btn-danger:active:focus,
.btn-pure.btn-danger.active:focus,
.open > .dropdown-toggle.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:hover.focus,
.btn-pure.btn-danger:focus.focus,
.btn-pure.btn-danger:active.focus,
.btn-pure.btn-danger.active.focus,
.open > .dropdown-toggle.btn-pure.btn-danger.focus {
    color: #fa9898;
}
.btn-pure.btn-danger:hover .badge,
.btn-pure.btn-danger:focus .badge,
.btn-pure.btn-danger:active .badge,
.btn-pure.btn-danger.active .badge,
.open > .dropdown-toggle.btn-pure.btn-danger .badge {
    color: #fa9898;
}
.btn-pure.btn-dark {
    color: #526069;
}
.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:active,
.btn-pure.btn-dark.active,
.open > .dropdown-toggle.btn-pure.btn-dark {
    color: #76838f;
}
.btn-pure.btn-dark:hover:hover,
.btn-pure.btn-dark:focus:hover,
.btn-pure.btn-dark:active:hover,
.btn-pure.btn-dark.active:hover,
.open > .dropdown-toggle.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:hover:focus,
.btn-pure.btn-dark:focus:focus,
.btn-pure.btn-dark:active:focus,
.btn-pure.btn-dark.active:focus,
.open > .dropdown-toggle.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:hover.focus,
.btn-pure.btn-dark:focus.focus,
.btn-pure.btn-dark:active.focus,
.btn-pure.btn-dark.active.focus,
.open > .dropdown-toggle.btn-pure.btn-dark.focus {
    color: #76838f;
}
.btn-pure.btn-dark:hover .badge,
.btn-pure.btn-dark:focus .badge,
.btn-pure.btn-dark:active .badge,
.btn-pure.btn-dark.active .badge,
.open > .dropdown-toggle.btn-pure.btn-dark .badge {
    color: #76838f;
}
.btn-pure.btn-inverse {
    color: #ffffff;
}
.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:active,
.btn-pure.btn-inverse.active,
.open > .dropdown-toggle.btn-pure.btn-inverse {
    color: #ffffff;
}
.btn-pure.btn-inverse:hover:hover,
.btn-pure.btn-inverse:focus:hover,
.btn-pure.btn-inverse:active:hover,
.btn-pure.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:hover:focus,
.btn-pure.btn-inverse:focus:focus,
.btn-pure.btn-inverse:active:focus,
.btn-pure.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:hover.focus,
.btn-pure.btn-inverse:focus.focus,
.btn-pure.btn-inverse:active.focus,
.btn-pure.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-pure.btn-inverse.focus {
    color: #ffffff;
}
.btn-pure.btn-inverse:hover .badge,
.btn-pure.btn-inverse:focus .badge,
.btn-pure.btn-inverse:active .badge,
.btn-pure.btn-inverse.active .badge,
.open > .dropdown-toggle.btn-pure.btn-inverse .badge {
    color: #ffffff;
}
.caret {
    border-top: 4px solid;
}
.dropdown-menu {
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
}
.dropdown-menu.bullet:before,
.dropdown-menu.bullet:after {
    border: 7px solid transparent;
    border-top-width: 0;
}
.dropdown-menu.bullet:before {
    border-bottom-color: #e4eaec;
}
.dropdown-menu.bullet:after {
    border-bottom-color: #ffffff;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.05);
    box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.05);
}
.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before,
.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
    border-top-width: 7px;
    border-bottom-width: 0;
}
.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
    border-top-color: #e4eaec;
}
.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
    border-top-color: #ffffff;
}
.dropdown-menu > .dropdown-submenu > a:after {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px dashed;
}
.dropdown-menu-media .dropdown-menu-header {
    padding: 20px 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #e4eaec;
}
.dropdown-menu-media .list-group-item {
    border: none;
}
.dropdown-menu-media .list-group-item .media {
    border-top: 1px solid #e4eaec;
}
.dropdown-menu-media .list-group-item:first-child .media {
    border-top: none;
}
.dropdown-menu-media > .dropdown-menu-footer {
    border-top: 1px solid #e4eaec;
    background-color: #f3f7f9;
}
.dropdown-menu-media > .dropdown-menu-footer > a {
    color: #a3afb7 !important;
}
.dropdown-menu-media > .dropdown-menu-footer > a:hover {
    background-color: transparent !important;
    color: #fa7a7a !important;
}
.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn:hover {
    color: #fa7a7a !important;
    background-color: transparent !important;
}
.dropdown-menu-primary > .active > a,
.dropdown-menu-primary > .active > a:hover,
.dropdown-menu-primary > .active > a:focus {
    color: #ffffff;
    background-color: #f96868;
}
.dropdown-menu-success > .active > a,
.dropdown-menu-success > .active > a:hover,
.dropdown-menu-success > .active > a:focus {
    color: #ffffff;
    background-color: #46be8a;
}
.dropdown-menu-info > .active > a,
.dropdown-menu-info > .active > a:hover,
.dropdown-menu-info > .active > a:focus {
    color: #ffffff;
    background-color: #57c7d4;
}
.dropdown-menu-warning > .active > a,
.dropdown-menu-warning > .active > a:hover,
.dropdown-menu-warning > .active > a:focus {
    color: #ffffff;
    background-color: #f2a654;
}
.dropdown-menu-danger > .active > a,
.dropdown-menu-danger > .active > a:hover,
.dropdown-menu-danger > .active > a:focus {
    color: #ffffff;
    background-color: #f96868;
}
.dropdown-menu-dark > .active > a,
.dropdown-menu-dark > .active > a:hover,
.dropdown-menu-dark > .active > a:focus {
    color: #ffffff;
    background-color: #526069;
}
.btn-group.open .dropdown-toggle {
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    border-color: transparent;
}
.nav-quick {
    background-color: #ffffff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.nav-quick a {
    color: #76838f;
}
.nav-quick a:hover {
    background-color: #f3f7f9;
}
.nav-quick-bordered {
    border-top: 1px solid #e4eaec;
    border-left: 1px solid #e4eaec;
}
.nav-quick-bordered li {
    border-bottom: 1px solid #e4eaec;
    border-right: 1px solid #e4eaec;
}
.nav-tabs > li > a {
    color: #76838f;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #ffffff;
    background-color: #f96868;
    border-color: transparent;
    border-bottom-color: #f96868;
}
.nav-tabs.nav-justified > li.active > a,
.nav-tabs.nav-justified > li.active > a:hover,
.nav-tabs.nav-justified > li.active > a:focus {
    border-color: transparent;
    border-bottom-color: #f96868;
}
.nav-tabs.nav-tabs-bottom {
    border-top: 1px solid #e4eaec;
}
.nav-tabs.nav-tabs-bottom > li > a:hover,
.nav-tabs.nav-tabs-bottom > li > a:focus {
    border-bottom-color: transparent;
    border-top-color: #e4eaec;
}
.nav-tabs.nav-tabs-bottom.nav-justified {
    border-top: none;
}
.nav-tabs.nav-tabs-bottom.nav-justified > li > a {
    border-bottom-color: transparent;
    border-top-color: #e4eaec;
}
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:hover,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:focus {
    border-top: 1px solid #f96868;
}
.nav-tabs-solid {
    border-bottom-color: #f3f7f9;
}
.nav-tabs-solid > li > a:hover {
    border-color: transparent;
}
.nav-tabs-solid > li.active > a,
.nav-tabs-solid > li.active > a:hover,
.nav-tabs-solid > li.active > a:focus {
    color: #76838f;
    background-color: #f3f7f9;
    border-color: transparent;
}
.nav-tabs-solid ~ .tab-content {
    background-color: #f3f7f9;
}
.nav-tabs-solid.nav-justified > li > a {
    border: none;
}
.nav-tabs-solid.nav-justified > li.active > a,
.nav-tabs-solid.nav-justified > li.active > a:hover,
.nav-tabs-solid.nav-justified > li.active > a:focus {
    border: none;
}
.nav-tabs-solid.nav-tabs-bottom > li.active > a,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:focus {
    border: none;
}
.nav-tabs-line > li > a {
    border-bottom: 2px solid transparent;
}
.nav-tabs-line > li > a:hover,
.nav-tabs-line > li > a:focus {
    background-color: transparent;
}
.nav-tabs-line > li > a:hover {
    border-bottom-color: #ccd5db;
}
.nav-tabs-line > li.active > a,
.nav-tabs-line > li.active > a:hover,
.nav-tabs-line > li.active > a:focus {
    color: #f96868;
    background-color: transparent;
    border-bottom: 2px solid #f96868;
}
.nav-tabs-line .open > a,
.nav-tabs-line .open > a:hover,
.nav-tabs-line .open > a:focus {
    border-color: transparent;
    border-bottom-color: #ccd5db;
}
.nav-tabs-line.nav-tabs-bottom > li > a {
    border-top: 2px solid transparent;
    border-bottom: none;
}
.nav-tabs-line.nav-tabs-bottom > li > a:hover {
    border-top-color: #ccd5db;
    border-bottom-color: transparent;
}
.nav-tabs-line.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-tabs-bottom > li.active > a:focus {
    border-top: 2px solid #f96868;
    border-bottom: none;
}
.nav-tabs-line.nav-justified > li > a {
    border-bottom: 2px solid #e4eaec;
}
.nav-tabs-line.nav-justified > li > a:hover {
    border-bottom-color: #ccd5db;
}
.nav-tabs-line.nav-justified > li.active > a,
.nav-tabs-line.nav-justified > li.active > a:hover,
.nav-tabs-line.nav-justified > li.active > a:focus {
    border-color: transparent;
    border-bottom: 2px solid #f96868;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom {
    border-top: none;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a {
    border-top: 2px solid #e4eaec;
    border-bottom: none;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a:hover {
    border-top-color: #ccd5db;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:focus {
    border-top-color: #f96868;
    border-bottom: none;
}
.nav-tabs-vertical:before,
.nav-tabs-vertical:after {
    content: " ";
    display: table;
}
.nav-tabs-vertical:after {
    clear: both;
}
.nav-tabs-vertical .nav-tabs {
    border-right: 1px solid #e4eaec;
    border-bottom: none;
}
.nav-tabs-vertical .nav-tabs > li > a:hover {
    border-bottom-color: transparent;
    border-right-color: #e4eaec;
}
.nav-tabs-vertical .nav-tabs-reverse {
    border-right: none;
    border-left: 1px solid #e4eaec;
}
.nav-tabs-vertical .nav-tabs-reverse > li > a:hover {
    border-right-color: transparent;
    border-left-color: #e4eaec;
}
.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #f3f7f9;
}
.nav-tabs-vertical .nav-tabs-solid > li > a:hover {
    border-color: transparent;
}
.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #f3f7f9;
}
.nav-tabs-vertical .nav-tabs-line > li > a {
    border-right: 2px solid transparent;
    border-bottom: none;
}
.nav-tabs-vertical .nav-tabs-line > li > a:hover {
    border-right-color: #ccd5db;
}
.nav-tabs-vertical .nav-tabs-line > li.active > a,
.nav-tabs-vertical .nav-tabs-line > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line > li.active > a:focus {
    border-right: 2px solid #f96868;
    border-bottom: none;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a {
    border-right-width: 1px;
    border-left: 2px solid transparent;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a:hover {
    border-color: transparent;
    border-left-color: #ccd5db;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:focus {
    border-right: 1px solid transparent;
    border-left: 2px solid #f96868;
}
.nav-tabs-inverse .nav-tabs-solid {
    border-bottom-color: #ffffff;
}
.nav-tabs-inverse .nav-tabs-solid > li.active > a,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:hover,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:focus {
    color: #76838f;
    background-color: #ffffff;
}
.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
    border-right-color: #ffffff;
}
.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
    border-left-color: #ffffff;
}
.nav-tabs-inverse .tab-content {
    background: #ffffff;
}
.navbar-toggle {
    background: transparent !important;
}
.navbar-toggle:hover {
    background: transparent !important;
}
.navbar {
    border: none;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
.navbar-form .icon {
    color: rgba(55, 71, 79, 0.4);
}
.navbar-form .form-control {
    background-color: #f3f7f9;
    border: none;
}
@media (max-width: 767px) {
    .navbar-search .navbar-form {
        border-bottom: none;
    }
}
.navbar-search-overlap {
    background-color: #ffffff;
}
.navbar-search-overlap .form-control {
    background-color: transparent !important;
}
.navbar-search-overlap .form-control:focus {
    border-color: transparent;
}
.navbar-default .navbar-toolbar > li > a {
    color: #76838f;
}
.navbar-default .navbar-toolbar > li > a:hover,
.navbar-default .navbar-toolbar > li > a:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.3);
}
.navbar-default .navbar-toolbar > .active > a,
.navbar-default .navbar-toolbar > .active > a:hover,
.navbar-default .navbar-toolbar > .active > a:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.6);
}
.navbar-default .navbar-toolbar > .disabled > a,
.navbar-default .navbar-toolbar > .disabled > a:hover,
.navbar-default .navbar-toolbar > .disabled > a:focus {
    color: #ccd5db;
    background-color: transparent;
}
.navbar-default .navbar-toggle {
    color: #76838f;
}
.navbar-default .navbar-toolbar > .open > a,
.navbar-default .navbar-toolbar > .open > a:hover,
.navbar-default .navbar-toolbar > .open > a:focus {
    color: #526069;
    background-color: rgba(243, 247, 249, 0.6);
}
.navbar-inverse .navbar-toolbar > li > a {
    color: #ffffff;
}
.navbar-inverse .navbar-toolbar > li > a:hover,
.navbar-inverse .navbar-toolbar > li > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .active > a,
.navbar-inverse .navbar-toolbar > .active > a:hover,
.navbar-inverse .navbar-toolbar > .active > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .disabled > a,
.navbar-inverse .navbar-toolbar > .disabled > a:hover,
.navbar-inverse .navbar-toolbar > .disabled > a:focus {
    color: #ffffff;
    background-color: transparent;
}
.navbar-inverse .navbar-toggle {
    color: #ffffff;
}
.navbar-inverse .navbar-toolbar > .open > a,
.navbar-inverse .navbar-toolbar > .open > a:hover,
.navbar-inverse .navbar-toolbar > .open > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.1);
}
.breadcrumb li .icon {
    text-decoration: none;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #ccd5db;
    background-color: transparent;
    border-color: #e4eaec;
}
.pagination-gap > li > a:hover {
    background-color: transparent;
    border-color: #f96868;
}
.pagination-no-border > li > a {
    border: none;
}
.pager li > a,
.pager li > span {
    color: #76838f;
}
.pager li > a:hover,
.pager li > a:focus {
    color: #f96868;
}
.pager li > a:hover,
.pager li > a:focus {
    border-color: #f96868;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
    border-color: #e4eaec;
}
.label.label-outline {
    color: #f3f7f9;
    background-color: transparent;
    border-color: #f3f7f9;
}
.label-outline {
    border: 1px solid transparent;
}
.label-default {
    color: #76838f;
    background-color: #e4eaec;
}
.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #f3f7f9;
}
.label-default.label-outline {
    color: #e4eaec;
    background-color: transparent;
    border-color: #e4eaec;
}
.label-default[href]:hover,
.label-default[href]:focus {
    color: #a3afb7;
}
.label-default.label-outline {
    color: #76838f;
}
.label-primary {
    background-color: #f96868;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #fa7a7a;
}
.label-primary.label-outline {
    color: #f96868;
    background-color: transparent;
    border-color: #f96868;
}
.label-success {
    background-color: #46be8a;
}
.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #5cd29d;
}
.label-success.label-outline {
    color: #46be8a;
    background-color: transparent;
    border-color: #46be8a;
}
.label-info {
    background-color: #57c7d4;
}
.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #77d6e1;
}
.label-info.label-outline {
    color: #57c7d4;
    background-color: transparent;
    border-color: #57c7d4;
}
.label-warning {
    background-color: #f2a654;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #f4b066;
}
.label-warning.label-outline {
    color: #f2a654;
    background-color: transparent;
    border-color: #f2a654;
}
.label-danger {
    background-color: #f96868;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #fa7a7a;
}
.label-danger.label-outline {
    color: #f96868;
    background-color: transparent;
    border-color: #f96868;
}
.label-dark {
    background-color: #526069;
}
.label-dark[href]:hover,
.label-dark[href]:focus {
    background-color: #76838f;
}
.label-dark.label-outline {
    color: #526069;
    background-color: transparent;
    border-color: #526069;
}
.badge-primary {
    color: #ffffff;
    background-color: #f96868;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
    color: #ffffff;
    background-color: #f73737;
}
.list-group-item.active > .badge-primary,
.nav-pills > .active > a > .badge-primary {
    color: #ffffff;
    background-color: #f96868;
}
.badge-success {
    color: #ffffff;
    background-color: #46be8a;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
    color: #ffffff;
    background-color: #369b6f;
}
.list-group-item.active > .badge-success,
.nav-pills > .active > a > .badge-success {
    color: #ffffff;
    background-color: #46be8a;
}
.badge-info {
    color: #ffffff;
    background-color: #57c7d4;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
    color: #ffffff;
    background-color: #33b6c5;
}
.list-group-item.active > .badge-info,
.nav-pills > .active > a > .badge-info {
    color: #ffffff;
    background-color: #57c7d4;
}
.badge-warning {
    color: #ffffff;
    background-color: #f2a654;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
    color: #ffffff;
    background-color: #ee8d25;
}
.list-group-item.active > .badge-warning,
.nav-pills > .active > a > .badge-warning {
    color: #ffffff;
    background-color: #f2a654;
}
.badge-danger {
    color: #ffffff;
    background-color: #f96868;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
    color: #ffffff;
    background-color: #f73737;
}
.list-group-item.active > .badge-danger,
.nav-pills > .active > a > .badge-danger {
    color: #ffffff;
    background-color: #f96868;
}
.badge-dark {
    color: #ffffff;
    background-color: #526069;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
    color: #ffffff;
    background-color: #3c464c;
}
.list-group-item.active > .badge-dark,
.nav-pills > .active > a > .badge-dark {
    color: #ffffff;
    background-color: #526069;
}
.thumbnail {
    border: none;
}
.alert-alt {
    color: #76838f;
    background-color: rgba(243, 247, 249, 0.8);
    border: none;
    border-left: 3px solid transparent;
}
.alert-alt a,
.alert-alt .alert-link {
    text-decoration: none;
}
.alert-dismissible .close {
    text-decoration: none;
}
.alert-dismissible.alert-alt .close {
    color: #a3afb7;
}
.alert-dismissible.alert-alt .close:hover,
.alert-dismissible.alert-alt .close:focus {
    color: #a3afb7;
}
.alert-primary {
    background-color: rgba(255, 234, 234, 0.8);
    border-color: #ffeaea;
    color: #f96868;
}
.alert-primary hr {
    border-top-color: #ffd0d0;
}
.alert-primary .alert-link {
    color: #f73737;
}
.alert-primary .close {
    color: #f96868;
}
.alert-primary .close:hover,
.alert-primary .close:focus {
    color: #f96868;
}
.alert-primary .alert-link {
    color: #e9595b;
}
.alert-alt.alert-primary {
    border-color: #f96868;
}
.alert-alt.alert-primary a,
.alert-alt.alert-primary .alert-link {
    color: #f96868;
}
.alert-success .alert-link {
    color: #36ab7a;
}
.alert-alt.alert-success {
    border-color: #46be8a;
}
.alert-alt.alert-success a,
.alert-alt.alert-success .alert-link {
    color: #46be8a;
}
.alert-info .alert-link {
    color: #47b8c6;
}
.alert-alt.alert-info {
    border-color: #57c7d4;
}
.alert-alt.alert-info a,
.alert-alt.alert-info .alert-link {
    color: #57c7d4;
}
.alert-warning .alert-link {
    color: #ec9940;
}
.alert-alt.alert-warning {
    border-color: #f2a654;
}
.alert-alt.alert-warning a,
.alert-alt.alert-warning .alert-link {
    color: #f2a654;
}
.alert-danger .alert-link {
    color: #e9595b;
}
.alert-alt.alert-danger {
    border-color: #f96868;
}
.alert-alt.alert-danger a,
.alert-alt.alert-danger .alert-link {
    color: #f96868;
}
.alert-facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #ffffff;
}
.alert-facebook hr {
    border-top-color: #344e86;
}
.alert-facebook .alert-link {
    color: #e6e6e6;
}
.alert-facebook .close {
    color: #ffffff;
}
.alert-facebook .close:hover,
.alert-facebook .close:focus {
    color: #ffffff;
}
.alert-facebook .alert-link {
    color: #ffffff;
}
.alert-twitter {
    background-color: #55acee;
    border-color: #55acee;
    color: #ffffff;
}
.alert-twitter hr {
    border-top-color: #3ea1ec;
}
.alert-twitter .alert-link {
    color: #e6e6e6;
}
.alert-twitter .close {
    color: #ffffff;
}
.alert-twitter .close:hover,
.alert-twitter .close:focus {
    color: #ffffff;
}
.alert-twitter .alert-link {
    color: #ffffff;
}
.alert-google-plus {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #ffffff;
}
.alert-google-plus hr {
    border-top-color: #d73925;
}
.alert-google-plus .alert-link {
    color: #e6e6e6;
}
.alert-google-plus .close {
    color: #ffffff;
}
.alert-google-plus .close:hover,
.alert-google-plus .close:focus {
    color: #ffffff;
}
.alert-google-plus .alert-link {
    color: #ffffff;
}
.alert-linkedin {
    background-color: #0976b4;
    border-color: #0976b4;
    color: #ffffff;
}
.alert-linkedin hr {
    border-top-color: #08669c;
}
.alert-linkedin .alert-link {
    color: #e6e6e6;
}
.alert-linkedin .close {
    color: #ffffff;
}
.alert-linkedin .close:hover,
.alert-linkedin .close:focus {
    color: #ffffff;
}
.alert-linkedin .alert-link {
    color: #ffffff;
}
.alert-flickr {
    background-color: #ff0084;
    border-color: #ff0084;
    color: #ffffff;
}
.alert-flickr hr {
    border-top-color: #e60077;
}
.alert-flickr .alert-link {
    color: #e6e6e6;
}
.alert-flickr .close {
    color: #ffffff;
}
.alert-flickr .close:hover,
.alert-flickr .close:focus {
    color: #ffffff;
}
.alert-flickr .alert-link {
    color: #ffffff;
}
.alert-tumblr {
    background-color: #35465c;
    border-color: #35465c;
    color: #ffffff;
}
.alert-tumblr hr {
    border-top-color: #2c3a4c;
}
.alert-tumblr .alert-link {
    color: #e6e6e6;
}
.alert-tumblr .close {
    color: #ffffff;
}
.alert-tumblr .close:hover,
.alert-tumblr .close:focus {
    color: #ffffff;
}
.alert-tumblr .alert-link {
    color: #ffffff;
}
.alert-github {
    background-color: #4183c4;
    border-color: #4183c4;
    color: #ffffff;
}
.alert-github hr {
    border-top-color: #3876b4;
}
.alert-github .alert-link {
    color: #e6e6e6;
}
.alert-github .close {
    color: #ffffff;
}
.alert-github .close:hover,
.alert-github .close:focus {
    color: #ffffff;
}
.alert-github .alert-link {
    color: #ffffff;
}
.alert-dribbble {
    background-color: #c32361;
    border-color: #c32361;
    color: #ffffff;
}
.alert-dribbble hr {
    border-top-color: #ad1f56;
}
.alert-dribbble .alert-link {
    color: #e6e6e6;
}
.alert-dribbble .close {
    color: #ffffff;
}
.alert-dribbble .close:hover,
.alert-dribbble .close:focus {
    color: #ffffff;
}
.alert-dribbble .alert-link {
    color: #ffffff;
}
.alert-youtube {
    background-color: #b31217;
    border-color: #b31217;
    color: #ffffff;
}
.alert-youtube hr {
    border-top-color: #9c1014;
}
.alert-youtube .alert-link {
    color: #e6e6e6;
}
.alert-youtube .close {
    color: #ffffff;
}
.alert-youtube .close:hover,
.alert-youtube .close:focus {
    color: #ffffff;
}
.alert-youtube .alert-link {
    color: #ffffff;
}
.alert.dark .alert-link {
    color: #ffffff !important;
}
.alert.dark .alert-left-border {
    border: none;
    border-left: 3px solid transparent;
}
.alert.dark.alert-dismissible.alert-alt .close {
    color: #ffffff;
}
.alert.dark.alert-dismissible.alert-alt .close:hover,
.alert.dark.alert-dismissible.alert-alt .close:focus {
    color: #ffffff;
}
.alert.dark.alert-primary {
    background-color: #f96868;
    border-color: #f96868;
    color: #ffffff;
}
.alert.dark.alert-primary hr {
    border-top-color: #f84f4f;
}
.alert.dark.alert-primary .alert-link {
    color: #e6e6e6;
}
.alert.dark.alert-primary .close {
    color: #ffffff;
}
.alert.dark.alert-primary .close:hover,
.alert.dark.alert-primary .close:focus {
    color: #ffffff;
}
.alert-alt.alert.dark.alert-primary {
    border-color: #d91d1f;
}
.alert-alt.alert.dark.alert-primary a,
.alert-alt.alert.dark.alert-primary .alert-link {
    color: #ffffff;
}
.alert.dark.alert-success {
    background-color: #46be8a;
    border-color: #46be8a;
    color: #ffffff;
}
.alert.dark.alert-success hr {
    border-top-color: #3dae7d;
}
.alert.dark.alert-success .alert-link {
    color: #e6e6e6;
}
.alert.dark.alert-success .close {
    color: #ffffff;
}
.alert.dark.alert-success .close:hover,
.alert.dark.alert-success .close:focus {
    color: #ffffff;
}
.alert-alt.alert.dark.alert-success {
    border-color: #247151;
}
.alert-alt.alert.dark.alert-success a,
.alert-alt.alert.dark.alert-success .alert-link {
    color: #ffffff;
}
.alert.dark.alert-info {
    background-color: #57c7d4;
    border-color: #57c7d4;
    color: #ffffff;
}
.alert.dark.alert-info hr {
    border-top-color: #43c0cf;
}
.alert.dark.alert-info .alert-link {
    color: #e6e6e6;
}
.alert.dark.alert-info .close {
    color: #ffffff;
}
.alert.dark.alert-info .close:hover,
.alert.dark.alert-info .close:focus {
    color: #ffffff;
}
.alert-alt.alert.dark.alert-info {
    border-color: #2e8893;
}
.alert-alt.alert.dark.alert-info a,
.alert-alt.alert.dark.alert-info .alert-link {
    color: #ffffff;
}
.alert.dark.alert-warning {
    background-color: #f2a654;
    border-color: #f2a654;
    color: #ffffff;
}
.alert.dark.alert-warning hr {
    border-top-color: #f09a3c;
}
.alert.dark.alert-warning .alert-link {
    color: #e6e6e6;
}
.alert.dark.alert-warning .close {
    color: #ffffff;
}
.alert.dark.alert-warning .close:hover,
.alert.dark.alert-warning .close:focus {
    color: #ffffff;
}
.alert-alt.alert.dark.alert-warning {
    border-color: #cb7314;
}
.alert-alt.alert.dark.alert-warning a,
.alert-alt.alert.dark.alert-warning .alert-link {
    color: #ffffff;
}
.alert.dark.alert-danger {
    background-color: #f96868;
    border-color: #f96868;
    color: #ffffff;
}
.alert.dark.alert-danger hr {
    border-top-color: #f84f4f;
}
.alert.dark.alert-danger .alert-link {
    color: #e6e6e6;
}
.alert.dark.alert-danger .close {
    color: #ffffff;
}
.alert.dark.alert-danger .close:hover,
.alert.dark.alert-danger .close:focus {
    color: #ffffff;
}
.alert-alt.alert.dark.alert-danger {
    border-color: #d91d1f;
}
.alert-alt.alert.dark.alert-danger a,
.alert-alt.alert.dark.alert-danger .alert-link {
    color: #ffffff;
}
.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.progress-bar-indicating.active:before {
    background-color: #ffffff;
}
.vertical .progress-bar-indicating.active:before {
    background-color: #ffffff;
}
.progress-skill .progress-bar > span {
    color: #526069;
}
.media .media {
    border-bottom: none;
}
.media-meta {
    color: #526069;
}
.list-group .media {
    border-bottom: 0;
}
a.list-group-item.disabled,
a.list-group-item.disabled:hover,
a.list-group-item.disabled:focus {
    color: #ccd5db;
    background-color: #f3f7f9;
}
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    color: #ffffff;
    background-color: #f96868;
}
.list-group.bg-inherit .list-group-item {
    background-color: transparent;
    border-bottom-color: rgba(0, 0, 0, 0.075);
}
.list-group.bg-inherit .list-group-item:last-child {
    border-bottom-color: transparent;
}
.list-group.bg-inherit .list-group-item:hover {
    background-color: rgba(0, 0, 0, 0.075);
    border-color: transparent;
}
.list-group-bordered .list-group-item {
    border-color: #e4eaec;
}
.list-group-bordered .list-group-item.active,
.list-group-bordered .list-group-item.active:hover,
.list-group-bordered .list-group-item.active:focus {
    color: #ffffff;
    background-color: #e9595b;
    border-color: #e9595b;
}
.list-group-dividered .list-group-item {
    border-top-color: #e4eaec;
}
.list-group-dividered .list-group-item.active:hover {
    border-top-color: #e4eaec;
}
.list-group-dividered .list-group-item:last-child {
    border-bottom-color: #e4eaec;
}
.list-group-dividered .list-group-item:first-child {
    border-top-color: transparent;
}
.list-group-dividered .list-group-item:first-child.active:hover {
    border-top-color: transparent;
}
.list-group-item-dark {
    color: #ffffff;
    background-color: #526069;
}
a.list-group-item-dark,
button.list-group-item-dark {
    color: #ffffff;
}
a.list-group-item-dark .list-group-item-heading,
button.list-group-item-dark .list-group-item-heading {
    color: inherit;
}
a.list-group-item-dark:hover,
button.list-group-item-dark:hover,
a.list-group-item-dark:focus,
button.list-group-item-dark:focus {
    color: #ffffff;
    background-color: #47535b;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active,
a.list-group-item-dark.active:hover,
button.list-group-item-dark.active:hover,
a.list-group-item-dark.active:focus,
button.list-group-item-dark.active:focus {
    color: #fff;
    background-color: #ffffff;
    border-color: #ffffff;
}
.panel {
    border-width: 0;
}
.panel > .nav-tabs-vertical .nav-tabs > li > a {
    border-left: none;
}
.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse > li > a {
    border-right: none;
}
.panel-heading {
    border-bottom: 1px solid transparent;
}
.panel-heading > .nav-tabs {
    border-bottom: none;
}
.panel-body > .list-group-dividered:only-child > .list-group-item:last-child {
    border-bottom-color: transparent;
}
.panel-footer {
    border-top: 1px solid transparent;
}
.table + .panel-footer {
    border-color: #e4eaec;
}
.panel-title {
    color: #37474f;
}
.panel-title small {
    color: #76838f;
}
.panel-desc {
    color: #76838f;
}
.panel-actions a {
    color: inherit;
}
.panel-actions .panel-action {
    color: #a3afb7;
    text-decoration: none;
    background-color: transparent;
}
.panel-actions .panel-action:hover {
    color: #526069;
}
.panel-actions .panel-action:active {
    color: #526069;
}
.panel-toolbar {
    background-color: transparent;
    border-bottom: 1px solid #e4eaec;
    border-top: 1px solid #e4eaec;
}
.panel-bordered .panel-toolbar {
    border-top-color: transparent;
}
.panel-toolbar .btn {
    color: #a3afb7;
}
.panel-toolbar .btn:hover,
.panel-toolbar .btn:active,
.panel-toolbar .btn.active {
    color: #76838f;
}
.panel-control {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.panel-bordered > .panel-heading {
    border-bottom: 1px solid #e4eaec;
}
.panel-bordered > .panel-footer {
    border-top: 1px solid #e4eaec;
}
.panel.panel-transparent {
    background: transparent;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.panel.panel-transparent > .panel-heading,
.panel.panel-transparent > .panel-footer {
    border-color: transparent;
}
.panel-primary,
.panel-info,
.panel-success,
.panel-warning,
.panel-danger,
.panel-dark {
    border: none;
}
.panel-primary .panel-heading,
.panel-info .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-danger .panel-heading,
.panel-dark .panel-heading {
    border: none;
}
.panel-primary .panel-title,
.panel-info .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title,
.panel-danger .panel-title,
.panel-dark .panel-title {
    color: #ffffff;
}
.panel-primary .panel-action,
.panel-info .panel-action,
.panel-success .panel-action,
.panel-warning .panel-action,
.panel-danger .panel-action,
.panel-dark .panel-action {
    color: #ffffff;
}
.well {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.02);
}
.well-primary {
    color: #ffffff;
    background-color: #f96868;
}
.well-success {
    color: #ffffff;
    background-color: #46be8a;
}
.well-info {
    color: #ffffff;
    background-color: #57c7d4;
}
.well-warning {
    color: #ffffff;
    background-color: #f2a654;
}
.well-danger {
    color: #ffffff;
    background-color: #f96868;
}
.modal-content {
    border: none;
    -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}
.modal-header {
    border-bottom: none;
}
.modal-footer {
    border-top: none;
}
.modal-sidebar {
    background-color: #ffffff;
}
.modal-sidebar .modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.modal-sidebar .modal-header {
    border-bottom: none;
}
.modal-sidebar .modal-footer {
    border-top: none;
}
.modal-fill-in {
    background-color: transparent;
}
.modal-fill-in.in {
    background-color: rgba(255, 255, 255, 0.95);
}
.modal-fill-in .modal-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.modal-fill-in .modal-header {
    border-bottom: none;
}
.modal-fill-in .modal-footer {
    border-top: none;
}
.modal-primary .modal-header {
    background-color: #f96868;
}
.modal-primary .modal-header * {
    color: #ffffff;
}
.modal-success .modal-header {
    background-color: #46be8a;
}
.modal-success .modal-header * {
    color: #ffffff;
}
.modal-info .modal-header {
    background-color: #57c7d4;
}
.modal-info .modal-header * {
    color: #ffffff;
}
.modal-warning .modal-header {
    background-color: #f2a654;
}
.modal-warning .modal-header * {
    color: #ffffff;
}
.modal-danger .modal-header {
    background-color: #f96868;
}
.modal-danger .modal-header * {
    color: #ffffff;
}
.modal.modal-just-me .modal-backdrop {
    background-color: #ffffff;
}
.modal.modal-just-me.in {
    background: #ffffff;
}
.tooltip-primary + .tooltip .tooltip-inner {
    color: #ffffff;
    background-color: #f96868;
}
.tooltip-primary + .tooltip.top .tooltip-arrow {
    border-top-color: #f96868;
}
.tooltip-primary + .tooltip.right .tooltip-arrow {
    border-right-color: #f96868;
}
.tooltip-primary + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f96868;
}
.tooltip-primary + .tooltip.left .tooltip-arrow {
    border-left-color: #f96868;
}
.tooltip-success + .tooltip .tooltip-inner {
    color: #ffffff;
    background-color: #46be8a;
}
.tooltip-success + .tooltip.top .tooltip-arrow {
    border-top-color: #46be8a;
}
.tooltip-success + .tooltip.right .tooltip-arrow {
    border-right-color: #46be8a;
}
.tooltip-success + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #46be8a;
}
.tooltip-success + .tooltip.left .tooltip-arrow {
    border-left-color: #46be8a;
}
.tooltip-info + .tooltip .tooltip-inner {
    color: #ffffff;
    background-color: #57c7d4;
}
.tooltip-info + .tooltip.top .tooltip-arrow {
    border-top-color: #57c7d4;
}
.tooltip-info + .tooltip.right .tooltip-arrow {
    border-right-color: #57c7d4;
}
.tooltip-info + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #57c7d4;
}
.tooltip-info + .tooltip.left .tooltip-arrow {
    border-left-color: #57c7d4;
}
.tooltip-warning + .tooltip .tooltip-inner {
    color: #ffffff;
    background-color: #f2a654;
}
.tooltip-warning + .tooltip.top .tooltip-arrow {
    border-top-color: #f2a654;
}
.tooltip-warning + .tooltip.right .tooltip-arrow {
    border-right-color: #f2a654;
}
.tooltip-warning + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f2a654;
}
.tooltip-warning + .tooltip.left .tooltip-arrow {
    border-left-color: #f2a654;
}
.tooltip-danger + .tooltip .tooltip-inner {
    color: #ffffff;
    background-color: #f96868;
}
.tooltip-danger + .tooltip.top .tooltip-arrow {
    border-top-color: #f96868;
}
.tooltip-danger + .tooltip.right .tooltip-arrow {
    border-right-color: #f96868;
}
.tooltip-danger + .tooltip.bottom .tooltip-arrow {
    border-bottom-color: #f96868;
}
.tooltip-danger + .tooltip.left .tooltip-arrow {
    border-left-color: #f96868;
}
.popover {
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.popover.bottom > .arrow:after {
    border-bottom-color: #f3f7f9;
}
.popover-primary + .popover .popover-title {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.popover-primary + .popover.bottom .arrow {
    border-bottom-color: #f96868;
}
.popover-primary + .popover.bottom .arrow:after {
    border-bottom-color: #f96868;
}
.popover-success + .popover .popover-title {
    color: #ffffff;
    background-color: #46be8a;
    border-color: #46be8a;
}
.popover-success + .popover.bottom .arrow {
    border-bottom-color: #46be8a;
}
.popover-success + .popover.bottom .arrow:after {
    border-bottom-color: #46be8a;
}
.popover-info + .popover .popover-title {
    color: #ffffff;
    background-color: #57c7d4;
    border-color: #57c7d4;
}
.popover-info + .popover.bottom .arrow {
    border-bottom-color: #57c7d4;
}
.popover-info + .popover.bottom .arrow:after {
    border-bottom-color: #57c7d4;
}
.popover-warning + .popover .popover-title {
    color: #ffffff;
    background-color: #f2a654;
    border-color: #f2a654;
}
.popover-warning + .popover.bottom .arrow {
    border-bottom-color: #f2a654;
}
.popover-warning + .popover.bottom .arrow:after {
    border-bottom-color: #f2a654;
}
.popover-danger + .popover .popover-title {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.popover-danger + .popover.bottom .arrow {
    border-bottom-color: #f96868;
}
.popover-danger + .popover.bottom .arrow:after {
    border-bottom-color: #f96868;
}
.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption h5,
.carousel-caption h6 {
    color: inherit;
}
.carousel-indicators li {
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
}
.carousel-indicators-scaleup li {
    border: none;
}
.carousel-indicators-fillin li {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 2px #fff inset;
    box-shadow: 0 0 0 2px #fff inset;
}
.carousel-indicators-fillin .active {
    -webkit-box-shadow: 0 0 0 8px #fff inset;
    box-shadow: 0 0 0 8px #fff inset;
}
.carousel-indicators-fall li:after {
    background-color: rgba(0, 0, 0, 0.3);
}
.carousel-indicators-fall .active {
    background-color: transparent;
}
.site-navbar {
    background-color: #f96868;
}
.site-navbar .navbar-header {
    background-color: transparent;
    color: #ffffff;
}
.site-navbar .navbar-header .navbar-toggle {
    color: #ffffff;
}
.site-navbar .navbar-header .hamburger:before,
.site-navbar .navbar-header .hamburger:after,
.site-navbar .navbar-header .hamburger .hamburger-bar {
    background-color: #ffffff;
}
.site-navbar .navbar-header .navbar-brand {
    color: #ffffff;
}
.site-navbar .container-fluid {
    background-color: #ffffff;
}
.site-navbar.navbar-inverse .container-fluid {
    background-color: transparent;
}
.site-menubar {
    background: #263238;
    color: rgba(163, 175, 183, 0.9);
}
.site-menubar.site-menubar-light {
    background: #ffffff;
}
.site-menubar-section > h4,
.site-menubar-section > h5 {
    color: #76838f;
}
.site-menubar-footer > a {
    color: #76838f;
    background-color: #21292e;
}
.site-menubar-footer > a:hover,
.site-menubar-footer > a:focus {
    background-color: #1e2427;
}
.site-menubar-light .site-menubar-footer > a {
    background-color: #e4eaec;
}
.site-menubar-light .site-menubar-footer > a:hover,
.site-menubar-light .site-menubar-footer > a:focus {
    background-color: #d5dee1;
}
.site-menu-item a {
    color: rgba(163, 175, 183, 0.9);
}
.site-menu > .site-menu-item {
    padding: 0;
}
.site-menu > .site-menu-item.open {
    background: #242f35;
}
.site-menu > .site-menu-item.open > a {
    color: #ffffff;
    background: transparent;
}
.site-menu > .site-menu-item.open.hover > a {
    background: transparent;
}
.site-menu > .site-menu-item.hover,
.site-menu > .site-menu-item:hover {
    color: rgba(255, 255, 255, 0.8);
}
.site-menu > .site-menu-item.hover > a,
.site-menu > .site-menu-item:hover > a {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.02);
}
.site-menu > .site-menu-item.active {
    background: #242f35;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.site-menu > .site-menu-item.active > a {
    color: #ffffff;
    background: transparent;
}
.site-menu > .site-menu-item.active.hover > a {
    background: transparent;
}
.site-menu .site-menu-sub {
    background: transparent;
}
.site-menu .site-menu-sub .site-menu-item {
    color: rgba(163, 175, 183, 0.9);
    background: transparent;
}
.site-menu .site-menu-sub .site-menu-item.has-sub {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.site-menu .site-menu-sub .site-menu-item.has-sub.open {
    border-top-color: rgba(0, 0, 0, 0.06);
    border-bottom-color: rgba(0, 0, 0, 0.06);
}
.site-menu .site-menu-sub .site-menu-item.open {
    background: rgba(0, 0, 0, 0.06);
}
.site-menu .site-menu-sub .site-menu-item.open > a {
    color: #ffffff;
}
.site-menu .site-menu-sub .site-menu-item.open.hover > a {
    background-color: transparent;
}
.site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menu .site-menu-sub .site-menu-item:hover > a {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.02);
}
.site-menu .site-menu-sub .site-menu-item.active {
    background: rgba(0, 0, 0, 0.06);
}
.site-menu .site-menu-sub .site-menu-item.active > a {
    color: #ffffff;
}
.site-menu .site-menu-sub .site-menu-item.active.hover > a {
    background-color: transparent;
}
.site-menubar-light .site-menu-item a {
    color: rgba(118, 131, 143, 0.9);
}
.site-menubar-light .site-menu-item.hover > a,
.site-menubar-light .site-menu-item:hover > a {
    background: transparent;
}
.site-menubar-light .site-menu > .site-menu-item.open {
    background: rgba(214, 73, 75, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.open > a {
    color: #f96868;
}
.site-menubar-light .site-menu > .site-menu-item.hover,
.site-menubar-light .site-menu > .site-menu-item:hover {
    background-color: rgba(214, 73, 75, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.hover > a,
.site-menubar-light .site-menu > .site-menu-item:hover > a {
    color: #f96868;
}
.site-menubar-light .site-menu > .site-menu-item.active {
    background: rgba(214, 73, 75, 0.05);
}
.site-menubar-light .site-menu > .site-menu-item.active > a {
    color: #f96868;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.open {
    background: rgba(214, 73, 75, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.open > a {
    color: #f96868;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover {
    background-color: rgba(214, 73, 75, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover > a,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover > a {
    color: #f96868;
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.hover.open,
.site-menubar-light .site-menu .site-menu-sub .site-menu-item:hover.open {
    background-color: rgba(214, 73, 75, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.active {
    background: rgba(214, 73, 75, 0.03);
}
.site-menubar-light .site-menu .site-menu-sub .site-menu-item.active > a {
    color: #f96868;
}
.site-gridmenu {
    background-color: #263238;
}
.site-gridmenu li > a {
    color: #a3afb7;
}
.site-gridmenu li:hover > a {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.02);
}
body, .page {
    background: #c6b9b9;
}
.page-description {
    color: #a3afb7;
}
.page-header {
    background: transparent;
    border-bottom: none;
}
.page-header-bordered {
    background-color: #ffffff;
    border-bottom-color: transparent;
}
.page-header-tabs .nav-tabs-line {
    border-bottom-color: transparent;
}
.page-aside {
    border-right-color: #e4eaec;
    background: #ffffff;
}
.page-aside-section:after {
    border-bottom-color: #e4eaec;
}
.page-aside-title {
    color: #526069;
}
.page-aside .list-group-item {
    border: none;
}
.page-aside .list-group-item .icon {
    color: #a3afb7;
}
.page-aside .list-group-item:hover,
.page-aside .list-group-item:focus {
    background-color: #f3f7f9;
    border: none;
    color: #f96868;
}
.page-aside .list-group-item:hover > .icon,
.page-aside .list-group-item:focus > .icon {
    color: #f96868;
}
.page-aside .list-group-item.active {
    background-color: transparent;
    color: #f96868;
}
.page-aside .list-group-item.active > .icon {
    color: #f96868;
}
.page-aside .list-group-item.active:hover,
.page-aside .list-group-item.active:focus {
    background-color: #f3f7f9;
    border: none;
    color: #f96868;
}
.page-aside .list-group-item.active:hover > .icon,
.page-aside .list-group-item.active:focus > .icon {
    color: #f96868;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon {
    background-color: transparent;
}
.page-aside .list-group.has-actions .list-group-item .item-actions .btn-icon:hover .icon {
    color: #f96868;
}
.page-aside .list-group.has-actions .list-group-item:hover .item-actions .icon {
    color: #76838f;
}
@media (max-width: 767px) {
    .page-aside {
        border-color: transparent;
    }
    .page-aside .page-aside-inner {
        background-color: white;
        border-right-color: #e4eaec;
    }
}
.site-footer {
    background-color: rgba(0, 0, 0, 0.02);
    border-top-color: #e4eaec;
}
.site-footer .scroll-to-top {
    color: #76838f;
}
@media (min-width: 1200px) {
    .layout-boxed {
        background: #e4eaec;
    }
}
.site-print {
    padding-top: 0;
}
.site-print .site-navbar,
.site-print .site-menubar,
.site-print .site-gridmenu,
.site-print .site-footer {
    display: none;
}
.site-print .page {
    margin: 0 !important;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item.hover {
    background: #37474f;
}
.site-menubar-fold.site-menubar-fold-alt .site-menu > .site-menu-item > a .site-menu-title {
    background: #37474f;
}
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.active,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.open,
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item.hover {
    background: #e8f1f8;
}
.site-menubar-fold.site-menubar-fold-alt .site-menubar-light .site-menu > .site-menu-item > a .site-menu-title {
    background: #e8f1f8;
}
@media screen and (max-width: 767px), screen and (min-width: 1200px) {
    .css-menubar .site-menu-category {
        color: #76838f;
    }
}
.site-menubar-unfold .site-menu-category {
    color: #76838f;
}
@media (max-width: 767px) {
    .site-gridmenu {
        background: rgba(38, 50, 56, 0.9);
    }
}
.form-material .form-control,
.form-material .form-control:focus,
.form-material .form-control.focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f96868), to(#f96868)), -webkit-gradient(linear, left top, left bottom, from(#e4eaec), to(#e4eaec));
    background-image: -webkit-linear-gradient(#f96868, #f96868), -webkit-linear-gradient(#e4eaec, #e4eaec);
    background-image: -o-linear-gradient(#f96868, #f96868), -o-linear-gradient(#e4eaec, #e4eaec);
    background-image: linear-gradient(#f96868, #f96868), linear-gradient(#e4eaec, #e4eaec);
}
.no-cssgradients .form-material .form-control:focus,
.no-cssgradients .form-material .form-control.focus {
    border-bottom-color: #f96868;
}
.form-material .form-control:focus ~ .floating-label,
.form-material .form-control.focus ~ .floating-label {
    color: #f96868;
}
.primary-100 {
    color: #ffeaea !important;
}
.primary-200 {
    color: #fad3d3 !important;
}
.primary-300 {
    color: #fab4b4 !important;
}
.primary-400 {
    color: #fa9898 !important;
}
.primary-500 {
    color: #fa7a7a !important;
}
.primary-600 {
    color: #f96868 !important;
}
.primary-700 {
    color: #e9595b !important;
}
.primary-800 {
    color: #d6494b !important;
}
.bg-primary-100 {
    background-color: #ffeaea !important;
}
.bg-primary-200 {
    background-color: #fad3d3 !important;
}
.bg-primary-300 {
    background-color: #fab4b4 !important;
}
.bg-primary-400 {
    background-color: #fa9898 !important;
}
.bg-primary-500 {
    background-color: #fa7a7a !important;
}
.bg-primary-600 {
    background-color: #f96868 !important;
}
.bg-primary-700 {
    background-color: #e9595b !important;
}
.bg-primary-800 {
    background-color: #d6494b !important;
}
.bg-white {
    color: #76838f;
    background-color: #ffffff;
}
.bg-primary {
    color: #ffffff;
    background-color: #f96868;
}
.bg-primary:hover {
    background-color: #fb9999;
}
.bg-primary a,
a.bg-primary {
    color: #ffffff;
}
.bg-primary a:hover,
a.bg-primary:hover {
    color: #ffffff;
}
.bg-success {
    color: #ffffff;
    background-color: #46be8a;
}
.bg-success:hover {
    background-color: #6ccba2;
}
.bg-success a,
a.bg-success {
    color: #ffffff;
}
.bg-success a:hover,
a.bg-success:hover {
    color: #ffffff;
}
.bg-info {
    color: #ffffff;
    background-color: #57c7d4;
}
.bg-info:hover {
    background-color: #80d5de;
}
.bg-info a,
a.bg-info {
    color: #ffffff;
}
.bg-info a:hover,
a.bg-info:hover {
    color: #ffffff;
}
.bg-warning {
    color: #ffffff;
    background-color: #f2a654;
}
.bg-warning:hover {
    background-color: #f6bf83;
}
.bg-warning a,
a.bg-warning {
    color: #ffffff;
}
.bg-warning a:hover,
a.bg-warning:hover {
    color: #ffffff;
}
.bg-danger {
    color: #ffffff;
    background-color: #f96868;
}
.bg-danger:hover {
    background-color: #fb9999;
}
.bg-danger a,
a.bg-danger {
    color: #ffffff;
}
.bg-danger a:hover,
a.bg-danger:hover {
    color: #ffffff;
}
.bg-dark {
    color: #ffffff;
    background-color: #526069;
}
.bg-dark:hover {
    background-color: #687a86;
}
.bg-dark a,
a.bg-dark {
    color: #ffffff;
}
.bg-dark a:hover,
a.bg-dark:hover {
    color: #ffffff;
}
.avatar i {
    border-color: #ffffff;
}
.avatar-online i {
    background-color: #46be8a;
}
.avatar-off i {
    background-color: #526069;
}
.avatar-busy i {
    background-color: #f2a654;
}
.avatar-away i {
    background-color: #f96868;
}
.status-online {
    background-color: #46be8a;
}
.status-off {
    background-color: #526069;
}
.status-busy {
    background-color: #f2a654;
}
.status-away {
    background-color: #f96868;
}
.counter > .counter-number,
.counter .counter-number-group {
    color: #37474f;
}
.counter-inverse {
    color: #ffffff;
}
.counter-inverse > .counter-number,
.counter-inverse .counter-number-group {
    color: #ffffff;
}
.counter-inverse .counter-icon {
    color: #ffffff;
}
.comments .comment {
    border: none;
    border-bottom-color: #e4eaec;
}
.comments .comment .comment:first-child {
    border-top-color: #e4eaec;
}
.comments .comment .comment:last-child {
    border-bottom: none;
}
.comment-author,
.comment-author:hover,
.comment-author:focus {
    color: #37474f;
}
.comment-meta {
    color: #a3afb7;
}
.chat-box {
    background-color: #ffffff;
}
.chat-content {
    color: #ffffff;
    background-color: #f96868;
}
.chat-content:before {
    border-color: transparent;
    border-left-color: #f96868;
}
.chat-content + .chat-content:before {
    border-color: transparent;
}
.chat-time {
    color: rgba(255, 255, 255, 0.6);
}
.chat-left .chat-content {
    color: #76838f;
    background-color: #dfe9ef;
}
.chat-left .chat-content:before {
    border-right-color: #dfe9ef;
    border-left-color: transparent;
}
.chat-left .chat-content + .chat-content:before {
    border-color: transparent;
}
.chat-left .chat-time {
    color: #a3afb7;
}
.step {
    background-color: #f3f7f9;
}
.step-number {
    color: #ffffff;
    background: #e4eaec;
}
.step-title {
    color: #526069;
}
.step.current {
    color: #ffffff;
    background-color: #f96868;
}
.step.current .step-title {
    color: #ffffff;
}
.step.current .step-number {
    color: #f96868;
    background-color: #ffffff;
}
.step.disabled {
    color: #ccd5db;
}
.step.disabled .step-title {
    color: #ccd5db;
}
.step.disabled .step-number {
    background-color: #ccd5db;
}
.step.error {
    color: #ffffff;
    background-color: #f96868;
}
.step.error .step-title {
    color: #ffffff;
}
.step.error .step-number {
    color: #f96868;
    background-color: #ffffff;
}
.step.done {
    color: #ffffff;
    background-color: #46be8a;
}
.step.done .step-title {
    color: #ffffff;
}
.step.done .step-number {
    color: #46be8a;
    background-color: #ffffff;
}
.pearl:before,
.pearl:after {
    background-color: #f3f7f9;
}
.pearl-number,
.pearl-icon {
    color: #ffffff;
    background: #ccd5db;
    border-color: #ccd5db;
}
.pearl-title {
    color: #526069;
}
.pearl.current:before,
.pearl.current:after {
    background-color: #f96868;
}
.pearl.current .pearl-number,
.pearl.current .pearl-icon {
    color: #f96868;
    background-color: #ffffff;
    border-color: #f96868;
}
.pearl.disabled:before,
.pearl.disabled:after {
    background-color: #f3f7f9;
}
.pearl.disabled .pearl-number,
.pearl.disabled .pearl-icon {
    color: #ffffff;
    background-color: #ccd5db;
    border-color: #ccd5db;
}
.pearl.error:before {
    background-color: #f96868;
}
.pearl.error:after {
    background-color: #f3f7f9;
}
.pearl.error .pearl-number,
.pearl.error .pearl-icon {
    color: #f96868;
    background-color: #ffffff;
    border-color: #f96868;
}
.pearl.done:before,
.pearl.done:after {
    background-color: #f96868;
}
.pearl.done .pearl-number,
.pearl.done .pearl-icon {
    color: #ffffff;
    background-color: #f96868;
    border-color: #f96868;
}
.ribbon-primary .ribbon-inner {
    background-color: #f96868;
}
.ribbon-primary.ribbon-bookmark .ribbon-inner:before {
    border-color: #f96868;
    border-right-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #f96868;
    border-bottom-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-bottom-color: transparent;
    border-left-color: #f96868;
}
.ribbon-primary.ribbon-corner .ribbon-inner {
    background-color: transparent;
}
.ribbon-primary.ribbon-corner .ribbon-inner:before {
    border-top-color: #f96868;
    border-left-color: #f96868;
}
.ribbon-primary.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #f96868;
}
.ribbon-primary .ribbon-inner:after {
    border-top-color: #e9595b;
    border-right-color: #e9595b;
}
.ribbon-primary.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #e9595b;
}
.ribbon-primary.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #e9595b;
}
.ribbon-success .ribbon-inner {
    background-color: #46be8a;
}
.ribbon-success.ribbon-bookmark .ribbon-inner:before {
    border-color: #46be8a;
    border-right-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #46be8a;
    border-left-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #46be8a;
    border-bottom-color: transparent;
}
.ribbon-success.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #46be8a;
    border-bottom-color: transparent;
    border-left-color: #46be8a;
}
.ribbon-success.ribbon-corner .ribbon-inner {
    background-color: transparent;
}
.ribbon-success.ribbon-corner .ribbon-inner:before {
    border-top-color: #46be8a;
    border-left-color: #46be8a;
}
.ribbon-success.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #46be8a;
    border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #46be8a;
}
.ribbon-success .ribbon-inner:after {
    border-top-color: #36ab7a;
    border-right-color: #36ab7a;
}
.ribbon-success.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #36ab7a;
}
.ribbon-success.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #36ab7a;
}
.ribbon-info .ribbon-inner {
    background-color: #57c7d4;
}
.ribbon-info.ribbon-bookmark .ribbon-inner:before {
    border-color: #57c7d4;
    border-right-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #57c7d4;
    border-left-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #57c7d4;
    border-bottom-color: transparent;
}
.ribbon-info.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #57c7d4;
    border-bottom-color: transparent;
    border-left-color: #57c7d4;
}
.ribbon-info.ribbon-corner .ribbon-inner {
    background-color: transparent;
}
.ribbon-info.ribbon-corner .ribbon-inner:before {
    border-top-color: #57c7d4;
    border-left-color: #57c7d4;
}
.ribbon-info.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #57c7d4;
    border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #57c7d4;
}
.ribbon-info .ribbon-inner:after {
    border-top-color: #47b8c6;
    border-right-color: #47b8c6;
}
.ribbon-info.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #47b8c6;
}
.ribbon-info.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #47b8c6;
}
.ribbon-warning .ribbon-inner {
    background-color: #f2a654;
}
.ribbon-warning.ribbon-bookmark .ribbon-inner:before {
    border-color: #f2a654;
    border-right-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f2a654;
    border-left-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #f2a654;
    border-bottom-color: transparent;
}
.ribbon-warning.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f2a654;
    border-bottom-color: transparent;
    border-left-color: #f2a654;
}
.ribbon-warning.ribbon-corner .ribbon-inner {
    background-color: transparent;
}
.ribbon-warning.ribbon-corner .ribbon-inner:before {
    border-top-color: #f2a654;
    border-left-color: #f2a654;
}
.ribbon-warning.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f2a654;
    border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #f2a654;
}
.ribbon-warning .ribbon-inner:after {
    border-top-color: #ec9940;
    border-right-color: #ec9940;
}
.ribbon-warning.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #ec9940;
}
.ribbon-warning.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #ec9940;
}
.ribbon-danger .ribbon-inner {
    background-color: #f96868;
}
.ribbon-danger.ribbon-bookmark .ribbon-inner:before {
    border-color: #f96868;
    border-right-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-left-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical .ribbon-inner:before {
    border-right-color: #f96868;
    border-bottom-color: transparent;
}
.ribbon-danger.ribbon-bookmark.ribbon-vertical.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-bottom-color: transparent;
    border-left-color: #f96868;
}
.ribbon-danger.ribbon-corner .ribbon-inner {
    background-color: transparent;
}
.ribbon-danger.ribbon-corner .ribbon-inner:before {
    border-top-color: #f96868;
    border-left-color: #f96868;
}
.ribbon-danger.ribbon-corner.ribbon-reverse .ribbon-inner:before {
    border-right-color: #f96868;
    border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom .ribbon-inner:before {
    border-top-color: transparent;
    border-bottom-color: #f96868;
}
.ribbon-danger .ribbon-inner:after {
    border-top-color: #e9595b;
    border-right-color: #e9595b;
}
.ribbon-danger.ribbon-reverse .ribbon-inner:after {
    border-right-color: transparent;
    border-left-color: #e9595b;
}
.ribbon-danger.ribbon-bottom .ribbon-inner:after {
    border-top-color: transparent;
    border-bottom-color: #e9595b;
}
.color-selector > li {
    background-color: #f96868;
}
.color-selector > li input[type="radio"]:checked + label:after {
    color: #ffffff;
}
.color-selector > li.color-selector-disabled {
    background-color: #ccd5db !important;
}
